import React from "react";
import { message, notification } from "antd";
import { FormattedMessage } from "react-intl";

export function error(response) {
  try {
    if (Boolean(response.response) && response.response.status === 403) window.location.href = "/logout";
    else showError(Boolean(response.response) && Boolean(response.response.data) ? response.response.data.message : "");
  } catch (error) {}
}

export function showSuccess(msg) {
  let message = "";
  if (Boolean(msg) && msg.length > 0) message = msg;
  else message = "Process completed successfully.";

  notification["success"]({
    message: "Success",
    description: message
  });
}

export function showError(msg) {
  let message = "";
  if (Boolean(msg) && msg.length > 0) message = msg;
  else message = "An error has occurred !.";

  notification["error"]({
    message: "Error",
    description: message
  });
}

export function showWarning(msg) {
  let message = "";
  if (Boolean(msg) && msg.length > 0) message = msg;
  else message = "This is a warning message";

  notification["warning"]({
    message: <FormattedMessage id="General.MessageWarning" defaultMessage="Warning" />,

    description: <FormattedMessage id="General.MessageWarning" defaultMessage={message} />
  });
}

export function showInfo(msg) {
  let message = "";
  if (Boolean(msg) && msg.length > 0) message = msg;
  else message = "This is a info message !.";

  notification["info"]({
    message: "Info",
    description: message
  });
}

export function showSuccessBasic(msg) {
  if (Boolean(msg) && msg.length > 0) message.error(msg);
  else message.success("Success");
}

export function showErrorBasic(msg) {
  if (Boolean(msg) && msg.length > 0) message.error(msg);
  else message.error("An error has occurred !");
}

export function showWarningBasic(msg) {
  if (Boolean(msg) && msg.length > 0) message.error(msg);
  else message.warning("This is a warning message!");
}

export function showInfoBasic(msg) {
  if (Boolean(msg) && msg.length > 0) message.error(msg);
  else message.info("This is a info message !");
}
