import Divider from "@material-ui/core/Divider/Divider";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

export default ({ row, actionList }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActionClick = e => {
    e.actionClick(row);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="secondary" />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actionList.map((action, index) => {
          let divider = index < actionList.length - 1 ? <Divider /> : null;
          return (
            <div key={index}>
              <MenuItem onClick={() => onActionClick(action)}>
                <ListItemIcon>{action.icon}</ListItemIcon>
                <Typography variant="inherit">{action.name}</Typography>
              </MenuItem>
              {divider}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};
