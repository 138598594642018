import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import moment from "moment";
import { handleRequest, API_BASE_ROOT } from "../../../../../app/ApiConnector";
import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { reducer } from "../../../../../app/modules/Auth/_redux/authRedux";
import store from "../../../../../redux/store";
import { Col, DatePicker, Input, Row, Select, Space, notification, Button as AntButton, Checkbox, Form, Modal, Card, Tag, Spin, Tooltip as AntTooltip, Dropdown as AntDropdown } from "antd";
import FullscreenIcon from "@material-ui/icons/FullscreenOutlined";
import './index.css';
import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import NotificationsApproval from "../../../../../app/pages/planning/notifications/NotificationsApproval";
import NotificationsReject from "../../../../../app/pages/planning/notifications/NotificationsReject";
import NotificationsComment from "../../../../../app/pages/planning/notifications/NotificationsComment";
import { error } from "../../../../../app/MessageHelper";
import DraggableModal from "../../../../../app/Components/DraggableModal";

const { RangePicker } = DatePicker;

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

let timeout = 3000;
let timeoutCounter = 1;
const maxTimeoutCounter = 5;
const { Option } = Select;

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const [notifications, setNotifications] = useState([]);
  const [newSize, setNewSize] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedDetailIndex, setClickedDetailIndex] = useState(null);
  const [modalReviewToDepartmentShow, setModalReviewToDepartmentShow] = useState(false);
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalApprovalShow, setModalApprovalShow] = useState(false);
  const [modalRejectShow, setModalRejectShow] = useState(false);
  const [modalCommentShow, setModalCommentShow] = useState(false);
  const [status, setStatus] = useState(null);
  const [aim, setAim] = useState(null);
  const [activationTimeStart, setActivationTimeStart] = useState(null);
  const [activationTimeEnd, setActivationTimeEnd] = useState(null);

  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas"
    };
  }, [uiService]);

  const fillNotifications = async () => {
    setLoading(true);
    const newItem = {
      firstResult: 0,
      maxResults: limit,
      message: searchKeyword,
      status: status,
      aim: aim,
      activationTimeStart: activationTimeStart,
      activationTimeEnd: activationTimeEnd,
    };
    var response = await handleRequest("POST", "/api/notification/search", newItem);
    if (response.type === "ERROR") {
      error(response);
      setNotifications([]);
      setNewSize(0);
      setLoading(false);
    } else {
      if (Boolean(response.data) && Boolean(response.data.list)) {
        setNotifications(response.data.list);
        setNewSize(response.data.waitingCount);
        setLoading(false);
      } else {
        setNotifications([]);
        setNewSize(0);
        setLoading(false);
      }
    }
  };

  const setSeen = async e => {
    var obj = e;
    obj.status = "Seen";
    let res = await handleRequest("PUT", "/api/notification/" + obj.id, obj);
  };

  const goToPage = async e => {
    await setSeen(e);

    setTimeout(() => {
      window.location.href = "/" + e.nextPage;
    }, 1000);
  };

  const showMore = () => {
    setLimit(limit + 10);
  };

  const search = async e => {
    let keyword = e.target.value;
    setSearchKeyword(keyword);
    setLimit(10);
  };

  const openNotification = e => {
    const key = e.id;

    const btn = (
      <AntButton type="primary" size="small" onClick={() => goToPage(e)}>
        Go to page
      </AntButton>
    );

    const desc = (
      <>
        <p>{e.message}</p>
        <div className="navi-text">
          <div className="text-muted"> {moment(e.activationTime).format("DD/MM/YYYY HH:mm")}</div>
        </div>
      </>
    );

    notification["info"]({
      message: e.title,
      description: desc,
      duration: 0,
      btn: btn,
      key
    });
  };

  const connect = () => {
    const token = reducer.authToken;
    const ws = new SockJS(`${API_BASE_ROOT}/socket`);
    const stompClient = Stomp.over(ws);

    stompClient.connect(
      { Authorization: "Bearer " + token },
      function (frame) {
        stompClient.subscribe("/user/" + store.getState().auth.user.id + "/", message => {
          console.log(message.body);
          var data = JSON.parse(message.body);
          openNotification(data);
        });
      },
      error => {
        timeoutCounter++;
        console.log("Trying to connect to websocket...");
        if (timeoutCounter < maxTimeoutCounter) {
          setTimeout(connect, timeoutCounter * timeout);
        }
      }
    );
  };

  const handleClick = (index) => {
    console.log("index", index);
    setClickedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleDetailClick = (index) => {
    setClickedDetailIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const onChanceToSelectted = async () => {
    setModalReviewToDepartmentShow(true)
  };

  const getStatusColor = status => {
    if (Boolean(status)) {
      if (status === "Info") {
        return "processing";
      } else if (status === "Review") {
        return "warning";
      } else if (status === "Approval") {
        return "success";
      }
    }
    return "default";
  };

  const handleButtonClick = (e) => {
    console.log('click left button', e);
  };

  const handleMenuClick = (e) => {
    console.log('click', e);
  };

  const items = [
    {
      label: 'Mark as Seen',
      key: 'markAsSeen',
      icon: <UserOutlined />,
    },
    {
      label: 'Remind me later',
      key: 'remindMeLater',
      icon: <UserOutlined />,
    },
    {
      label: 'Mark as Important',
      key: 'markAsImportant',
      icon: <UserOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    connect();
    fillNotifications();
  }, [searchKeyword, limit, status, aim, activationTimeStart, activationTimeEnd]);


  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 8 }
  };

  const calculateElapsedTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const remainingMilliseconds = timeDifference % (1000 * 60 * 60 * 24);
    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));

    if (days > 0) {
      return `${days} days`;
    } else if (hours > 0) {
      return `${hours} hrs`;
    }
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  {Boolean(newSize) && newSize > 0 && <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {newSize} new
                  </span>}
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Tab.Content className="tab-content">
                    <Tab.Pane
                      eventKey="Alerts"
                      id="topbar_notifications_events"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >

                        <Row gutter={[16, 16]}>
                          <Col md={1}></Col>
                          <Col md={10}>
                            <Select showSearch
                              allowClear
                              optionFilterProp="children"
                              style={{ width: "100%" }}
                              defaultValue={"All"}
                              onChange={value => {
                                if (value === "All") {
                                  setAim(null);
                                } else {
                                  setAim(value);
                                }
                              }}
                            >
                              <Option key={"All"} value={"All"}>{"All"}</Option>
                              <Option key={"Information"} value={"Info"}>{"Information"}</Option>
                              <Option key={"Review"} value={"Review"}>{"Review"}</Option>
                              <Option key={"Approval"} value={"Approval"}>{"Approval"}</Option>
                            </Select>
                          </Col>
                          <Col md={10}>
                            <Select showSearch
                              allowClear
                              optionFilterProp="children"
                              style={{ width: "100%" }}
                              defaultValue={"All"}
                              onChange={value => {
                                if (value === "All") {
                                  setStatus(null);
                                } else {
                                  setStatus(value);
                                }
                              }}
                            >
                              <Option key={"All"} value={"All"}>{"All"}</Option>
                              <Option key={"Unseen"} value={"Waiting"}>{"Unseen"}</Option>
                              <Option key={"Seen"} value={"Seen"}>{"Seen"}</Option>
                              <Option key={"Important"} value={"Important"}>{"Important"}</Option>
                            </Select>
                          </Col>
                          <Col md={1}></Col>
                        </Row>
                        <br />

                        <Row gutter={[16, 16]}>
                          <Col md={1}></Col>
                          <Col md={22}>
                            <Space>
                              <Input
                                type="text"
                                autoFocus={true}
                                placeholder="Search..."
                                value={searchKeyword}
                                onChange={search}
                                style={{ width: "100%" }}
                              />
                              <RangePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm"
                                onChange={value => {
                                  if (Boolean(value) && value.length == 2) {
                                    setActivationTimeStart(value[0]);
                                    setActivationTimeEnd(value[1]);
                                  } else {
                                    setActivationTimeStart(null);
                                    setActivationTimeEnd(null);
                                  }
                                }} />
                            </Space>
                          </Col>
                          <Col md={1}></Col>
                        </Row>

                        <Spin spinning={loading}>
                          {Boolean(notifications) && notifications.length > 0 &&
                            notifications.map((i, index) => (
                              <a href="#" className="navi-item">
                                <div className="navi-link"
                                  onClick={() => {
                                    setData(i);
                                    setModalDetailShow(true);
                                  }}>
                                  {getIcon(index)}
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      {Boolean(i.message) ? i.message : "Message"}
                                    </div>
                                    <div className="text-muted">
                                      {calculateElapsedTime(i.dateCreated, new Date())}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))}
                          <a key={"showMore"} onClick={() => showMore()} className="navi-item">
                            <div className="navi-link">
                              <div className="navi-icon mr-2"></div>
                              <div className="navi-text">
                                <div className="font-weight-bold"> {"Show More"}</div>
                              </div>
                            </div>
                          </a>
                        </Spin>
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}

      <DraggableModal
        title=""
        open={modalReviewToDepartmentShow}
        onCancel={() => {
          setModalReviewToDepartmentShow(false);
        }}
        width={400}
        centered
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        content={
          <>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.MarkAsSeen" defaultMessage="Mark as Seen" />}
              name="markAsSeen"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox onChange={e => {
                setModalReviewToDepartmentShow(false);
              }}></Checkbox>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.RemindMeLater" defaultMessage="Remind me later" />}
              name="remindMeLater"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox onChange={e => {
                setModalReviewToDepartmentShow(false);
              }}></Checkbox>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageFormInput.MarkAsImportant" defaultMessage="Mark as Important" />}
              name="markAsImportant"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox onChange={e => {
                setModalReviewToDepartmentShow(false);
              }}></Checkbox>
            </Form.Item>
          </>
        }

      />

      <DraggableModal
        title={<FormattedMessage id="PageFormInput.Detail" defaultMessage="Detail" />}
        open={modalDetailShow}
        onCancel={() => {
          setModalDetailShow(false);
        }}
        width={800}
        centered
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        content={
          Boolean(data) &&
          <>
            <div className={`navi-link`} >
              <div className="navi-link">
                <div className="navi-icon mr-2"></div>
                <div className="navi-text">
                  <Tag color={getStatusColor(data.aim)}>
                    {data.aimName}
                  </Tag>
                </div>
              </div>
              <br />
              <div className="navi-link">
                <div className="navi-icon mr-2"></div>
                <div className="navi-text">
                  <div className="text-muted ml-2">{moment(data.activationTime).format("DD/MM/YYYY HH:mm")}</div>
                </div>
              </div>
              <br />
              <div className="navi-link">
                <div className="navi-icon mr-2"></div>
                <div className="navi-text">
                  <div className="text-muted ml-2">
                    {(Boolean(data.page) && Boolean(data.action)) ? (data.page + "-" + data.action + " ") : ""}
                  </div>
                </div>
              </div>
              <br />
              <div className="navi-link">
                <div className="navi-icon mr-2"></div>
                <div className="navi-text">
                  {data.message}
                </div>
              </div>
              <br />
              <div className="navi-link">
                <div className="navi-icon mr-2"></div>
                <div className="navi-text">
                  {data.title}
                </div>
              </div>
            </div>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={6}>
                <Spin spinning={loading}>
                  <Button
                    onClick={() => {
                      setLoading(true);
                      goToPage(data);
                    }}
                    style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="UserNotificationsDropdown.GoToPage" defaultMessage="Go to page" />
                  </Button>
                </Spin>
              </Col>
              {data.aim === "Info" && <Col md={6}>
                <Button
                  onClick={() => {
                    setModalCommentShow(true);
                    setModalDetailShow(false);
                  }}
                  style={{ width: "100%" }} variant="info">
                  <FormattedMessage id="UserNotificationsDropdown.Comment" defaultMessage="Comment" />
                </Button>
              </Col>}
              {(data.aim === "Review" || data.aim === "Approval") && <>
                <Col md={6}>
                  <Button
                    onClick={() => {
                      setModalApprovalShow(true);
                      setModalDetailShow(false);
                    }}
                    style={{ width: "100%" }} variant="info">
                    <FormattedMessage id="UserNotificationsDropdown.Confirm" defaultMessage="Confirm" />
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    onClick={() => {
                      setModalRejectShow(true);
                      setModalDetailShow(false);
                    }}
                    style={{ width: "100%" }} variant="danger">
                    <FormattedMessage id="UserNotificationsDropdown.Reject" defaultMessage="Reject" />
                  </Button>
                </Col>
              </>
              }
            </Row>
          </>
        }
      />


      <DraggableModal
        title={<FormattedMessage id="PageFormInput.Approval" defaultMessage="Approval" />}
        open={modalApprovalShow}
        onCancel={() => {
          setModalApprovalShow(false);
        }}
        width={800}
        centered
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        content={
          <NotificationsApproval action={"Approval"} pageApprovalId={Boolean(data) ? data.pageApprovalId : null} notificationId={Boolean(data) ? data.id : null} closeModal={() => {
            setModalApprovalShow(false);
          }} />
        }
      />


      <DraggableModal
        title={<FormattedMessage id="PageFormInput.Reject" defaultMessage="Reject" />}
        open={modalRejectShow}
        onCancel={() => {
          setModalRejectShow(false);
        }}
        width={800}
        centered
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        content={
          <NotificationsReject action={"Review"} pageApprovalId={Boolean(data) ? data.pageApprovalId : null} notificationId={Boolean(data) ? data.id : null} closeModal={() => {
            setModalRejectShow(false);
          }} />
        }
      />
      {/* <DraggableModal
        title={<FormattedMessage id="PageFormInput.Comment" defaultMessage="Comment" />}
        open={modalCommentShow}
        onCancel={() => {
          setModalCommentShow(false);
        }}
        width={800}
        centered
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        content={
          <NotificationsComment pageApprovalId={Boolean(data) ? data.pageApprovalId : null} notificationId={Boolean(data) ? data.id : null} closeModal={() => {
            setModalCommentShow(false);
          }} />
        }
      /> */}
    </>
  );

  function getIcon(index) {
    const modResult = index % 14;

    let iconClassName = "flaticon2-line-chart text-success";
    if (modResult === 1) {
      iconClassName = "flaticon2-paper-plane text-danger";
    } else if (modResult === 2) {
      iconClassName = "flaticon2-user flaticon2-line- text-success";
    } else if (modResult === 3) {
      iconClassName = "flaticon2-pin text-primary";
    } else if (modResult === 4) {
      iconClassName = "flaticon2-sms text-danger";
    } else if (modResult === 5) {
      iconClassName = "flaticon2-pie-chart-3 text-warning";
    } else if (modResult === 6) {
      iconClassName = "flaticon-pie-chart-1 text-info";
    } else if (modResult === 7) {
      iconClassName = "flaticon2-settings text-success";
    } else if (modResult === 8) {
      iconClassName = "flaticon-safe-shield-protection text-primary";
    } else if (modResult === 9) {
      iconClassName = "flaticon2-notification text-primary";
    } else if (modResult === 10) {
      iconClassName = "flaticon2-fax text-success";
    } else if (modResult === 11) {
      iconClassName = "flaticon-download-1 text-danger";
    } else if (modResult === 12) {
      iconClassName = "flaticon-security text-warning";
    } else if (modResult === 13) {
      iconClassName = "flaticon2-analytics-1 text-success";
    }
    return (
      <div className="navi-icon mr-2">
        <i className={iconClassName}></i>
      </div>
    );
  }
}
