import { Column } from "@ant-design/charts";
import moment from "moment";
import React, { useEffect } from "react";
import { DatePicker, Steps } from "antd";

const { Step } = Steps;
const { RangePicker } = DatePicker;

function Stepper(props) {
  const [statusList, setStatusList] = React.useState(null);

  useEffect(() => {
    console.log(props.sList);
    setStatusList(props.sList);
  }, []);
  return (
    <div className="col-lg-6">
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">RFQ Status</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm"></span>
          </h3>
        </div>
        <div className="card-body pt-4">
          {/*<div style={{ width: "100%" }}>{Boolean(stepConfig) && <Column {...stepConfig} />}</div>*/}
          <Steps percent={60} direction="vertical" className="pl-5-custom">
            {Boolean(statusList) &&
              statusList.length > 0 &&
              statusList.map((item, index) => {
                return (
                  <Step
                    icon={item.icon}
                    title={item.status + (item.count >= 0 ? " - " + item.count : "")}
                    status={
                      item.color === "green"
                        ? "finish"
                        : item.color === "blue"
                        ? "process"
                        : item.status === "Evaluation"
                        ? "wait"
                        : item.status === "Selection of vendor(s)"
                        ? "wait"
                        : item.status === "P.O. Creation"
                        ? "wait"
                        : "wait"
                    }
                    description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                  />
                );
              })}
          </Steps>
        </div>
      </div>
    </div>
  );
}

export default Stepper;
