import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../../MessageHelper";
import { handleRequest } from "../../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Modal as AntModal } from "antd";
import DynamicActionMenu from "../../../CustomMenu/DynamicActionMenu";
import { Add, Archive, ArrowDownward, Bookmark, BookmarkOutlined, Cached, Delete, EditOutlined, SearchOutlined, ShortText, Star, StarOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Avatar } from "@material-ui/core";
import Search from "antd/lib/transfer/search";
import InboxReply from "./InboxReply";

export class InboxPage extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "InboxPage",
      controllerName: "taxPayers",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      selectedRow: null,
      showReply: false,
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleReplyClick = (record) => {
    this.setState({
      selectedRow: record,
      showReply: true,
    }, () => {
      console.log("Selected Row ID:", this.state.selectedRow.id);
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="InboxPage.taxPayers" defaultMessage="Tax Payers" />,
        key: "taxPayers",
        render: record => {
          return record.taxPayers;
        }, ...getColumnFilter({
          inputName: "taxPayers", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const data = [
      {
        id: 1,
        name: 'Ana Simmons1',
        imageSrc: 'URL_TO_IMAGE',
        skills: 'HTML, JS, ReactJS',
        company: 'Intertico',
        specialization: 'Web, UI/UX Design',
        date: '15 Dec 2023',
      },
      {
        id: 2,
        name: 'Ana Simmons2',
        imageSrc: 'URL_TO_IMAGE',
        skills: 'HTML, JS, ReactJS',
        company: 'Intertico',
        specialization: 'Web, UI/UX Design',
        date: '15 Dec 2023',
      },
      {
        id: 3,
        name: 'Ana Simmons3',
        imageSrc: 'URL_TO_IMAGE',
        skills: 'HTML, JS, ReactJS',
        company: 'Intertico',
        specialization: 'Web, UI/UX Design',
        date: '15 Dec 2023',
      },
    ];

    const renderTableRows = () => {
      return data.map((item) => (
        <tr key={item.id} onClick={() => this.handleReplyClick(item)}>
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input widget-9-check' type='checkbox' value={item.id} />
            </div>
          </td>
          <td>
            <div className='d-flex'>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Star />
              </a>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Bookmark />
              </a>
            </div>
          </td>
          <td >
            <div className='d-flex align-items-center'>
              <Avatar src={item.imageSrc} alt={item.name} className='me-2' />
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
                  {item.name}
                </a>
                <span className='text-muted fw-semibold text-muted fs-7'>{item.skills}</span>
              </div>
            </div>
          </td>
          <td>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary d-block fs-6'>
              {item.company}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.specialization}</span>
          </td>
          <td className='text-end'>
            <div className='d-flex flex-column'>
              <span className='text-muted fs-7 fw-semibold' style={{ lineHeight: '1.5rem', fontSize: '15px' }}>
                {item.date}
              </span>
            </div>
          </td>
        </tr>
      ));
    };

    return (
      <>
        <div>
          {/* begin::Header */}
          <div className='card-body py-1'>
            <div className='table-responsive'>
              <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a user'>
                <div className="d-flex justify-content-between align-items-center" >
                  <div className="d-flex">
                    <div onClick={() => console.log("Reload clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <Cached style={{ fontSize: '16px' }} />
                    </div>

                    <div onClick={() => console.log("Archive clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <Archive style={{ fontSize: '16px' }} />
                    </div>

                    <div onClick={() => console.log("Delete clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <Delete style={{ fontSize: '16px' }} />
                    </div>

                    <div onClick={() => console.log("Arrow Down clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <ArrowDownward style={{ fontSize: '16px' }} />
                    </div>

                    <div onClick={() => console.log("Short Text clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <ShortText style={{ fontSize: '16px' }} />
                    </div>
                  </div>
                  <div className="ms-3" style={{ transition: 'background-color 0.3s' }}>
                    <Input
                      placeholder="Search"
                      prefix={<SearchOutlined style={{ color: '#a0a5b1' }} />}
                      style={{ backgroundColor: '#f8f9fa', border: '1px solid #ced4da' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                        />
                      </div>
                    </th>
                    <th className='min-w-30px'>Actions</th>
                    <th className='min-w-80px'>Authors</th>
                    <th className='min-w-150px'>Subject</th>
                    <th className='min-w-80px text-end'>Date</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
          </div>

          {/* begin::Body */}
        </div >

        <AntModal
          title={<FormattedMessage id="InboxDrawer.Reply" defaultMessage="Reply" />}
          open={this.state.showReply}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showReply: false })}
          width={1300}
          centered
        >
          <Form
            initialValues={{ remember: false }}
          >
            <InboxReply />
          </Form>
        </AntModal>
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);
