import React, { useState } from "react";
import clsx from "clsx";
import { AttachFile, CloudUpload } from "@material-ui/icons";

const defaultUserInfos = [];

const messageFromClient = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
};

const messageModel = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quarter achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: ' ',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season, we have the next Big Deal for you.',
    time: 'Just now',
  },
];

const ChatInner = ({ isDrawer = false }) => {
  const [chatUpdateFlag, toggleChatUpdateFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [userInfos] = useState(defaultUserInfos);

  const groupMessages = () => {
    const grouped = {};
    messageModel.forEach((msg) => {
      const key = msg.user.toString();
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(msg);
    });
    return grouped;
  };

  const sortMessages = () => {
    const groupedMessages = groupMessages();

    const sortedMessages = [];
    let user4Messages = groupedMessages['4'] || [];
    let user2Messages = groupedMessages['2'] || [];

    while (user4Messages.length > 0 || user2Messages.length > 0) {
      if (user4Messages.length > 0) {
        sortedMessages.push(user4Messages.shift());
      }

      if (user2Messages.length > 0) {
        sortedMessages.push(user2Messages.shift());
      }
    }

    return sortedMessages;
  };

  const renderMessages = () => {
    const sortedMessages = sortMessages();

    return sortedMessages.map((msg, index) => {
      const isClientMessage = msg.type === "out" && !msg.template;
      const contentClass = clsx("d-flex mb-10", {
        "justify-content-end": isClientMessage,
      });

      return (
        <div key={`message${index}`} className={contentClass}>
          <div className="d-flex flex-column align-items-start">

            <div className="d-flex align-items-center mb-2">
              {isClientMessage ? (
                <>
                  <div className="me-3">
                    <span className="text-muted fs-7 mb-1">{msg.time}</span>
                    <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">
                      You
                    </a>
                  </div>
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src={userInfos[msg.user] ? userInfos[msg.user].avatar : ""} />
                  </div>
                </>
              ) : (
                <>
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src={userInfos[msg.user] ? userInfos[msg.user].avatar : ""} />
                  </div>
                  <div className="ms-3">
                    <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">
                      {userInfos[msg.user] ? userInfos[msg.user].name : ""}
                    </a>
                    <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">
                      Other
                    </a>
                    <span className="text-muted fs-7 mb-1">{msg.time}</span>
                  </div>
                </>
              )}
            </div>

            <div
              className={clsx(
                "p-5 rounded",
                `bg-light-${isClientMessage ? "primary" : "info"}`,
                "text-gray-900 fw-bold mw-lg-400px",
                `text-${isClientMessage ? "end" : "start"}`
              )}
              data-kt-element="message-text"
              dangerouslySetInnerHTML={{ __html: msg.text }}
            ></div>

          </div>
        </div>
      );
    });
  };

  const sendMessage = () => {
    const newMessage = {
      user: 2,
      type: 'out',
      text: message,
      time: 'Just now',
    };

    messageModel.push(newMessage);

    toggleChatUpdateFlag(!chatUpdateFlag);
    setMessage('');

    setTimeout(() => {
      const clientMessage = {
        ...messageFromClient,
        time: 'Just now',
      };
      messageModel.push(clientMessage);
      toggleChatUpdateFlag(!chatUpdateFlag);
    }, 1000);
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className={`card-body`} id={isDrawer ? "kt_drawer_chat_messenger_body" : "kt_chat_messenger_body"} >
      <div className={clsx("scroll-y me-n5 pe-5", { "h-300px h-lg-auto": !isDrawer })} data-kt-element="messages" data-kt-scroll="true" >
        <div style={{ height: '500px', overflowY: 'scroll' }}>
          {renderMessages()}
        </div>
      </div>

      <div className="card-footer pt-4" id={isDrawer ? "kt_drawer_chat_messenger_footer" : "kt_chat_messenger_footer"}>
        <textarea
          className="form-control form-control-flush mb-3"
          rows={1}
          data-kt-element="input"
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className="d-flex flex-stack justify-content-between">
          <div className="d-flex align-items-center">
            <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
              <AttachFile fontSize="large" />
            </button>
            <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
              <CloudUpload fontSize="large" />
            </button>
          </div>
          <button className="btn btn-primary" type="button" data-kt-element="send" onClick={sendMessage} >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export { ChatInner };
