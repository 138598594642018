import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";
import { changeParentSelectValue } from "./redux/dependantSelectSlice";

const SelectComponent = ({ field, layout, form }) => {
  const [list, setList] = useState([]);
  const [parentValue, setParentValue] = useState(null);
  const { Option } = Select;
  const dispatch = useDispatch();
  const parentValues = useSelector(state => {
    return state.dependantSelect.value;
  });
  const pValue = parentValues[field.parentName + "ParentValue"];
  let formItemProps = PrepareFormItemProps(field, form);
  let otherProps = {};
  if (field.children) {
    otherProps.onChange = value => {
      dispatch(changeParentSelectValue({ key: field.inputName, value }));
    };
  }
  if (formItemProps.hidden && otherProps.onChange) {
    otherProps.onChange(undefined);
  }
  const fillList = async p => {
    if (Boolean(form)) {
      form.current.setFieldsValue({ [field.inputName]: null });
    }
    let url = field.loadUrl; //"/api/countries/selectItems";
    if (!Boolean(url)) return;
    if (Boolean(field.parentName)) {
      if (!Boolean(p)) {
        setList([]);
        return;
      }
      url = url.replace("{id}", p);
    }
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setList(Boolean(response.data) ? response.data : []);
      let p = Boolean(response.data) ? (Boolean(field.propKey) ? response.data[0][field.propKey] : response.data[0].key) : undefined;
      dispatch(changeParentSelectValue({ key: field.inputName, value: p }));
    }
  };
  useEffect(() => {
    const getData = async p => {
      if (p !== parentValue) {
        await fillList(p);
      }
      setParentValue(p);
    };
    if (parentValue !== pValue) {
      getData(pValue).catch(console.error);
    }
  }, [parentValue, pValue]);

  return (
    <>
      <Form.Item {...layout} {...formItemProps}>
        <Select showSearch allowClear optionFilterProp="children" placeholder={field.label} style={{ width: "100%" }} {...otherProps}>
          {list
            ? list.map((i, index) => (
                <Option value={Boolean(field.propKey) ? i[field.propKey] : i.key} key={index}>
                  {Boolean(field.propValue) ? i[field.propValue] : i.value}
                </Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </>
  );
};

export default SelectComponent;
