import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Drawer, Cascader, InputNumber, Divider, Upload } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Input } from "antd";
import { Select } from "antd";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const dataListUnit = [];
const generateListUnit = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListUnit.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListUnit(node.children);
    }
  }
};

export class EldenEquipmentLegal extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentLegal",
      controllerName: "eldenEquipmentLegal",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],
      unitIdSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("EldenEquipmentLegalTitle");
    this.fillCode("EldenEquipmentLegalOptions");
    this.fillCode("EldenEquipmentLegalColumns");

    var responseAnswerType = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
    if (responseAnswerType.type === "ERROR") {
      error(responseAnswerType);
    }
    var responseUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitTree");
    if (responseUnit.type === "ERROR") {
      error(responseUnit);
    } else {
      generateListUnit(responseUnit.data);
    }

    this.setState({
      answerTypeSelectItems: Boolean(responseAnswerType.data) && responseAnswerType.data.length > 0 ? responseAnswerType.data : [],
      unitIdSelectItems: Boolean(responseUnit.data) && responseUnit.data.length > 0 ? responseUnit.data : [],
    }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/listAll";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.formRef.current.setFieldsValue({
        settings: [],
      });
    } else {
      let settings = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          let options = [];
          if (Boolean(element.options) && element.options.length > 0) {
            element.options.forEach(opt => {
              options.push(opt.codeId);
            });
          }
          element.options = options;

          let columns = [];
          if (Boolean(element.columns) && element.columns.length > 0) {
            element.columns.forEach(opt => {
              columns.push(opt.codeId);
            });
          }
          element.columns = columns;

          var parent = dataListUnit.find(p => p.key === element.unitId);
          if (Boolean(parent)) {
            element.unitId = parent.lineage;
          }

          settings.push(element);
        });
      }

      this.formRef.current.setFieldsValue({
        settings: settings,
      });
    }
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      eldenEquipmentTreeId: this.props.eldenEquipmentTreeId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(values) && Boolean(values.settings) && values.settings.length > 0) {
      values.settings.forEach(row => {

        console.log("AAA", row);
        var options = [];
        if (Boolean(row.options) && row.options.length > 0) {
          row.options.forEach(element => {
            options.push({
              ["codeId"]: element
            });
          });
        }

        var columns = [];
        if (Boolean(row.columns) && row.columns.length > 0) {
          row.columns.forEach(element => {
            columns.push({
              ["codeId"]: element
            });
          });
        }

        var _imageOptions = [];
        if (Boolean(this.state.fileList))
          this.state.fileList.forEach(element => {
            let url = null;
            if (Boolean(element.url)) {
              url = element.url;
            } else if (Boolean(element.response) && Boolean(element.response.url)) {
              url = element.response.url;
            }
            if (Boolean(url)) {
              _imageOptions.push({
                ["imageUrl"]: url
              });
            }
          });
        row.options = options;
        row.columns = columns;
        row.imageOptions = _imageOptions;

        row.unitId = Boolean(row.unitId) && row.unitId.length > 0 ? row.unitId[row.unitId.length - 1] : row.unitId;
      });
    }
    console.log("values", values);

    if (Boolean(this.props.eldenEquipmentTreeId) && this.props.eldenEquipmentTreeId > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.eldenEquipmentTreeId, values.settings);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.nextTab();
      }
    } else {
      showWarning("Equipment Log not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const settings = Boolean(this.formRef)
      && Boolean(this.formRef.current)
      && Boolean(this.formRef.current.getFieldValue("settings"))
      && Array.isArray(this.formRef.current.getFieldValue("settings"))
      && this.formRef.current.getFieldValue("settings").length > 0
      ? this.formRef.current.getFieldValue("settings")
      : []
      ;
    const isSettingsArray = Boolean(settings) && Array.isArray(settings) && settings.length > 0;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 6 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenEquipmentLegal.pageTitle" defaultMessage="Legal" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br /><br />
            <div hidden={false}>
              <Form.List name="settings">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenEquipmentLegal.Title"} defaultMessage={"Title"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenEquipmentLegal.AnswerType"} defaultMessage={"Answer Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenEquipmentLegal.Options"} defaultMessage={"Options"} />
                            </th>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"EldenEquipmentLegal.Unit"} defaultMessage={"Unit"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            const fieldSettings = isSettingsArray ? settings[field.name] : null;
                            const answerType = fieldSettings && fieldSettings.answerType;

                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "title"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "answerType"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "150px" }}
                                      onChange={value => {
                                        let list = this.formRef.current.getFieldValue("settings");
                                        let item = { ...list[field.name] };
                                        item.answerType = value;
                                        list[field.name] = item;
                                        this.formRef.current.setFieldsValue({
                                          "settings": list,
                                        });
                                        this.setState({
                                          "settings": list,
                                        });
                                      }}>
                                      {Boolean(this.state["answerTypeSelectItems"]) &&
                                        this.state["answerTypeSelectItems"].length > 0 &&
                                        this.state["answerTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  {
                                    isSettingsArray && fieldSettings && (answerType === "Matrix_Single" || answerType === "Matrix_Multiple") && (
                                      <Form.List name="matrixValues">
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map((field, index) => (
                                              <Form.Item
                                                {...field}
                                                {...layout}
                                                label={index + 1}
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                  }
                                                ]}
                                              >
                                                <Form.Item
                                                  {...field}
                                                  style={{
                                                    display: "inline-block",
                                                    width: "calc(80%)"
                                                  }}
                                                  name={[field.name, "title"]}
                                                >
                                                  <Input></Input>
                                                </Form.Item>

                                                <Form.Item {...field} hidden name={[field.name, "id"]}>
                                                  <InputNumber></InputNumber>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                              </Form.Item>
                                            ))}
                                            <Form.Item>
                                              <Row gutter={[16, 16]}>
                                                <Col md={8}></Col>
                                                <Col md={6}>
                                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                              </Row>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                    )}

                                  {
                                    isSettingsArray && fieldSettings && (answerType === "Matrix_Single" || answerType === "Matrix_Multiple") && (
                                      <Form.Item
                                        {...layout}
                                        label={<FormattedMessage id="EldenEquipmentLegal.columns" defaultMessage="Columns" />}
                                        name="columns"
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select
                                          mode="tags"
                                          style={{ width: "150px" }}
                                          placeholder="columns"
                                          value={this.state.selectedColumns}
                                          onChange={value => {
                                            this.setState({ selectedColumns: value });
                                          }}
                                          dropdownRender={menu => (
                                            <div>
                                              {menu}
                                              <Divider style={{ margin: "4px 0" }} />
                                              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                                <Input
                                                  style={{ flex: "auto" }}
                                                  value={this.state.codeName}
                                                  onChange={event => {
                                                    this.setState({
                                                      codeName: event.target.value
                                                    });
                                                  }}
                                                />
                                                <a
                                                  style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                                  onClick={() => this.addCode("EldenEquipmentLegalColumns")}
                                                >
                                                  <PlusOutlined /> Add item
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {Boolean(this.state.EldenEquipmentLegalColumnsSelectItems) &&
                                            this.state.EldenEquipmentLegalColumnsSelectItems.length > 0 &&
                                            this.state.EldenEquipmentLegalColumnsSelectItems.map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    )}

                                  {
                                    isSettingsArray && fieldSettings && (answerType === "Checkbox" || answerType === "Radiogroup"
                                      || answerType === "Dropdown" || answerType === "Matrix_Multiple") && (
                                      <Form.Item name={[field.name, "options"]}
                                        {...layout}
                                        label={<FormattedMessage id="EldenEquipmentLegal.Options" defaultMessage="Options" />}
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Select
                                          mode="tags"
                                          style={{ width: "150px" }}
                                          placeholder="Options"
                                          value={this.state.selectedOptions}
                                          onChange={value => {
                                            this.setState({ selectedOptions: value });
                                          }}
                                          dropdownRender={menu => (
                                            <div>
                                              {menu}
                                              <Divider style={{ margin: "4px 0" }} />
                                              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                                <Input
                                                  style={{ flex: "auto" }}
                                                  value={this.state.codeName}
                                                  onChange={event => {
                                                    this.setState({
                                                      codeName: event.target.value
                                                    });
                                                  }}
                                                />
                                                <a
                                                  style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                                  onClick={() => this.addCode("EldenEquipmentLegalOptions")}
                                                >
                                                  <PlusOutlined /> Add item
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {Boolean(this.state.EldenEquipmentLegalOptionsSelectItems) &&
                                            this.state.EldenEquipmentLegalOptionsSelectItems.length > 0 &&
                                            this.state.EldenEquipmentLegalOptionsSelectItems.map(i => (
                                              <Option key={i.id} value={i.id}>
                                                {i.name}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    )}

                                  {
                                    isSettingsArray && fieldSettings && answerType === "Rating" && (
                                      <Form.Item name={[field.name, "rateMax"]}
                                        {...layout}
                                        label={<FormattedMessage id="EldenEquipmentLegal.MaxRate" defaultMessage="Max Rate" />}
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <InputNumber />
                                      </Form.Item>
                                    )}

                                  {
                                    isSettingsArray && fieldSettings && answerType === "Imagepicker" && (
                                      <Form.Item name={[field.name, "imagepicker"]}
                                        {...layout}
                                        label={<FormattedMessage id="EldenEquipmentLegal.imagepicker" defaultMessage="Choose Images" />}
                                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                      >
                                        <Upload {...props} onChange={this.handleFileChange}>
                                          <AntButton>
                                            <UploadOutlined /> <FormattedMessage id="EldenEquipmentLegal.Upload" defaultMessage="Upload" />
                                          </AntButton>
                                        </Upload>
                                      </Form.Item>
                                    )}

                                  {
                                    isSettingsArray && fieldSettings && answerType === "Boolean" && (
                                      <Form.Item
                                        {...layout}
                                        label={<FormattedMessage id="EldenEquipmentLegal.boolean" defaultMessage="First" />}
                                        style={{ marginBottom: "5px" }}
                                      >
                                        <Row gutter={8}>
                                          <Col span={10}>
                                            <Form.Item name="labelTrue">
                                              <Input
                                                style={{
                                                  width: "100%"
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={4}>Last</Col>
                                          <Col span={10}>
                                            <Form.Item name="labelFalse">
                                              <Input
                                                style={{
                                                  width: "100%"
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    )}


                                </td>

                                <td>
                                  <Form.Item noStyle name={[field.name, "unitValue"]}>
                                    <InputNumber style={{ width: "150px" }} />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item noStyle name={[field.name, "unitId"]}>
                                    <Cascader showSearch allowClear optionFilterProp="children"
                                      style={{ width: "150px" }} options={this.state.unitIdSelectItems} />
                                  </Form.Item>
                                </td>

                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"EldenEquipmentLegal.AddNewLine"} defaultMessage={"Add New Line"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>

              <div hidden={false}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EldenEquipmentLegal.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenEquipmentLegal"} />
          </Drawer>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentLegal); 