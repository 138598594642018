import { Image } from "antd";
import React from "react";

export default function ImageGalleryFromUrls({ paths }) {
  const [gallery, setGallery] = React.useState(false);
  const [pathList] = React.useState(Boolean(paths) && paths.length > 0 ? paths.split(",") : []);
  return (
    <>
      {Boolean(paths) && pathList.length > 0 ? (
        <Image
          width={80}
          preview={{
            visible: false
          }}
          src={pathList[0].trim()}
          onClick={() => setGallery(true)}
        />
      ) : null}
      {Boolean(pathList) && pathList.length > 0 ? (
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              visible: gallery,
              onVisibleChange: vis => setGallery(vis)
            }}
          >
            {pathList.map((field, i) => (
              <Image key={i} src={field} />
            ))}
          </Image.PreviewGroup>
        </div>
      ) : null}
    </>
  );
}
