import "antd/dist/antd.css";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Form, Row, Col, Space, Input, Button as AntButton, Upload } from "antd";
import { ArrowDownOutlined, PlusOutlined, FileMarkdownOutlined, UploadOutlined } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import TextToParaLogFormatCreate from "./TextToParaLogFormatCreate";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../../Components/ElementUtils";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const { TextArea } = Input;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TextToParaLogContent extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TextToParaLogContent",
      controllerName: "textToParaLogContent",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      selectedItem: { "itemNo": "", "originalItemNo": "" },
      pageAutoDesign: null,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillWordText();
    this.fillItems();
  };

  fillItems = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillItems/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
    }

    let selectedItem = { "itemNo": "", "originalItemNo": "" };
    let itemSelectItems = Boolean(response.data) ? response.data : [];
    if (Boolean(itemSelectItems) && itemSelectItems.length > 0) {
      selectedItem = itemSelectItems[0];

      this.formRef.current.setFieldsValue({
        ...selectedItem
      });
    }

    this.setState({
      itemSelectItems: itemSelectItems,
      selectedItem: selectedItem,
    });
  };

  fillWordText = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillWordText/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
    }
    this.formRef.current.setFieldsValue({
      selection: response.data
    });
  }

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  getContent = async (row) => {
    console.log("row", row);

    if (!Boolean(row.id) || !Boolean(this.state.tts)) {
      return;
    }

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getContent/" + row.id + "/" + this.state.tts);
    if (response.type === "ERROR") {
      error(response);
    }

    this.formRef.current.setFieldsValue({
      ...response.data,
      tableUploadPath: Boolean(response.data) && Boolean(response.data.tableUploadPath) ? PrepareFileUploadEdit(response.data.tableUploadPath) : null,
      sketchUploadPath: Boolean(response.data) && Boolean(response.data.sketchUploadPath) ? PrepareFileUploadEdit(response.data.sketchUploadPath) : null
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.fillItems();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { selectedItem, tts } = this.state;
    if (!Boolean(selectedItem.id)) {
      showWarning("Auto Report Item not found.");
      return;
    }
    if (!Boolean(tts)) {
      showWarning("Text - Table - Sketch not found.");
      return;
    }
    console.log("values", values);
    const newItem = {
      ...values,
      textToParaLogFormatId: selectedItem.id,
      tts: tts,
    };
    newItem.tableUploadPath = PrepareFileUploadSave(newItem.tableUploadPath);
    newItem.sketchUploadPath = PrepareFileUploadSave(newItem.sketchUploadPath);

    if (Boolean(newItem.id) && newItem.id > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    } else {
      response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  generateWord = async () => {
    this.setState({ loadingButtonSubmit: true });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/wordCreate/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data) && Boolean(response.data.url)) {
      window.location.href = response.data.url;
    } else {
      showWarning("File cant found.");
    }
  };

  generatePdf = async () => {
    this.setState({ loadingButtonSubmit: true });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/raporCreate/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingButtonSubmit: false
      });
    } else if (Boolean(response.data) && Boolean(response.data.url)) {
      this.setState({
        pdfFile: response.data.url,
        modalPdfViewer: true,
        loadingButtonSubmit: false
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        loadingButtonSubmit: false
      });
      showError("There is no created report for selected item");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const handleTextSelection = () => {
      const textArea = document.getElementById("text-area");
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const selected = textArea.value.substring(start, end);

      // const beforeSelection = textArea.value.substring(0, start);
      // const afterSelection = textArea.value.substring(end);
      // const highlightedValue = `${beforeSelection}[${selected} - Seçilen metin]${afterSelection}`;

      this.setState({
        // renderedValue: highlightedValue,
        selectedText: selected,
      });
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const { selectedText, itemSelectItems, selectedItem } = this.state;

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <br />
                    <Row gutter={[16, 16]}>
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={() => this.setState({ modalAddNewShow: true, random: Math.random() })}>
                          <PlusOutlined /> Add New
                        </a>
                      </div>

                      {/*  */}

                      {Boolean(itemSelectItems) && itemSelectItems.length > 0 &&
                        itemSelectItems.map((item) => (
                          <div className="navi-item mb-2">
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {item.itemNo + "-" + item.originalTitle}
                                </Tooltip>
                              }
                            >
                              <a
                                href="#"
                                className={`navi-link py-4 ${selectedItem.itemNo + "-" + selectedItem.originalTitle === item.itemNo + "-" + item.originalTitle ? "active-link" : ""}`}
                                onClick={() => {
                                  this.setState({
                                    selectedItem: item
                                  }, () => {
                                    this.getContent(item)
                                  });
                                }}
                              >
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                  </span>
                                </span>
                                <span className="navi-text">
                                  {item.itemNo + "-" + item.originalTitle}
                                </span>
                              </a>
                            </OverlayTrigger>
                          </div>
                        ))}
                      <br />
                    </Row>
                  </div>
                </div>
              </div>
            </div>


            {Boolean(selectedItem) &&
              <div className="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch" >
                  <div className="form">
                    <div className="card-body">

                      <br /><br />
                      <div className="flex-row-fluid ml-lg-12">
                        <div className="card card-custom card-stretch" >
                          <div className="card-title align-items-start flex-column">
                            <Form.Item hidden name="id"></Form.Item>

                            <Row gutter={[16, 16]}>
                              <Col xs={4}>
                                <Button style={{ width: "100%" }} variant={this.state.tts === "Text" ? "success" : "outline-success"}
                                  onClick={() => {
                                    this.setState({
                                      tts: "Text"
                                    }, () => {
                                      this.getContent(selectedItem)
                                    });
                                  }}>
                                  <FormattedMessage id="TextToParaLogContent.Text" defaultMessage="Text" />
                                </Button>
                              </Col>
                              <Col xs={4}>
                                <Button style={{ width: "100%" }} variant={this.state.tts === "Table" ? "success" : "outline-success"} onClick={() => {
                                  this.setState({
                                    tts: "Table"
                                  }, () => {
                                    this.getContent(selectedItem)
                                  });
                                }}>
                                  <FormattedMessage id="TextToParaLogContent.Table" defaultMessage="Table" />
                                </Button>
                              </Col>
                              <Col xs={4}>
                                <Button style={{ width: "100%" }} variant={this.state.tts === "Sketch" ? "success" : "outline-success"} onClick={() => {
                                  this.setState({
                                    tts: "Sketch"
                                  }, () => {
                                    this.getContent(selectedItem)
                                  });
                                }}>
                                  <FormattedMessage id="TextToParaLogContent.Sketch" defaultMessage="Sketch" />
                                </Button>
                              </Col>
                              <Col xs={8}></Col>
                              <Col md={1}>
                                <AntButton
                                  style={{ border: "0px" }}
                                  onClick={this.generateWord}
                                  icon={<FileMarkdownOutlined style={{ color: "blue" }} fontSize="large" />}
                                ></AntButton>
                              </Col>

                              <Col md={1}>
                                <AntButton
                                  style={{ border: "0px" }}
                                  onClick={this.generatePdf}
                                  icon={<PictureAsPdfIcon style={{ color: "red" }} fontSize="large" />}
                                ></AntButton>
                              </Col>
                            </Row>

                            <br /> <br />
                            <Form.Item
                              name="selection"
                              rules={[
                                {
                                  required: false,
                                  message: (
                                    <FormattedMessage
                                      id="GENERAL.REQUIRED"
                                      defaultMessage="This field is required."
                                    />
                                  ),
                                },
                              ]}
                            >
                              <TextArea
                                id="text-area"
                                rows={8}
                                placeholder="Metni buraya yazın"
                                style={{ width: "100%" }}
                                onMouseUp={handleTextSelection}
                              />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                              <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}></Col>
                              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                <AntButton type="default" onClick={() => {
                                  if (!Boolean(selectedText)) {
                                    return;
                                  }
                                  let oldValue = this.formRef.current.getFieldValue("selectedText");
                                  this.formRef.current.setFieldsValue({
                                    selectedText: Boolean(oldValue) ? (oldValue + " " + selectedText) : selectedText,
                                  });
                                }}
                                  style={{ borderRadius: "", color: "#000000", borderColor: "", background: "#dcfade" }}
                                  icon={<ArrowDownOutlined />}></AntButton>
                              </Col>
                            </Row>
                            <br />
                            <div className="flex-row-fluid ml-lg-12">
                              <div className="card card-custom card-stretch" >
                                <div className="card-header py-3">
                                  <div className="card-title align-items-start flex-column">
                                    {selectedItem.itemNo + "-" + selectedItem.originalItemNo}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br></br>

                            <Form.Item
                              name="selectedText"
                              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <TextArea
                                rows={8}
                                placeholder="Selected Text"
                                style={{ width: "100%", height: "200%" }}
                              />
                            </Form.Item>


                            {this.state.tts === "Table" &&
                              <>
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.TableNo" defaultMessage="Table No" />}
                                    name="tableNo"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                }
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.TableTitle" defaultMessage="Table Title" />}
                                    name="tableTitle"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                }
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.File" defaultMessage="File" />}
                                    name="tableUploadPath"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                  >
                                    <Upload {...props}>
                                      <AntButton icon={<UploadOutlined />}>
                                        <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                      </AntButton>
                                    </Upload>
                                  </Form.Item>
                                }
                              </>
                            }

                            {this.state.tts === "Sketch" &&
                              <>
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.SketchNo" defaultMessage="Sketch No" />}
                                    name="sketchNo"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                }
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.SketchTitle" defaultMessage="Sketch Title" />}
                                    name="sketchTitle"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                }
                                {
                                  <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="TextToParaLogContent.File" defaultMessage="File" />}
                                    name="sketchUploadPath"
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                  >
                                    <Upload {...props}>
                                      <AntButton icon={<UploadOutlined />}>
                                        <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                      </AntButton>
                                    </Upload>
                                  </Form.Item>
                                }
                              </>
                            }


                            <Row gutter={[16, 16]}>
                              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                </Button>
                              </Col>
                              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Form >

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="TextToParaLogContent.AddNew" defaultMessage="Add New" />}
          open={this.state.modalAddNewShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalAddNewShow: false })}
          width={1000}
          centered
          content={
            <TextToParaLogFormatCreate textToParaLogId={this.props.textToParaLogId} random={this.state.random}
              close={() => {
                this.setState({ modalAddNewShow: false });
                this.fillItems();
              }} />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          onCancel={() => {
            this.setState({ modalPdfViewer: false });
          }}
          cancelButtonProps={{ hidden: false }}
          okButtonProps={{ hidden: true }}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          width={1200}
          centered
          content={
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TextToParaLogContent);