import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarningBasic } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import moment from "moment";
import { IApplicationContribution } from "@antv/xflow-core";
import {
  message,
  Col,
  Row,
  Table,
  Space,
  Select,
  Button as AntButton,
  Modal as AntModal,
  Spin,
  Input,
  DatePicker,
  Progress,
  Badge,
  Steps,
  Button,
  Form
} from "antd";
import { FormattedMessage } from "react-intl";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Step } = Steps;

const Vendors = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [progressType, setProgressType] = useState("line");

  useEffect(() => {
    asyncFetch();
  }, [page, limit, keyword]);

  const asyncFetch = async () => {
    let pagination = {
      page: page,
      limit: limit,
      keyword: keyword
    };
    setLoading(true);
    var response = await handleRequest("POST", "/api/dashboard/procurementProgress", pagination);
    setLoading(false);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      if (Boolean(data.details) && data.details.length > 0) {
        let index = data.details.findIndex(p => p.status === "Not Started");
        if (index !== -1) data.details[index].status = "In Progress";
      }
      setData(data);
    }
  };

  let columns = [
    {
      title: <FormattedMessage id="Vendors.Vendor" defaultMessage="Vendor" />,
      key: "vendor",
      render: record => record.vendor
    },
    {
      title: <FormattedMessage id="Vendors.Vendor" defaultMessage="Class" />,
      key: "class",
      render: record => record.class
    },
    {
      title: <FormattedMessage id="Vendors.ProductsQuantity" defaultMessage="Products Quantity" />,
      key: "productsQuantity",
      render: record => record.productsQuantity
    },
    {
      title: <FormattedMessage id="Vendors.Cost" defaultMessage="Cost" />,
      key: "cost",
      render: record => record.cost
    },
    {
      title: <FormattedMessage id="Vendors.DeliveryTime" defaultMessage="Delivery Time" />,
      key: "deliveryTime",
      render: record => record.deliveryTime
    },
    {
      title: <FormattedMessage id="Vendors.RejectionRate" defaultMessage="Rejection Rate" />,
      key: "rejectionRate",
      render: record => record.rejectionRate
    }
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={18}></Col>
        <Col lg={6}>
          <Select
            placeholder="Progress Type"
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              setLimit(5);
            }}
          >
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="Vendors.line" defaultMessage="Line" />
            </Option>
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="Vendors.Circle" defaultMessage="Circle" />
            </Option>
          </Select>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <div>
            <Table bordered columns={columns} dataSource={data} pagination={false} scroll={{ x: 600, y: 600, scrollToFirstRowOnChange: true }} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Vendors;
