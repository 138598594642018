import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Table, Input, Select, Tooltip, Col, Row, Button as AntButton, Badge, DatePicker, Card, Tree, Divider } from "antd";
import moment from "moment";
import {
  FilterOutlined,
  CloseCircleOutlined,
  NotificationOutlined,
  PlusOutlined,
  DollarOutlined,
  LineChartOutlined,
  ToolOutlined,
  FileOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  CheckSquareOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { FcFolder, FcFile } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { error, showError, showSuccess, showWarning } from "../../../MessageHelper";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import DraggableModal from "../../../Components/DraggableModal";
import CompanyProjectAssign from "./CompanyProjectAssign";
import CompanyProjectProgress from "./CompanyProjectProgress";
import CompanyProjectBudget from "./CompanyProjectBudget";
import { Resizable } from "react-resizable";

const { Option } = Select;

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

//# Columns-Resize
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class ProjectLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectLog",
      controllerName: "companyProject",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,

      id: null,
      tableList: [],
      selectedOptions: [],
      countrySelectItems: [],
      clientSelectItems: [],
      projectsTreeIds: [],

      activeTabKey: "projectInfo",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefContractor = React.createRef();
  formRefContractUpdates = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCountries();
    this.fillClients();
    this.fillTree();
    this.fillCode("ProjectLog.contractorId");

    var responsePreparedById = await handleRequest("GET", "/api/" + this.state.controllerName + "/preparedById");
    if (responsePreparedById.type === "ERROR") {
      error(responsePreparedById);
    }
    this.setState({
      preparedByIdSelectItems: Boolean(responsePreparedById.data) ? responsePreparedById.data : []
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  fillCountries = async () => {
    let url = "/api/" + this.state.controllerName + "/countries";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        countrySelectItems: Boolean(response.data) ? response.data : []
      });
  };

  fillCities = async countryId => {
    if (Boolean(countryId)) {
      let url = "/api/" + this.state.controllerName + "/cities/" + countryId;
      var response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          cityIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        cityIdSelectItems: []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
      endDate: Boolean(row.endDate) ? moment(row.endDate) : null,
      contractSignDate: Boolean(row.contractSignDate) ? moment(row.contractSignDate) : null,
      landDeliveryDate: Boolean(row.landDeliveryDate) ? moment(row.landDeliveryDate) : null,
      kickOffDate: Boolean(row.kickOffDate) ? moment(row.kickOffDate) : null,
      projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
      contractEndDate: Boolean(row.contractEndDate) ? moment(row.contractEndDate) : null
    });

    if (Boolean(row.countryId)) {
      this.fillCities(row.countryId);
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      checkedKeys: { checked: row.projectsTreeIds }
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let contractUpdateAction = {
      name: "Contract Updates",
      icon: <NotificationOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.contractUpdateActionModal.bind(this)
    };
    let contractorAction = {
      name: "Contractor",
      icon: <FileOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.contractorActionModal.bind(this)
    };
    let assignAction = {
      name: "Assign",
      icon: <ToolOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.assignActionModal.bind(this)
    };
    let progressAction = {
      name: "Progress",
      icon: <LineChartOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.progressActionModal.bind(this)
    };
    let budgetAction = {
      name: "Budget",
      icon: <DollarOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.budgetActionModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(contractUpdateAction);
    actionList.push(contractorAction);
    actionList.push(assignAction);
    actionList.push(progressAction);
    actionList.push(budgetAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  contractUpdateActionModal = row => {
    this.setState(
      {
        modalContractUpdateAction: true,
        id: row.id
      },
      () => {
        this.formRefContractUpdates.current.setFieldsValue({
          ...row,
          contractSignDate: Boolean(row.contractSignDate) ? moment(row.contractSignDate) : null,
          landDeliveryDate: Boolean(row.landDeliveryDate) ? moment(row.landDeliveryDate) : null,
          kickOffDate: Boolean(row.kickOffDate) ? moment(row.kickOffDate) : null,
          projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
          contractEndDate: Boolean(row.contractEndDate) ? moment(row.contractEndDate) : null
        });
      }
    );
  };

  contractorActionModal = row => {
    this.setState(
      {
        modalContractorAction: true,
        id: row.id
      },
      () => {
        this.formRefContractor.current.setFieldsValue({
          ...row
        });
        if (Boolean(row.constructorCountryId)) {
          this.fillCities(row.constructorCountryId);
        }
      }
    );
  };

  assignActionModal = row => {
    this.setState({
      modalAssignAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  progressActionModal = row => {
    this.setState({
      modalProgressAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  budgetActionModal = row => {
    this.setState({
      modalBudgetAction: true,
      id: row.id,
      random: Math.random()
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  save = async values => {
    const { activeTabKey } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      projectsTreeIds:
        Boolean(this.state.checkedKeys) && Boolean(this.state.checkedKeys.checked) && this.state.checkedKeys.checked.length > 0
          ? this.state.checkedKeys.checked
          : []
    };
    console.log("newItem", newItem);
    if (!Boolean(activeTabKey)) {
      showWarning("Tab not selected.");
      return;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (activeTabKey === "projectInfo") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateProjectInfo/" + newItem.id, newItem);
      } else if (activeTabKey === "contractInfo") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractInfo/" + newItem.id, newItem);
      } else if (activeTabKey === "contractor") {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractor/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveContractor = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    console.log("newItem", newItem);

    let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractor/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalContractorAction: false
      });
    }
  };

  saveContractUpdates = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    console.log("newItem", newItem);

    let response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateContractInfo/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalContractUpdateAction: false
      });
    }
  };

  saveProjectStatus = async values => {
    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveProjectStatus/" + id + "/" + values.completed);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalProjectStatusAction: false
        });
      }
    } else {
      showWarning("Company project not found.");
    }
  };

  addClient = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "ProjectLogClientId"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillClients();
      }
    } else {
      showError("Please enter code");
    }
  };

  fillClients = async () => {
    const response = await handleRequest("GET", "/api/codes/type/ProjectLogClientId");
    if (Boolean(response.data)) {
      this.setState({
        clientSelectItems: response.data
      });
    }
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  onTab2Change = key => {
    this.setState({ activeTabKey: key });
  };

  onChangeItemGroupId = async value => {};

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        key: "key",
        render: this.action,
        width: 70
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        width: 70
      },
      {
        title: "Status",
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: "Responsible Department",
        key: "responsibleDepartment",
        render: record => {
          return record.responsibleDepartment;
        },
        ...getColumnFilter({
          inputName: "responsibleDepartment",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: "Responsible Person",
        key: "responsiblePerson",
        render: record => {
          return record.responsiblePerson;
        },
        ...getColumnFilter({
          inputName: "responsiblePerson",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: "Contractor",
        key: "contractorId",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.contractorActionModal(record)}
              >
                {record.contractorName}
              </div>
            </React.Fragment>
          );
        },
        width: 100
      },
      {
        title: "Tendering Name",
        key: "tenderingName",
        render: record => (
          <Tooltip title={record.tenderingName}>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.tenderingName}</div>
          </Tooltip>
        ),
        ...getColumnFilter({
          inputName: "tenderingName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: "Project Name",
        key: "projectFullName",
        render: record => (
          <Tooltip title={record.projectFullName}>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.projectFullName}</div>
          </Tooltip>
        ),
        ...getColumnFilter({
          inputName: "projectFullName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        width: 100
      },
      {
        title: "Project Type",
        key: "projectType",
        render: record => {
          return (
            <Tooltip title={record.projectsTreeNames.join(" - ")}>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{record.projectsTreeNames.join(" - ")}</div>
            </Tooltip>
          );
        },
        width: 100
      },
      {
        title: "Address",
        key: "address",
        render: record => {
          let address = [];
          if (record.constructorAddress) {
            address.push(record.constructorAddress);
          }
          if (record.constructorCityName) {
            address.push(record.constructorCityName);
          }
          if (record.constructorCountryName) {
            address.push(record.constructorCountryName);
          }
          return (
            <Tooltip title={address.join(" / ")}>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{address.join(" / ")}</div>
            </Tooltip>
          );
        },
        width: 100
      },
      {
        title: "Progress",
        key: "progress",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.progressActionModal(record)}
              >
                {record.physicalProgress}
              </div>
            </React.Fragment>
          );
        },
        width: 100
      },
      {
        title: "Contract Info",
        key: "contractInfo",
        render: record => {
          return (
            <AntButton
              onClick={() => {
                this.contractUpdateActionModal(record);
              }}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ProfileOutlined fontSize="large" />}
            ></AntButton>
          );
        },
        width: 100
      },
      {
        title: "Budget Info",
        key: "budgetInfo",
        render: record => {
          return (
            <AntButton
              onClick={() => {
                this.budgetActionModal(record);
              }}
              style={{ border: "0px", color: "#c51b23" }}
              icon={<AccountBookOutlined fontSize="large" />}
            ></AntButton>
          );
        },
        width: 100
      },
      {
        title: "Assignments",
        key: "assignments",
        render: record => {
          return (
            <AntButton
              onClick={() => {
                this.assignActionModal(record);
              }}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<CheckSquareOutlined fontSize="large" />}
            ></AntButton>
          );
        },
        width: 100
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }))
        : Boolean(columns) &&
          columns.length > 0 &&
          columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
              width: column.width,
              onResize: handleResize(index)
            })
          }));

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const { activeTabKey } = this.state;
    const tabList = [
      {
        key: "projectInfo",
        label: "projectInfo",
        tab: "Project Info"
      },
      {
        key: "contractInfo",
        label: "contractInfo",
        tab: "Contract Info"
      },
      {
        key: "contractor",
        label: "contractor",
        tab: "Contractor"
      }
    ];

    const contentList = {
      projectInfo: (
        <>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.tenderingName" defaultMessage="Tendering Name" />}
              name="tenderingName"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ProjectName" defaultMessage="Project Name" />}
              name="projectFullName"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.projectType" defaultMessage="Project Type" />}
              name="projectType"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                checkable
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                halfCheckedKeys={this.state.halfCheckedKeys}
              />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.scope" defaultMessage="Scope" />}
              name="scope"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
              name="countryId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => {
                  this.fillCities(value);
                }}
              >
                {this.state.countrySelectItems.map(i => (
                  <Option value={i.key}>{i.value}</Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PersonnelExperience.cityId" defaultMessage="City" />}
              name="cityId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                {Boolean(this.state["cityIdSelectItems"]) &&
                  this.state["cityIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
              name="address"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
        </>
      ),

      contractInfo: (
        <>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.ContractSignDate" defaultMessage="Contract Sign Date" />}
              style={{
                marginBottom: 0
              }}
              name="contractSignDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.LandDeliveryDate" defaultMessage="Land Delivery Date" />}
              style={{
                marginBottom: 0
              }}
              name="landDeliveryDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.KickOffDate" defaultMessage="Kick-off Date" />}
              style={{
                marginBottom: 0
              }}
              name="kickOffDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.ProjectStartDate" defaultMessage="Project Start Date" />}
              style={{
                marginBottom: 0
              }}
              name="projectStartDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.ContractEndDate" defaultMessage="Contract End Date" />}
              style={{
                marginBottom: 0
              }}
              name="contractEndDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.BudgetOfProject" defaultMessage="Budget of Project" />}
              name="budgetOfProject"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
        </>
      ),

      contractor: (
        <>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ContractorName" defaultMessage="Constructor" />}
              name="contractorId"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={this.state.contractorId}
                onChange={value => {
                  this.formRef.current.setFieldsValue({
                    contractorId: value
                  });
                  this.setState({ contractorId: value });
                }}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                      <Input
                        style={{ flex: "auto" }}
                        value={this.state.codeName}
                        onChange={event => {
                          this.setState({
                            codeName: event.target.value
                          });
                        }}
                      />
                      <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("ProjectLog.contractorId")}>
                        Add item
                      </AntButton>
                    </div>
                  </div>
                )}
              >
                {Boolean(this.state["ProjectLog.contractorIdSelectItems"]) &&
                  this.state["ProjectLog.contractorIdSelectItems"].length > 0 &&
                  this.state["ProjectLog.contractorIdSelectItems"].map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
              name="constructorCountryId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => {
                  this.fillCities(value);
                }}
              >
                {this.state.countrySelectItems.map(i => (
                  <Option value={i.key}>{i.value}</Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PersonnelExperience.City" defaultMessage="City" />}
              name="constructorCityId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                {Boolean(this.state["cityIdSelectItems"]) &&
                  this.state["cityIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
              name="constructorAddress"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.contactPerson" defaultMessage="Contact Person" />}
              name="contactPerson"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ContractLog.ContactNo" defaultMessage="Contact No" />}
              name="contactNo"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
        </>
      )
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="projectLog.pageTitle" defaultMessage="Project Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={22}></Col>
              {/* <Col md={1}>{excelExport}</Col> */}
              <Col md={1}>{/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}</Col>
              <Col md={1}>
                <AntButton
                  tooltip={"Create New"}
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
                activeTabKey={this.state.activeTabKey}
                tabBarExtraContent={<a href="#">More</a>}
                tabList={tabList}
                onTabChange={this.onTab2Change}
              >
                {contentList[activeTabKey]}

                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </Card>
              <br></br>
            </div>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              size="small"
              bordered
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              scroll={{ y: 500, x: 900 }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.contractUpdates" defaultMessage="Contract Updates" />}
          width={900}
          centered
          open={this.state.modalContractUpdateAction}
          onOk={() => this.formRefContractUpdates.current.submit()}
          onCancel={() => this.setState({ modalContractUpdateAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveContractUpdates}
              onFinishFailed={onFinishFailed}
              ref={this.formRefContractUpdates}
            >
              <>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.ContractSignDate" defaultMessage="Contract Sign Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="contractSignDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.LandDeliveryDate" defaultMessage="Land Delivery Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="landDeliveryDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.KickOffDate" defaultMessage="Kick-off Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="kickOffDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.ProjectStartDate" defaultMessage="Project Start Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="projectStartDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductStatistics.ContractEndDate" defaultMessage="Contract End Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="contractEndDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.BudgetOfProject" defaultMessage="Budget of Project" />}
                    name="budgetOfProject"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.contractor" defaultMessage="Contractor" />}
          width={900}
          centered
          open={this.state.modalContractorAction}
          onOk={() => this.formRefContractor.current.submit()}
          onCancel={() => this.setState({ modalContractorAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveContractor} onFinishFailed={onFinishFailed} ref={this.formRefContractor}>
              <>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.ContractorName" defaultMessage="Constructor" />}
                    name="contractorId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      value={this.state.contractorId}
                      onChange={value => {
                        this.formRef.current.setFieldsValue({
                          contractorId: value
                        });
                        this.setState({ contractorId: value });
                      }}
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                            <Input
                              style={{ flex: "auto" }}
                              value={this.state.codeName}
                              onChange={event => {
                                this.setState({
                                  codeName: event.target.value
                                });
                              }}
                            />
                            <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("ProjectLog.contractorId")}>
                              Add item
                            </AntButton>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["ProjectLog.contractorIdSelectItems"]) &&
                        this.state["ProjectLog.contractorIdSelectItems"].length > 0 &&
                        this.state["ProjectLog.contractorIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
                    name="constructorCountryId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      onChange={value => {
                        this.fillCities(value);
                      }}
                    >
                      {this.state.countrySelectItems.map(i => (
                        <Option value={i.key}>{i.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PersonnelExperience.City" defaultMessage="City" />}
                    name="constructorCityId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                      {Boolean(this.state["cityIdSelectItems"]) &&
                        this.state["cityIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.Address" defaultMessage="Address" />}
                    name="constructorAddress"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.contactPerson" defaultMessage="Contact Person" />}
                    name="contactPerson"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ContractLog.ContactNo" defaultMessage="Contact No" />}
                    name="contactNo"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.assign" defaultMessage="Assign" />}
          width={1200}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalAssignAction}
          onCancel={() => this.setState({ modalAssignAction: false })}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectAssign
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalAssignAction: false })}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.progress" defaultMessage="Project Progress" />}
          width={900}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalProgressAction}
          onCancel={() => this.setState({ modalProgressAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectProgress
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalProgressAction: false })}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectLog.budget" defaultMessage="Budget" />}
          width={800}
          centered
          okButtonProps={{ hidden: true }}
          open={this.state.modalBudgetAction}
          onCancel={() => this.setState({ modalBudgetAction: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <CompanyProjectBudget
              companyProjectId={this.state.id}
              random={this.state.random}
              close={() => this.setState({ modalBudgetAction: false })}
            />
          }
        />
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectLog);
