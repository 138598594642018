import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import {
  Input,
  Form,
  Row,
  Col,
  Button as AntButton,
  InputNumber, Select, Cascader, Space, Checkbox
} from "antd";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { round } from "mathjs";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TenderingOnGoingProjectSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "TenderingOnGoingProjectSettings",
      controllerName: "tenderingOnGoingProjectSettings",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      nodesShowHide: [],
      loadedKeys: [],
      settingsList: [],
      unitIdSelectItems: [],
      settingsList: [],
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }
  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseExpenses = await handleRequest("GET", "/api/" + this.state.controllerName + "/getTenderingOnGoingProjectSettings");
    if (responseExpenses.type === "ERROR") {
      error(responseExpenses);
      this.setState({
        settingsList: [],
      });
      this.formRef.current.setFieldsValue({
        settingsList: []
      });
    } else {
      let settingsList = Boolean(responseExpenses.data) ? responseExpenses.data : [];
      this.setState({
        settingsList: settingsList,
      });

      this.formRef.current.setFieldsValue({
        settingsList: settingsList
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     settingsList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       settingsList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       settingsList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      showOrderModal: false,
      showPasteModal: false,
    });
  };

  onDragEnter = info => { };

  saveAll = async values => {
    console.log("values", values);
    if (Boolean(values) && Boolean(values.settingsList) && values.settingsList.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", values.settingsList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Not found.");
    }
  };

  calcWeight = () => {
    let settingsList = this.formRef.current.getFieldValue("settingsList");
    console.log("settingsList", settingsList);
    let totalTableScore = 0;
    settingsList.forEach(element => {
      if (Boolean(element.score)) {
        totalTableScore += element.score;
      }
    });
    console.log("totalTableScore", totalTableScore);

    settingsList.forEach(element => {
      if (Boolean(element.score) && element.score > 0 && totalTableScore > 0) {
        element.weight = round((element.score / totalTableScore) * 100, 2);
      }
      if (!Boolean(element.score)) {
        element.weight = null;
      }
    });

    console.log("settingsList", settingsList);
    this.formRef.current.setFieldsValue({
      settingsList: settingsList
    });
    this.setState({
      settingsList: settingsList
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const settingsList = Boolean(this.state.settingsList) && this.state.settingsList.length > 0 ? this.state.settingsList : [];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.saveAll} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <br />
          <Form.List name="settingsList">
            {(fields, { add, remove }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingOnGoingProjectSettings.Settings"} defaultMessage={"Settings"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingOnGoingProjectSettings.Score"} defaultMessage={"Score"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingOnGoingProjectSettings.Weight"} defaultMessage={"Weight"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item noStyle name={[field.name, "selected"]} valuePropName="checked">
                                <Checkbox></Checkbox>
                              </Form.Item>
                            </td>
                            <td>
                              {Boolean(settingsList) &&
                                settingsList.length > 0 &&
                                Boolean(settingsList[field.key]) &&
                                Boolean(settingsList[field.key].key)
                                ?
                                <Form.Item noStyle name={[field.name, "name"]}>
                                  <Input style={{ width: "100%" }} readOnly />
                                </Form.Item>
                                :
                                <Form.Item noStyle name={[field.name, "name"]}>
                                  <Input style={{ width: "100%" }} />
                                </Form.Item>
                              }
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "score"]}>
                                <InputNumber min={0} style={{ width: "100%" }}
                                  onChange={value => {
                                    const { settingsList } = this.state;
                                    let fChild = settingsList[field.key];
                                    fChild.score = value;
                                    this.setState({ settingsList }, this.calcWeight);
                                  }} />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "weight"]}>
                                <InputNumber readOnly style={{ width: "100%" }} value={
                                  Boolean(settingsList) &&
                                    settingsList.length > 0 &&
                                    Boolean(settingsList[field.key]) &&
                                    Boolean(settingsList[field.key].weight)
                                    ? settingsList[field.key].weight : 0} />
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"TenderingOnGoingProjectSettings.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%", marginTop: "10px" }}
                disabled={Boolean(this.state.settingsList) && this.state.settingsList.length > 0 ? false : true}
                loading={this.state.loading}
              >
                <FormattedMessage id="TenderingOnGoingProjectSettings.SaveAll" defaultMessage="Save All" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TenderingOnGoingProjectSettings);