import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";
const { Option } = Select;

const SalesmanDiscountRates = () => {
  const [tableList, setTableList] = useState([]);
  const [period, setPeriod] = useState("CASH");
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5" ,"10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });
  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    const search = {
      firstResult: pagination.current - 1,
      maxResults: pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/salesmanDiscountRates", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
    }
  };

  const restartTable = async (params = {}) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/salesmanDiscountRates", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      },)
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      },
    },
    
     
        {
          title: <FormattedMessage id="SalesmanDiscountRates.TotalofInvoice" defaultMessage="Total of Invoice" />,
          key: "totalofInvoice",
          render: record => {
            return record.totalofInvoice;
          },
          align: "center"
        },
        {
          title: <FormattedMessage id="SalesmanDiscountRates.Discount" defaultMessage="Discount" />,
          key: "discount",
          render: record => {
            return record.discount;
          },
          align: "center"
        },
        {
            title: <FormattedMessage id="SalesmanDiscountRates.DiscountRate" defaultMessage="Discount %" />,
            key: "discountRate",
            render: record => {
              return record.discountRate=-record.discount*100/(record.totalofInvoice+(-record.discount));
            },
            align: "center"
          },
          
        
    ]

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={4}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />
          
        </Col><Col lg={6}>
        <Select
            placeholder="Period"
            defaultActiveFirstOption
            allowClear
            value={period}
            className="w-100"
            onChange={value => {
              setPeriod(value);
            }}
          >
            <Option key={"CASH"} value={"CASH"}>
              <FormattedMessage id="period.cash" defaultMessage="Cash" />
            </Option>
            <Option key={"CREDIT"} value={"CREDIT"}>
              <FormattedMessage id="period.credit" defaultMessage="Credit" />
            </Option>
            <Option key={"BANKTRANSFER"} value={"BANKTRANSFER"}>
              <FormattedMessage id="period.bankTransfer" defaultMessage="Bank Transfer" />
            </Option>
            <Option key={"CHEQUE"} value={"CHEQUE"}>
              <FormattedMessage id="period.cheque" defaultMessage="Cheque" />
            </Option>
            <Option key={"NOTE"} value={"NOTE"}>
              <FormattedMessage id="period.note" defaultMessage="Note" />
            </Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Table
            bordered
            columns={columns}
            dataSource={tableList} 
            onChange={handleTableChange}
            pagination={pagination}
          />
        </Col>
        
      </Row>
    </>
  );
};

export default SalesmanDiscountRates;
