import { Form, Input } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";

const PasswordComponent = ({ field, layout }) => {
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <Input.Password placeholder={field.label} />
      </Form.Item>
    </>
  );
};

export default PasswordComponent;
