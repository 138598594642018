import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Modal as AntModal, Alert, Button as AntButton, Select, Typography } from "antd";
import { handleRequest } from "../../ApiConnector";
import CommentIcon from "@material-ui/icons/Comment";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const { Option } = Select;
const { Text } = Typography;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PersonnelContacts extends Component {
  constructor() {
    super();
    this.state = {
      controller: "personnelLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      personnelsSelectItems: [],
      totalBalance: 0,
      personnelLogId: -1,
      salaryDetailList: [],

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillPersonnelByUserId();
  };

  fillPersonnelByUserId = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controller + "/getPersonnelContactByPersonnelId/" + this.props.user.personnelLogId);
    if (response.type === "ERROR") {
      this.error(response);
    } else {
      this.setState({
        personnel: Boolean(response.data) ? response.data : [],
      });
    }
  };

  render() {
    const user = this.props.user;
    const personnel = this.state.personnel;
    console.log("props", this.props);
    console.log("user", user);
    console.log("personnel", personnel);
    return (
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">

            </div>
            <div className="card-toolbar"></div>
          </div>
          <div className="form">
            <div className="card-body">
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mb-6">Contacts</h5>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Adress</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(personnel) && Boolean(personnel) ? personnel.address : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">GSM</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(personnel) && Boolean(personnel) ? personnel.phoneNumber : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Company GSM</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Personnel Email</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(personnel) && Boolean(personnel) ? personnel.email : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Company Email</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Emergency Person</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Emergency Phone No</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? personnel.emergencyPhoneNumber : ""}
                    />
                  </div>
                </div>
              </div>
              <br>
              </br>
              <br>
              </br>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Home Country</label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(personnel) && Boolean(personnel) ? personnel.country.nicename : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Address </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(personnel) && Boolean(personnel) ? personnel.address : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Emergency Person </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly
                    className={`form-control form-control-lg form-control-solid`}
                    name="firstname"
                    value={Boolean(user) && Boolean(user.companyId) ? user.companyId : ""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Emergency Phone No</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      readOnly
                      className={`form-control form-control-lg form-control-solid`}
                      name="firstname"
                      value={Boolean(personnel) && Boolean(personnel) ? personnel.emergencyPhoneNumber : ""}
                    />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelContacts);
