import { Form, Input } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";

const HiddenComponent = props => {
  const { field, layout } = props;
  let formItemProps = PrepareFormItemProps(field);
  delete formItemProps.label;
  return (
    <>
      <Form.Item noStyle {...formItemProps}>
        <Input type={"hidden"} />
      </Form.Item>
    </>
  );
};

export default HiddenComponent;
