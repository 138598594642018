import { DownloadOutlined, FilterOutlined, SendOutlined, UploadOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Cascader, Col, DatePicker, Form, Input, Modal as AntModal, Row, Select, Table, Tabs, Tooltip, Upload } from "antd";
import "antd/dist/antd.css";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../ApiConnector";
import { error, showError, showSuccess } from "../MessageHelper";
import DynamicActionMenu from "./CustomMenu/DynamicActionMenu";
import { ExcelReader } from "./excel/ExcelReader";
import UserReceivedMessages from "./_partials/UserReceivedMessages";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

const itemList = [];

const generateItemList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    itemList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle,
      page: node.data.page
    });
    if (node.children) {
      generateItemList(node.children);
    }
  }
};

export class UserMessages extends Component {
  constructor({ location }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      currentUrl: "",
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      messageToUserIdSelectItems: [],
      message: "",
      currentTabKey: "1",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000"],
        total: 0
      },
      loading: false,
      sortField: "lastUpdateDate",
      sortOrder: "descend",
      modalSendShow: false,
      excelData: [],
      selectedMessage: {}
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    // let location = window.location;
    // let url = getCurrentUrl(location);
    // this.setCascaderMenu(url);
    // this.setState({ currentUrl: url });
  };
  componentDidUpdate = async (prevProps, prevState) => {};

  fillComboboxes = async () => {
    this.getDynamicMenu();
    var responseUsers = await handleRequest("GET", "/api/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        messageToUserIdSelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }
  };

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");
    if (responseTree.data.length !== 0) {
      generateItemList(responseTree.data.treeTable);
      this.setState({
        menuIdSelectItems: responseTree.data.cascader,
        menuIdTableList: responseTree.data.treeTable
      });
    }
  };

  setCascaderMenu = val => {
    val = val.substring(val.indexOf("/") + 1);
    var item = itemList.find(p => p.page === val);
    let itemId = [];
    if (Boolean(item)) {
      itemId = item.lineage;
    }
    this.formRef.current.setFieldsValue({
      menuId: itemId
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "lastUpdateDate",
      sortOrder: "descend",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var item = itemList.find(p => p.key === row.menuId);
    let itemId = [];

    if (Boolean(item)) {
      itemId = item.lineage;
    }
    console.log(row, item, itemList);
    let uploadedFiles = [];
    let photoPath = Boolean(row.filePath) ? row.filePath.split(",") : [];
    if (Boolean(photoPath) && photoPath.length > 0) {
      photoPath.forEach((item, index) => {
        let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
        uploadedFiles.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      menuId: itemId,
      filePath: uploadedFiles
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  replyMessage = row => {
    this.formRef.current.setFieldsValue({
      messageToUserId: row.createUserId,
      subject: "RE: " + row.subject,
      message: "<p><br></p><p><br></p><p>-------------------</p>" + row.message
    });
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      currentTabKey: "2"
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  closeModal = row => {
    this.setState({
      modalCloseShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/userMessage/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  closeMessage = async () => {
    const closedItem = {
      id: this.state.id
    };
    var response = await handleRequest("PATCH", "/api/userMessage/close/" + closedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalCloseShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/userMessage/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      menuId: Boolean(values.menuId) && values.menuId.length > 0 ? values.menuId[values.menuId.length - 1] : null
    };
    let pathList = [];
    if (Boolean(newItem.filePath))
      newItem.filePath.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.filePath = pathList.toString();

    // if (newItem.id === null) {
    newItem.status = true;
    var response = await handleRequest("POST", "/api/userMessage", newItem);
    // } else {
    //   response = await handleRequest("PUT", "/api/userMessage/" + newItem.id, newItem);
    // }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  resend = row => {
    this.setState({
      modalSendShow: true,
      selectedMessage: row
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/userMessage/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Re Send",
              icon: <SendOutlined />,
              actionClick: this.resend.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };
  normalizingFileUpload = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };

  onMessageChange = (html, delta, source, editor) => {
    this.setState({ message: html });
    console.log(html);
    return;
  };
  sendMultipleTo = async () => {
    const { sendMultipleTo } = this.state;
    if (!Boolean(sendMultipleTo) || sendMultipleTo.length === 0) {
      showError("Please select at least one user");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="UserMessage.messageToUserId" defaultMessage="To" />,
        key: "messageToUserId",
        render: record => {
          return Boolean(record.messageToUser) ? record.messageToUser : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              allowClear
              placeholder="User"
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.messageToUserId) ? this.state.pagination.messageToUserId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  messageToUserId: value,
                  messageToUserIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["messageToUserIdSelectItems"]) &&
                this.state["messageToUserIdSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name + " " + i.surname}
                    <br />
                    {i.email}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.messageToUserIdColor) ? this.state.pagination.messageToUserIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.menuId" defaultMessage="Menu" />,
        key: "menuId",
        render: record => {
          return Boolean(record.menu) ? record.menu.menuTitle : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.menuId) ? this.state.pagination.menuId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  menuId: e.target.value,
                  menuIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.menuIdColor) ? this.state.pagination.menuIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.subject" defaultMessage="Subject" />,
        key: "subject",
        render: record => {
          return record.subject;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.subject) ? this.state.pagination.subject : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  subject: e.target.value,
                  subjectColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.subjectColor) ? this.state.pagination.subjectColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.message" defaultMessage="Message" />,
        key: "message",
        render: record => {
          return (
            <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.message) }}></div>}>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  lineClamp: "2",
                  WebkitLineClamp: "2"
                }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.message) }}
              ></div>
            </Tooltip>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.message) ? this.state.pagination.message : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  message: e.target.value,
                  messageColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.messageColor) ? this.state.pagination.messageColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.filePath" defaultMessage="File" />,
        key: "filePath",
        render: record => {
          let path = record.filePath;
          if (Boolean(path)) {
            path = path.split(",");
            let buttons = path.map((item, index) => {
              return (
                <AntButton key={index} href={item} target="_blank" type="primary" shape="round" icon={<DownloadOutlined />}>
                  <span>{index + 1}</span>
                </AntButton>
              );
            });
            return buttons;
          } else return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.filePath) ? this.state.pagination.filePath : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  filePath: e.target.value,
                  filePathColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.filePathColor) ? this.state.pagination.filePathColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return Boolean(record.status) ? "Open" : "Closed";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.status) ? this.state.pagination.status : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  status: e.target.value,
                  statusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.statusColor) ? this.state.pagination.statusColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="UserMessage.finishDate" defaultMessage="Close Date" />,
        key: "finishDate",
        render: record => {
          return record.finishDate;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.finishDate) ? this.state.pagination.finishDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  finishDate: e.target.value,
                  finishDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.finishDateColor) ? this.state.pagination.finishDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Notes" hideElement={true}>
            <ExcelSheet name="Notes" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="Menu" value="menuId" />
              <ExcelColumn label="Subject" value="subject" />
              <ExcelColumn label="Message" value="message" />
              <ExcelColumn label="File" value="filePath" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Finish Date" value="finishDate" />
              <ExcelColumn label="Last Update Date" value="lastUpdateDate" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "code"],
        ["clean"]
      ]
    };

    const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "code"];
    return (
      <div className="card card-custom" style={{ minHeight: "100%" }}>
        <Tabs type="card" activeKey={this.state.currentTabKey} onChange={activeKey => this.setState({ currentTabKey: activeKey })}>
          <TabPane tab="Received" key={1}>
            <Row gutter={[16, 16]}>
              <Col md={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={() => {
                    this.setState({
                      hideInputs: false,
                      hideSave: false,
                      hideUpdate: true,
                      currentTabKey: "2"
                    });
                  }}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                >
                  Create New Message
                </AntButton>
              </Col>
            </Row>
            <UserReceivedMessages onReply={this.replyMessage.bind(this)} />
          </TabPane>
          <TabPane tab="Sent" key={2} forceRender={true}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Row gutter={[16, 16]}>
                <Col md={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                  {excelExport}
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="UserMessage.messageToUserId" defaultMessage="To" />}
                  name="messageToUserId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} placeholder="User">
                    {Boolean(this.state["messageToUserIdSelectItems"]) &&
                      this.state["messageToUserIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name + " " + i.surname}
                          <br />
                          {i.email}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="UserMessage.menuId" defaultMessage="Menu" />}
                    name="menuId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Cascader style={{ width: "100%" }} options={this.state.menuIdSelectItems} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="UserMessage.subject" defaultMessage="Subject" />}
                    name="subject"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input placeholder="Subject" style={{ width: "100%" }} />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout2}
                    label={<FormattedMessage id="UserMessage.message" defaultMessage="Message" />}
                    name="message"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    initialValue=""
                  >
                    <ReactQuill theme="snow" value={this.state.message || ""} modules={modules} formats={formats} placeholder="Message" />
                  </Form.Item>
                }{" "}
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="UserMessage.filePath" defaultMessage="FilePath" />}
                    name="filePath"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="fileList"
                    getValueFromEvent={this.normalizingFileUpload}
                  >
                    <Upload {...propsUpload}>
                      <AntButton icon={<UploadOutlined />}>Click to upload</AntButton>
                    </Upload>
                  </Form.Item>
                }{" "}
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                bordered
                size="small"
                rowKey={record => record.id}
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>
          </TabPane>
        </Tabs>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.modalCloseShow}
          onHide={() => {
            this.setState({ id: null, modalCloseShow: false });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be closed !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ id: null, modalCloseShow: false });
              }}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={this.closeMessage}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <AntModal
          title="Send exististing message"
          visible={this.state.modalSendShow}
          onCancel={() => {
            this.setState({
              modalSendShow: false
            });
          }}
          cancelButtonProps={{
            style: {
              display: "none"
            }
          }}
          onOk={() => {
            this.setState({
              modalSendShow: false
            });
          }}
          maskClosable={false}
          width={800}
        >
          <div className="ant-form ant-form-horizontal">
            <Form.Item {...layout} label="Subject">
              <label>{this.state.selectedMessage.subject}</label>
            </Form.Item>
            <Form.Item {...layout} label="To">
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="User"
                mode="multiple"
                onChange={values => {
                  this.setState({
                    sendMultipleTo: values
                  });
                }}
              >
                {Boolean(this.state["messageToUserIdSelectItems"]) &&
                  this.state["messageToUserIdSelectItems"].map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.fullNameWithEmail}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="button" style={{ width: "100%" }} variant="success" onClick={this.sendMultipleTo}>
                  <FormattedMessage id="GeneralButtonSend" defaultMessage="Send" />
                </Button>
              </Col>
            </Row>
          </div>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserMessages);
