import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Space, Drawer, Cascader, InputNumber, Input, Card, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Add, FilterList } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

const layoutLeft = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

const dataListPurchaseCriteria = [];
const generateListPurchaseCriteria = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListPurchaseCriteria.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListPurchaseCriteria(node.children);
    }
  }
};

export class EldenEquipmentLicence extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentLicence",
      controllerName: "eldenEquipmentLogVrc",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      type: null,
      categoryId: -1,
      descriptionId: -1,
      materialTypeId: -1,
      purchasingcriteriaSelectItems: [],
      costRatios: [],
      selectedRowKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();
  formRefItem = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getSelectedLicence/" + this.props.eldenEquipmentTreeId);
    if (response.type === "ERROR") {
    } else {
      let selectedRowKeys = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          if (Boolean(element.eldenEquipmentLogVrcId)) {
            selectedRowKeys.push(element.eldenEquipmentLogVrcId);
          }
        });
      }
      this.setState({
        selectedRowKeys: selectedRowKeys
      });
    }
  };

  fillMaterialType = async () => {
    const { categoryId, descriptionId } = this.state;
    if (Boolean(categoryId) && categoryId > 0 && Boolean(descriptionId) && descriptionId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + categoryId + "/" + descriptionId);
      if (responseTree.data.length !== 0) {
        generateListMaterialType(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      }
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  getPurchaseCriteria = async value => {
    if (Boolean(value)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + value);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        generateListPurchaseCriteria(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems:
            Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async row => {
    document.getElementById("kt_scrolltop").click();

    this.fillService(row.disciplineId);
    this.fillComponent(row.compound);

    let itemIdFormListSelectItems = [];
    if (Boolean(row.costRatios) && row.costRatios.length > 0) {
      row.costRatios.forEach(element => {
        if (Boolean(element.itemId) && element.itemId > 0) {
          let isKeyExists = itemIdFormListSelectItems.some(item => item.key === element.itemId);
          if (!isKeyExists) {
            itemIdFormListSelectItems.push({ key: element.itemId, value: element.itemName });
          }
        }
      });
    }

    this.setState(
      {
        type: row.type,
        categoryId: row.categoryId,
        descriptionId: row.descriptionId,
        materialTypeId: row.materialTypeId,
        serviceId: row.serviceId,
        itemIdFormListSelectItems: itemIdFormListSelectItems
      },
      () => {
        this.getStockCardItem();
        this.fillMaterialType();
        this.fillCompounds();
      }
    );

    await this.onChangeItemGroupId(row.categoryId);

    var materialType = dataListMaterialType.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    var unit = dataListPurchaseCriteria.find(p => p.key === row.unitId);
    let unitId = [];
    if (Boolean(unit)) {
      unitId = unit.lineage;
    }

    var itemIds = [];
    if (Boolean(row.items) && row.items.length > 0) {
      row.items.forEach(element => {
        itemIds.push(element.stockCardId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      materialTypeId: materialTypeId,
      unitId: unitId,
      itemIds: itemIds,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectDocumentsModal = row => {
    this.setState({
      modalProjectDocumentsShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async () => {
    const { selectedRowKeys } = this.state;
    const { eldenEquipmentTreeId } = this.props;
    console.log("selectedRowKeys", selectedRowKeys);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSelectedLicence/" + eldenEquipmentTreeId, selectedRowKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
    }
    this.setState({
      selectedRowKeys: []
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/description/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  onChangeItemGroupIdforNameCode = async () => {
    const { type, itemGroupId, materialTypeId, descriptionId } = this.state;
    let obj = {
      type: Boolean(type) ? type : null,
      descriptionId: Boolean(descriptionId) ? descriptionId : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null
    };

    var responseCodeSelectItems = await handleRequest("POST", "/api/" + this.state.controllerName + "/nameCode", obj);
    if (responseCodeSelectItems.type === "ERROR") {
      error(responseCodeSelectItems);
    } else {
      this.setState({
        itemIdFormListSelectItems: Boolean(responseCodeSelectItems.data) ? responseCodeSelectItems.data : []
      });
    }
  };

  fillService = async value => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/service/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          serviceIdSelectItems: []
        });
      } else {
        this.setState({
          serviceIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        serviceIdSelectItems: []
      });
    }
  };

  fillCompounds = async () => {
    const { serviceId } = this.state;
    if (Boolean(serviceId)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/compound/" + serviceId);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          compoundIdSelectItems: []
        });
      } else {
        this.setState({
          compoundIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        compoundIdSelectItems: []
      });
    }
  };

  fillComponent = async value => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/component/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          componentSelectItems: []
        });
      } else {
        this.setState({
          componentSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        componentSelectItems: []
      });
    }
  };

  getStockCardItem = async () => {
    const { type, categoryId, descriptionId, materialTypeId } = this.state;
    var newObj = {
      type: type,
      categoryId: categoryId,
      descriptionSelectId: descriptionId,
      materialTypeId: materialTypeId
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getStockCardItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdFormListSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdFormListSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdFormListSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        itemIdFormListSelectItems: [],
        loadingSearch: false
      });
    }
  };

  saveItem = async values => {
    console.log("Values", values);
    this.setState(
      {
        showItemReachSearchModal: false
      },
      () => {
        const { currentFieldKey } = this.state;
        console.log("currentFieldKey", currentFieldKey);

        let costRatios = this.formRef.current.getFieldValue("costRatios");
        let product = costRatios[currentFieldKey];
        product.itemId = values.code;

        this.formRef.current.setFieldsValue({
          costRatios: costRatios
        });

        this.setState({
          showProductTreeCompanyModal: false
        });
      }
    );
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentLicence.Title" defaultMessage="Title" />,
        key: "title",
        render: record => {
          return record.title;
        },
        ...getColumnFilter({
          inputName: "title",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLicence.Code" defaultMessage="Code" />,
        key: "code",
        render: record => {
          return record.code;
        },
        ...getColumnFilter({
          inputName: "code",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLicence.AnswerType" defaultMessage="Answer Type" />,
        key: "answerType",
        render: record => {
          return record.answerType;
        },
        ...getColumnFilter({
          inputName: "answerType",
          inputType: "SELECT",
          selectItems: Boolean(this.state["answerTypeSelectItems"]) ? this.state["answerTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLicence.Options" defaultMessage="Options" />,
        key: "optionNames",
        render: record => {
          return record.optionNames;
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentLicence.Unit" defaultMessage="Unit" />,
        key: "unitName",
        render: record => {
          return record.unitName;
        }
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenEquipmentLicence.pageTitle" defaultMessage="Licence" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EldenEquipmentCerticates.certificates" defaultMessage="Certificates" />}
                        name="certificates"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.TypeOfCertificate" defaultMessage="Type Of Certificate" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="certificateId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    certificateId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["certificateIdSelectItems"]) &&
                                this.state["certificateIdSelectItems"].length > 0 &&
                                this.state["certificateIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewCertificateModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.RelatedStandard" defaultMessage="Related Standard" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="standardId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    standardId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["standardIdSelectItems"]) &&
                                this.state["standardIdSelectItems"].length > 0 &&
                                this.state["standardIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewStandardModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.Requlation" defaultMessage="Requlation" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="requlationId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    dId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["requlationIdSelectItems"]) &&
                                this.state["requlationIdSelectItems"].length > 0 &&
                                this.state["requlationIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewRequlationModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.Authority" defaultMessage="Authority" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="authorityId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    dId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["authorityIdSelectItems"]) &&
                                this.state["authorityIdSelectItems"].length > 0 &&
                                this.state["authorityIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewAuthorityModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.AuthorizedBody" defaultMessage="Authorized Body" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="authorizedBodyId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    dId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["authorizedBodyIdSelectItems"]) &&
                                this.state["authorizedBodyIdSelectItems"].length > 0 &&
                                this.state["authorizedBodyIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewAuthorizedBodyModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="EldenEquipmentCerticates.CertificationType" defaultMessage="Certification Type" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="certificationTypeId"
                            rules={[
                              { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              onChange={value => {
                                this.setState(
                                  {
                                    dId: value
                                  },
                                  () => {
                                    this.fillBrand();
                                  }
                                );
                              }}
                            >
                              {Boolean(this.state["certificationTypeIdSelectItems"]) &&
                                this.state["certificationTypeIdSelectItems"].length > 0 &&
                                this.state["certificationTypeIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewCertificationTypeModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
            </div>

            <br></br>

            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={1}> </Col>
                <Col span={22}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <div hidden={this.state.hideSave}>
                      {
                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                      }
                    </div>
                    <br />

                    <Form.List name="costRatios">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            <TableBoot bordered size="sm">
                              <thead>
                                <tr>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"EldenEquipmentLicence.Isolation"} defaultMessage={"Isolation"} />
                                  </th>
                                  <th colSpan="2" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"EldenEquipmentLicence.Item"} defaultMessage={"Item"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"EldenEquipmentLicence.Laborship"} defaultMessage={"Laborship"} />
                                  </th>
                                  <th colSpan="3" style={{ textAlign: "center" }}>
                                    <FormattedMessage id="EldenEquipmentLicence.LaborshipCostRatio" defaultMessage="Laborship Cost Ratio" />
                                  </th>
                                  <th colSpan="1"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map(field => {
                                  return (
                                    <tr key={field.key}>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "isolation"]}>
                                          <Input />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "itemId"]}>
                                          <Select
                                            allowClear
                                            showSearch
                                            onSearch={this.searchItem}
                                            style={{ marginBottom: 0, width: "100%" }}
                                            loading={this.state.loadingSearch}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            onChange={value => {
                                              this.getPurchaseCriteria(value);
                                            }}
                                          >
                                            {Boolean(this.state["itemIdFormListSelectItems"]) &&
                                              this.state["itemIdFormListSelectItems"].length > 0 &&
                                              this.state["itemIdFormListSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <AntButton
                                          type="default"
                                          style={{
                                            color: "black",
                                            background: "white",
                                            width: "10%"
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              showItemReachSearchModal: true,
                                              currentFieldKey: field.name
                                            });
                                          }}
                                          icon={<FilterList />}
                                        ></AntButton>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "laborshipId"]}>
                                          <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                            {Boolean(this.state["laborshipIdSelectItems"]) &&
                                              this.state["laborshipIdSelectItems"].length > 0 &&
                                              this.state["laborshipIdSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "costRatio"]}>
                                          <InputNumber />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "currency"]}>
                                          <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                            {Boolean(this.state["currencySelectItems"]) &&
                                              this.state["currencySelectItems"].length > 0 &&
                                              this.state["currencySelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "unitId"]}>
                                          <Cascader
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            style={{ width: "100%" }}
                                            options={this.state.purchasingcriteriaSelectItems}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan={11}>
                                    <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                      <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                                    </AntButton>
                                  </td>
                                </tr>
                              </tbody>
                            </TableBoot>
                          </>
                        );
                      }}
                    </Form.List>
                  </Card>
                </Col>
              </Row>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Space>
                <Button
                  variant="primary"
                  onClick={this.save}
                  style={{ marginTop: "10px" }}
                  // disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                  loading={this.state.loading}
                >
                  <FormattedMessage id="EldenEquipmentLicence.Save" defaultMessage="Save Selected" />
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
                </span>
              </Space>
            </Col>
          </Row>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowSelection={rowSelection}
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EldenEquipmentLicence.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenEquipmentLicence"} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentLicence.Isolation" defaultMessage="Isolation" />}
            centered
            width={1200}
            maskClosable={false}
            open={this.state.modalIsolationShow}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalIsolationShow: false });
            }}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table bordered size="small" dataSource={this.state.costRatios} loading={this.state.loading} pagination={false} />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentLicence.Item" defaultMessage="Item" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showItemReachSearchModal}
            onOk={() => this.formRefItem.current.submit()}
            onCancel={() => {
              this.setState({ showItemReachSearchModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveItem} onFinishFailed={onFinishFailed} ref={this.formRefItem}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />}
                      name="type"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Radio.Group
                        onChange={e => {
                          this.setState({
                            type: e.target.value
                          });
                        }}
                      >
                        <Radio value={"EQUIPMENT"}>{<FormattedMessage id="ProjectTakeOff.Equipment" defaultMessage="Equipment" />}</Radio>
                        <Radio value={"MATERIAL"}>{<FormattedMessage id="ProjectTakeOff.Material" defaultMessage="Material" />}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentLicence.itemGroupId" defaultMessage="Item Group" />}
                      name="itemGroupId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onChange={value => {
                          this.onChangeItemGroupId(value);
                          this.setState({ itemGroupId: value }, () => {
                            this.onChangeItemGroupIdforNameCode();
                            this.fillMaterialType();
                          });
                        }}
                      >
                        {Boolean(this.state["itemGroupIdSelectItems"]) &&
                          this.state["itemGroupIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentLicence.ItemDescriptionId" defaultMessage="Item Description" />}
                      name="itemDescriptionId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        showSearch
                        onChange={value => {
                          this.setState({ descriptionId: value }, () => {
                            this.onChangeItemGroupIdforNameCode();
                            this.fillMaterialType();
                          });
                        }}
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                      >
                        {Boolean(this.state["descriptionIdSelectItems"]) &&
                          this.state["descriptionIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentLicence.materialTypeId" defaultMessage="Material Type" />}
                      name="materialTypeId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Cascader
                        onChange={value => {
                          this.setState({ materialTypeId: value }, () => {
                            this.onChangeItemGroupIdforNameCode();
                          });
                        }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        options={this.state.nodes}
                      />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentLicence.NameCode" defaultMessage="Name/Code" />}
                      name="code"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                        {Boolean(this.state["itemIdFormListSelectItems"]) &&
                          this.state["itemIdFormListSelectItems"].length > 0 &&
                          this.state["itemIdFormListSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentLicence);
