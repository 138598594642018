import axios from "axios";
import React, { Component } from 'react';
import { API_BASE_ROOT, handleRequest } from "../ApiConnector";
import ColumnFilterComponent from "./ColumnFilterComponent";
import { FilterOutlined } from "@ant-design/icons";

let filterSearchoperations = {
  TEXT: null,
  MAIL: null,
  PHONE: null,
  PASSWORD: null,
  NUMBER: null,
  TEXTAREA: null,
  TEXT_EDITOR: null,
  SELECT: null,
  SELECT_MULTIPLE: null,
  SELECT_ADDABLE: null,
  SELECT_MULTIPLE_ADDABLE: null,
  CHECKBOX: null,
  RADIO: null,
  DATEPICKER: null,
  DATEPICKER_WITH_TIME: null,
  TIMEPICKER: null,
  MONTHPICKER: null,
  YEARPICKER: null,
  RANGEPICKER: null,
  RANGEPICKER_WITH_TIME: null,
  CASCADER: null,
  FILEUPLOAD: null,
  FILEUPLOAD_IMAGE: null,
  TREE_SELECT: null
};

export async function fillFilterOperations() {
  var response = await handleRequest("GET", "/ApplicationSettings/searchOperations");
  if (response.type === "ERROR") {
    this.error(response);
  } else {
    if (Boolean(response.data) && response.data.length > 0) {
      filterSearchoperations.TEXT = response.data.filter(x => x.key.startsWith("STR_"));
      filterSearchoperations.NUMBER = response.data.filter(x => x.key.startsWith("NUM_"));
      filterSearchoperations.DATEPICKER = response.data.filter(x => x.key.startsWith("DATE_"));
      filterSearchoperations.DATEPICKER_WITH_TIME = filterSearchoperations.DATEPICKER;
      filterSearchoperations.YEARPICKER = filterSearchoperations.NUMBER;
      filterSearchoperations.CHECKBOX = response.data.filter(x => x.key.startsWith("BOOL_"));
      filterSearchoperations.SELECT = filterSearchoperations.CHECKBOX;
      filterSearchoperations.SELECT_MULTIPLE = filterSearchoperations.CHECKBOX;
      filterSearchoperations.SELECT_ADDABLE = filterSearchoperations.CHECKBOX;
      filterSearchoperations.SELECT_MULTIPLE_ADDABLE = filterSearchoperations.CHECKBOX;
      filterSearchoperations.RADIO = filterSearchoperations.CHECKBOX;
      filterSearchoperations.CASCADER = filterSearchoperations.CHECKBOX;
      filterSearchoperations.MAIL = filterSearchoperations.TEXT;
      filterSearchoperations.PHONE = filterSearchoperations.TEXT;
      filterSearchoperations.PASSWORD = filterSearchoperations.TEXT;
      filterSearchoperations.TEXTAREA = filterSearchoperations.TEXT;
      filterSearchoperations.TEXT_EDITOR = filterSearchoperations.TEXT;
    }
  }
};

export function getColumnFilter(column) {
  let ColumnFilters = {
    TEXT: true,
    PHONE: true,
    EMAIL: true,
    PASSWORD: true,
    NUMBER: true,
    TEXTAREA: true,
    TEXT_EDITOR: true,
    RADIO: true,
    CHECKBOX: true,
    SELECT: true,
    SELECT_ADDABLE: true,
    SELECT_MULTIPLE: true,
    SELECT_MULTIPLE_ADDABLE: true,
    DATEPICKER: true,
    DATEPICKER_WITH_TIME: true,
    YEARPICKER: true,
    CASCADER: true,
  }

  let c = Boolean(ColumnFilters[column.inputType])
    ? {
      filterDropdown: () => (
        <ColumnFilterComponent
          filterChangeItem={column.filterChangeItem}
          input={column}
          searchCriteriaModels={column.searchCriteriaModels}
          random={Math.random()}
          selectItems={column.selectItems}
          filterOperations={filterSearchoperations[column.inputType]}
        />
      ),
      filterIcon: filtered => (
        <FilterOutlined style={{ color: Boolean(column.searchCriteriaModels.find(p => p.key === column.inputName)) ? "#0099ff" : "#bfbfbf" }} />
      ),
      sorter: true
    }
    : null;
  return c;
};