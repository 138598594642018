import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Card } from "antd";
import { API_BASE_ROOT } from "../../../ApiConnector";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import { Input } from "antd";
import { getColumnFilter } from "../../../Components/DataTableFilterComponent";
import moment from "moment";
import DraggableModal from "../../../Components/DraggableModal";

export class ProposedProjectLogScoring extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProposedProjectLogScoring",
      controllerName: "proposedProjectLogScoring",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      typeRevise: false,
      fileList: [],

      activeTabKey: "Prelimanry",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefCriteria = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.setState({
      activeTabKey: "Prelimanry",
    }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
    });
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      type: this.state.activeTabKey,
      proposedProjectLogId: this.props.proposedProjectLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    let fileUrl = [];
    let filePathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileUrl.push(obj);
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileUrl,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
      fileList: fileUrl
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.edit.bind(this, true)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(reviseAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  criteriaModal = row => {
    this.setState({
      modalCriteriaShow: true,
      id: row.id
    }, () => {
      this.formRefCriteria.current.resetFields();
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      type: this.state.activeTabKey,
      proposedProjectLogId: this.props.proposedProjectLogId,
    };

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({ modalCriteriaShow: false });
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  onTab2Change = key => {
    this.setState({ activeTabKey: key }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const columnsPreliminaryScoring = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.PreliminaryCriteria" defaultMessage="Criteria" />,
        key: "criteria",
        render: record => {
          return record.criteria;
        },
        ...getColumnFilter({
          inputName: "criteria",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.PreliminaryScore" defaultMessage="Score" />,
        key: "preliminaryScore",
        render: record => {
          return record.preliminaryScore;
        },
        ...getColumnFilter({
          inputName: "preliminaryScore",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.PreliminaryWeight" defaultMessage="Weight" />,
        key: "preliminaryWeight",
        render: record => {
          return record.preliminaryWeight;
        },
        ...getColumnFilter({
          inputName: "preliminaryWeight",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

    ];
    const columnsDesignScoring = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.DesignCriteria" defaultMessage="Criteria" />,
        key: "criteria",
        render: record => {
          return record.criteria;
        },
        ...getColumnFilter({
          inputName: "criteria",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.DesignScore" defaultMessage="Score" />,
        key: "designScore",
        render: record => {
          return record.designScore;
        },
        ...getColumnFilter({
          inputName: "designScore",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.DesignWeight" defaultMessage="Weight" />,
        key: "designWeight",
        render: record => {
          return record.designWeight;
        },
        ...getColumnFilter({
          inputName: "designWeight",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

    ];
    const columnsExecutionScoring = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.ExecutionCriteria" defaultMessage="Criteria" />,
        key: "criteria",
        render: record => {
          return record.criteria;
        },
        ...getColumnFilter({
          inputName: "criteria",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.ExecutionScore" defaultMessage="Score" />,
        key: "executionScore",
        render: record => {
          return record.executionScore;
        },
        ...getColumnFilter({
          inputName: "executionScore",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogScoring.ExecutionWeight" defaultMessage="Weight" />,
        key: "executionWeight",
        render: record => {
          return record.executionWeight;
        },
        ...getColumnFilter({
          inputName: "executionWeight",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}      
         {this.state.exportClick ? (<ExcelFile filename="ProposedProjectLogScoring" hideElement={true}>    
                  <ExcelSheet name="ProposedProjectLogScoring" data={this.state.tableList}>            
                     <ExcelColumn label="Code" value="code" />
      <ExcelColumn label="Description" value="description" />
      <ExcelColumn label="Rev no" value="revNo" />
      <ExcelColumn label="Issue Date" value="issueDate" />
      <ExcelColumn label="Distribution" value="distributionName" />
      <ExcelColumn label="Remarks" value="remarks" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}     */}
      </>
    );
    const { activeTabKey } = this.state;
    const tabList = [
      {
        key: "Prelimanry",
        label: "Prelimanry",
        tab: "Preliminary Scoring"
      },
      {
        key: "Design",
        label: "Design",
        tab: "Design Scoring"
      },
      {
        key: "Execution",
        label: "Execution",
        tab: "Execution Scoring"
      }
    ];
    const layoutTab = {
      labelCol: { span: 9 },
      wrapperCol: { span: 9 }
    };
    const contentList = {
      Prelimanry: (
        <Form
          initialValues={{
            remember: false,

          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                tooltip={"Criteria"}
                style={{ border: "0px" }}
                onClick={this.criteriaModal}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <Table
            size="small"
            bordered
            rowClassName={(record, index) => (record.completed ? "text-danger" : "")}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsPreliminaryScoring}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </Form>
      ),

      Design: (
        <Form
          initialValues={{
            remember: false,

          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                tooltip={"Criteria"}
                style={{ border: "0px" }}
                onClick={this.criteriaModal}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <Table
            size="small"
            bordered
            rowClassName={(record, index) => (record.completed ? "text-danger" : "")}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsDesignScoring}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </Form>
      ),

      Execution: (
        <Form
          initialValues={{
            remember: false,

          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                tooltip={"Criteria"}
                style={{ border: "0px" }}
                onClick={this.criteriaModal}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <Table
            size="small"
            bordered
            rowClassName={(record, index) => (record.completed ? "text-danger" : "")}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsExecutionScoring}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </Form>
      )
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Card
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: "100%"
            }}
            activeTabKey={this.state.activeTabKey}

            tabList={tabList}
            onTabChange={this.onTab2Change}
          >
            {contentList[activeTabKey]}
          </Card>
        </Form>


        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="ProposedProjectLogScoring.Criteria" defaultMessage="Criteria" />}
          centered
          width={900}
          open={this.state.modalCriteriaShow}
          onOk={() => this.formRefCriteria.current.submit()}
          onCancel={() => this.setState({ modalCriteriaShow: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.save}
              onFinishFailed={onFinishFailed}
              ref={this.formRefCriteria}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLogScoring.Criteria" defaultMessage="Criteria" />}
                  name="criteria"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          }
        />


      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProposedProjectLogScoring);