import objectPath from "object-path";
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { handleRequest } from "../../../../app/ApiConnector";

export function Brand() {
  const [logo, setLogo] = useState(null);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/api/companies/getLogo";
        const response = await handleRequest("GET", url);
        if (Boolean(response.data)) {
          setLogo(response.data);
        }
        else {
          setLogo(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* begin::Brand */}
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          {Boolean(logo) ?
            <img alt="logo" src={logo} height="50" />
            :
            <img alt="logo" src={layoutProps.headerLogo} height="50" />
          }
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}