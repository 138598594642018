import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, InputNumber } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { DatePicker } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import {
  AreaChartOutlined,
  UpCircleOutlined,
  SettingOutlined,
  DeleteOutlined,
  RiseOutlined,
  FileDoneOutlined,
  WarningOutlined
} from "@ant-design/icons";
import ProposedProjectLogScoring from "./ProposedProjectLogScoring";
import ProposedProjectLogUpgrading from "./ProposedProjectLogUpgrading";
import DraggableModal from "../../../Components/DraggableModal";
import TenderExecutionLog from "../tenderPreparation/TenderExecutionLog";

const { Option } = Select;

export class ProposedProjectLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProposedProjectLog",
      controllerName: "proposedProjectLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }
    this.setState({
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : []
    });

    // var responseprojectType = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectType");
    // if (responseprojectType.type === "ERROR") {
    //   error(responseprojectType);
    // }
    // this.setState({
    //   projectTypeSelectItems: Boolean(responseprojectType.data) ? responseprojectType.data : []
    // });
    // var responsefacilityType = await handleRequest("GET", "/api/" + this.state.controllerName + "/facilityType");
    // if (responsefacilityType.type === "ERROR") {
    //   error(responsefacilityType);
    // }
    // this.setState({
    //   facilityTypeSelectItems: Boolean(responsefacilityType.data) ? responsefacilityType.data : []
    // });
    // var responsescope = await handleRequest("GET", "/api/" + this.state.controllerName + "/scope");
    // if (responsescope.type === "ERROR") {
    //   error(responsescope);
    // }
    // this.setState({
    //   scopeSelectItems: Boolean(responsescope.data) ? responsescope.data : []
    // });
    // var responselocationAddress = await handleRequest("GET", "/api/" + this.state.controllerName + "/locationAddress");
    // if (responselocationAddress.type === "ERROR") {
    //   error(responselocationAddress);
    // }
    // this.setState({
    //   locationAddressSelectItems: Boolean(responselocationAddress.data) ? responselocationAddress.data : []
    // });
    // var responseestimatedBudget = await handleRequest("GET", "/api/" + this.state.controllerName + "/estimatedBudget");
    // if (responseestimatedBudget.type === "ERROR") {
    //   error(responseestimatedBudget);
    // }
    // this.setState({
    //   estimatedBudgetSelectItems: Boolean(responseestimatedBudget.data) ? responseestimatedBudget.data : []
    // });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      date: row.date ? moment(row.date) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  scoringModal = row => {
    this.setState({
      modalScoringShow: true,
      id: row.id,
      random: Math.random()
    });
  };

  criteriasModal = row => {
    this.setState({
      modalCriteriasShow: true,
      id: row.id
    });
  };

  upgradingModal = row => {
    this.setState({
      modalUpgradingShow: true,
      id: row.id,
      random: Math.random()
    });
  };

  tenderingModal = row => {
    this.setState({
      modalTenderPreparationShow: true,
      id: row.id,
      random: Math.random()
    });
  };

  declineModal = row => {
    this.setState({
      modalDeclineShow: true,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideDeclineModal = () => {
    this.setState({
      modalDeclineShow: !this.state.modalDeclineShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.edit.bind(this)
    };
    let scoringAction = {
      name: "Scoring Settings",
      icon: <SettingOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.scoringModal.bind(this)
    };
    let criteriasAction = {
      name: "Criterias",
      icon: <FileDoneOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.criteriasModal.bind(this)
    };
    let upgradingAction = {
      name: "Upgrading",
      icon: <RiseOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.upgradingModal.bind(this)
    };
    let tenderingAction = {
      name: "Tendering",
      icon: <FileDoneOutlined style={{ fontSize: "small", color: "#1890ff" }} />,
      actionClick: this.tenderingModal.bind(this)
    };
    let declineAction = {
      name: "Decline",
      icon: <WarningOutlined style={{ fontSize: "small", color: "#ff4d4f" }} />,
      actionClick: this.declineModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteOutlined style={{ fontSize: "small", color: "#ff4d4f" }} />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(scoringAction);
    actionList.push(criteriasAction);
    actionList.push(upgradingAction);
    actionList.push(tenderingAction);
    actionList.push(declineAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProposalNo" defaultMessage="Proposal No" />,
        key: "proposalNo",
        render: record => {
          return record.id;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date ? moment(record.date).format("DD-MM-YYYY HH:mm") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectName" defaultMessage="Project Name" />,
        key: "projectName",
        render: record => {
          return record.projectName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectType" defaultMessage="Project Type" />,
        key: "projectType",
        render: record => {
          return record.projectType;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.FacilityType" defaultMessage="Facility Type" />,
        key: "facilityType",
        render: record => {
          return record.facilityType;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Scope" defaultMessage="Scope" />,
        key: "scope",
        render: record => {
          return record.scope;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.LocationAddress" defaultMessage="Location, Address" />,
        key: "locationAddress",
        render: record => {
          return record.locationAddress;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.EstimatedBudget" defaultMessage="Estimated Budget" />,
        key: "estimatedBudget",
        render: record => {
          return record.estimatedBudget;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.DurationOfProject" defaultMessage="Duration of Project" />,
        key: "duration",
        render: record => {
          return record.duration;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProposedBy" defaultMessage="Proposed By" />,
        key: "proposedBy",
        render: record => {
          return record.proposedBy;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.PreliminaryScoring" defaultMessage="Preliminary Scoring" />,
        key: "preliminaryScoring",
        render: record => {
          return record.preliminaryScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.DesignScoring" defaultMessage="Design Scoring" />,
        key: "designScoring",
        render: record => {
          return record.designScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectExecutionScoring" defaultMessage="Project Execution Scoring" />,
        key: "projectExecutionScoring",
        render: record => {
          return record.projectExecutionScoring;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.Tendering" defaultMessage="Tendering" />,
        key: "tendering",
        render: record => {
          return record.tendering;
        },
        sorter: false
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="ProposedProjectLog.pageTitle" defaultMessage="Proposed Project's Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.upgradingModal}
                  icon={<UpCircleOutlined color="secondary" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.scoringModal}
                  icon={<AreaChartOutlined color="secondary" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLog.projectName" defaultMessage="Project Name" />}
                  name="projectName"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProposedProjectLog.projectType" defaultMessage="Project Type" />}
                name="projectType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["projectTypeSelectItems"]) &&
                    this.state["projectTypeSelectItems"].length > 0 &&
                    this.state["projectTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProposedProjectLog.facilityType" defaultMessage="Facility Type" />}
                name="facilityType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["facilityTypeSelectItems"]) &&
                    this.state["facilityTypeSelectItems"].length > 0 &&
                    this.state["facilityTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProposedProjectLog.scope" defaultMessage="Scope" />}
                name="scope"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["scopeSelectItems"]) &&
                    this.state["scopeSelectItems"].length > 0 &&
                    this.state["scopeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProposedProjectLog.locationAddress" defaultMessage="Location Address" />}
                name="locationAddress"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["locationAddressSelectItems"]) &&
                    this.state["locationAddressSelectItems"].length > 0 &&
                    this.state["locationAddressSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item {...layout} label={<FormattedMessage id="ProposedProjectLog.estimatedBudget" defaultMessage="Estimated Budget" />}>
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item name="estimatedBudget">
                      <InputNumber style={{ width: "100%" }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label={<FormattedMessage id="CashIn.Currency" defaultMessage="Currency" />} name="currency">
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["currencySelectItems"]) &&
                          this.state["currencySelectItems"].length > 0 &&
                          this.state["currencySelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProposedProjectLog.estimatedDurationDuration" defaultMessage="Estimated Duration" />}
                name="duration"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
              </Form.Item>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLog.proposedBy" defaultMessage="Proposed By" />}
                  name="proposedBy"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="ProposedProjectLog.Criterias" style={{ width: "100%" }} variant="secondary" onClick={this.criteriasModal}>
                      <FormattedMessage id="ProposedProjectLog.Criterias" defaultMessage="Criterias" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
              <br />
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLog.date" defaultMessage="Date" />}
                  name="date"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} showTime={{ format: "HH:mm" }} format="DD-MM-YYYY HH:mm" />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalDeclineShow} onHide={this.showOrHideDeclineModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="DeclineMessage" defaultMessage="This project will be declined" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeclineModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.decline}>
                <FormattedMessage id="GeneralButtonDecline" defaultMessage="Decline" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="ProposedProjectLog.Scoring" defaultMessage="Scoring" />}
            width={1500}
            centered
            okButtonProps={{ hidden: true }}
            open={this.state.modalScoringShow}
            onCancel={() => {
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ modalScoringShow: false });
            }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<ProposedProjectLogScoring random={this.state.random} proposedProjectLogId={this.state.id}></ProposedProjectLogScoring>}
          />

          <DraggableModal
            title={<FormattedMessage id="ProposedProjectLog.Upgrading" defaultMessage="Upgrading" />}
            width={1500}
            centered
            okButtonProps={{ hidden: true }}
            open={this.state.modalUpgradingShow}
            onCancel={() => {
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ modalUpgradingShow: false });
            }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<ProposedProjectLogUpgrading random={this.state.random} proposedProjectLogId={this.state.id}></ProposedProjectLogUpgrading>}
          />

          <DraggableModal
            title={<FormattedMessage id="ProposedProjectLog.TenderPreparation" defaultMessage="Tender Preparation" />}
            width={1500}
            centered
            okButtonProps={{ hidden: true }}
            open={this.state.modalTenderPreparationShow}
            onCancel={() => {
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ modalTenderPreparationShow: false });
            }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<TenderExecutionLog random={this.state.random} proposedProjectLogId={this.state.id} />}
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProposedProjectLog);
