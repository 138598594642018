import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Form, Row, Col, Button as AntButton, Input, Select, Space, Radio, DatePicker, Upload } from "antd";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BookCreatorReference extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BookCreatorReference",
      controllerName: "bookCreatorReference",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      selectedItem: { "itemName": "" },
      pageAutoDesign: null,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefAddNew = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/items/" + this.props.autoReportId);
    if (responseItem.type === "ERROR") {
      error(responseItem);
    }
    var responseInputType = await handleRequest("GET", "/api/" + this.state.controllerName + "/inputType");
    if (responseInputType.type === "ERROR") {
      error(responseInputType);
    }
    var responseUser = await handleRequest("GET", "/api/" + this.state.controllerName + "/users");
    if (responseUser.type === "ERROR") {
      error(responseUser);
    }
    this.setState({
      itemSelectItems: Boolean(responseItem.data) ? responseItem.data : [],
      selectedItem: Boolean(responseItem.data) ? responseItem.data[0] : { "itemName": "" },
      inputTypeSelectItems: Boolean(responseInputType.data) ? responseInputType.data : [],
      usersSelectItems: Boolean(responseUser.data) ? responseUser.data : [],
    }, () => {
      this.fillFormats();
    });
  };

  fillFormats = async () => {
    const { selectedItem } = this.state;
    if (Boolean(selectedItem) && Boolean(selectedItem.id) && selectedItem.id > 0) {
      var responseItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/formats/" + this.state.selectedItem.id);
      if (responseItem.type === "ERROR") {
        error(responseItem);
      }
      if (Boolean(responseItem.data)) {
        responseItem.data.forEach(element => {
          element.preparationDate = Boolean(element.preparationDate) ? moment(element.preparationDate) : null;
          element.reviewDate = Boolean(element.reviewDate) ? moment(element.reviewDate) : null;
          element.approvalDate = Boolean(element.approvalDate) ? moment(element.approvalDate) : null;

          if (element.inputType === "Image") {
            let uploadedFiles = [];
            let photoPath = Boolean(element.itemValue) ? element.itemValue.split(",") : [];
            if (Boolean(photoPath) && photoPath.length > 0) {
              photoPath.forEach((item, index) => {
                let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
                uploadedFiles.push(obj);
              });
            }
            element.itemValue = uploadedFiles;
          }

        });
      }
      this.formRef.current.setFieldsValue({
        formats: Boolean(responseItem.data) ? responseItem.data : null,
      });
    } else {
      this.formRef.current.setFieldsValue({
        formats: null,
      });
    }
  }

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  saveAddNew = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      autoReportId: this.props.autoReportId,      
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      showSuccess();
      this.setState({
        modalAddNewShow: false,
      });
    }
  }

  save = async values => {
    const { selectedItem } = this.state;
    if (!Boolean(selectedItem.id)) {
      showWarning("Auto Report Item not found.");
      return;
    }
    console.log("values", values);

    if (Boolean(values.formats) && values.formats.length > 0) {
      values.formats.forEach(element => {
        if (element.inputType === "Image") {
          let pathList = [];
          if (Boolean(element.itemValue))
            element.itemValue.forEach(item => {
              if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
              else if (Boolean(item.url)) pathList.push(item.url);
            });
          element.itemValue = pathList.toString();
        }
      });
    }

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveFormats/" + selectedItem.id, values.formats);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const options = [
      { label: 'Option 1', value: 'Type1', img: '/media/autoReport/Type1.png' },
      { label: 'Option 2', value: 'Type2', img: '/media/autoReport/Type2.png' },
      { label: 'Option 3', value: 'Type3', img: '/media/autoReport/Type3.png' },
      { label: 'Option 4', value: 'Type4', img: '/media/autoReport/Type4.png' },
    ];

    const handleChange = e => {
      console.log(e.target.value);
      if (Boolean(e.target.value)) {
        this.setState({
          modalBookCreatorReferenceShow: true,
          random: Math.random(),
          type: e.target.value
        });
      }
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const handleInputTypeChange = (fieldName, value) => {
      this.formRef.current.setFieldsValue({
        formats: this.formRef.current.getFieldValue('formats').map((item, index) =>
          index === fieldName ? { ...item, inputType: value } : item
        )
      });
    };

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const { selectedInputName, selectedInputIndex, itemSelectItems, selectedItem } = this.state;

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <br />
                    <Row gutter={[16, 16]}>
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={() => this.setState({ modalAddNewShow: true, })}>
                          <PlusOutlined /> Add New
                        </a>
                      </div>

                      {/*  */}

                      {Boolean(itemSelectItems) && itemSelectItems.length > 0 &&
                        itemSelectItems.map((item) => (
                          <Button style={{ width: "100%" }} variant={this.state.selectedItem.itemName === item.itemName ? "warning" : "outline-warning"} onClick={() => {
                            this.setState({
                              selectedItem: item,
                            }, () => {
                              this.fillFormats();
                            });
                          }}>
                            {item.itemNo + "-" + item.itemName}
                          </Button>
                        ))}





                      <br />
                    </Row>
                  </div>
                </div>
              </div>
            </div>


            {Boolean(selectedItem) && (
              <div className="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch">
                  <div className="card card-custom card-stretch">
                    <div className="card-header py-3">
                      <div className="card-title align-items-start flex-column">{selectedItem.itemNo + "-" + selectedItem.itemName}</div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="flex-row-fluid ml-lg-12">
                    <div className="card-title align-items-start flex-column">
                      <Form.List name="formats">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                               <TableBoot bordered responsive="sm" className="TableBoot">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.ReferenceName"} defaultMessage={"Reference Name"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.ReferanceType"} defaultMessage={"Reference Type"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.Author"} defaultMessage={"Author"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.EditionNo"} defaultMessage={"Edition No"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.YearOfPublish"} defaultMessage={"Year Of Publish"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.WordFormat"} defaultMessage={"Word Format"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.PdfFormat"} defaultMessage={"Pdf Format"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BookCreatorReference.Link"} defaultMessage={"Link"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        
                                        <td>
                                          <Form.Item noStyle name={[field.name, "referenceName"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "referenceTypeId"]}>
                                            <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                              {Boolean(this.state["usersSelectItems"]) &&
                                                this.state["usersSelectItems"].length > 0 &&
                                                this.state["usersSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        
                                        <td>
                                          <Form.Item noStyle name={[field.name, "author"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "editionNo"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "yearOfPublish"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "wordFormat"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "pdfFormat"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "link"]}>
                                            <Input></Input>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage id={"BookCreatorReference.AddNew"} defaultMessage={"Add New"} />
                                      </AntButton>
                                    </td>
                                  </tr>
                                  <br /> <br />
                                  <tr>
                                    <td colSpan={11}>
                                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form >

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BookCreatorReference.AddNew" defaultMessage="Add New" />}
          open={this.state.modalAddNewShow}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ modalAddNewShow: false })}
          onOk={() => this.formRefAddNew.current.submit()}
          width={600}
          centered
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveAddNew} ref={this.formRefAddNew}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreatorReference.ItemNo" defaultMessage="Item No" />}
                name="itemNo"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="BookCreatorReference.Item" defaultMessage="Item" />}
                name="itemName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              <br></br>
              <br></br>
              <br></br>

              <Form.Item name="type">
                <Radio.Group onChange={handleChange}>
                  {options && options.length > 0 &&
                    options.map(i => (
                      <Radio key={i.value} value={i.value}>
                        <div style={{ textAlign: 'center' }}>
                          <img
                            src={toAbsoluteUrl(i.img)}
                            alt={i.label}
                            style={{ width: '200px', height: '200px' }}
                          />
                        </div>
                      </Radio>
                    ))}
                </Radio.Group>

              </Form.Item>
            </Form>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BookCreatorReference);