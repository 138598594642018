import React from "react";
import CustomerPaymentTypes from "../CustomerPaymentTypes";
import SalesmanDiscountRates from "../SalesmanDiscountRates";
import CustomerDiscountAndProfitRates from "../CustomerDiscountAndProfitRates";
import ProductDiscountAndProfitsRates from "../ProductDiscountAndProfitsRates";
import SalesmanCreditStatus from "../SalesmanCreditStatus";
import CustomerCreditStatus from "../CustomerCreditStatus";
import SalesRates from "../SalesRates";
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { ExportOutlined } from "@ant-design/icons";
import PaymentType from "../PaymentType";
import SalesmanSalesType from "../SalesmanSalesType";

//IMPORT

const SalesComponent = () => {
  return (
    <>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.SalesRates" defaultMessage="Sales Rates" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.props.getModalParameters("Sales Rates", <SalesRates />)} />
              </Col>
            </Row>
            <SalesRates />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.SalesmanSalesType" defaultMessage="Salesman Sales Type" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Salesman Sales Type", <SalesmanSalesType />)}
                />
              </Col>
            </Row>
            <SalesmanSalesType />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.CustomerPaymentTypes" defaultMessage=" Customer Payment Types" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Customer Payment Types", <CustomerPaymentTypes />)}
                />
              </Col>
            </Row>
            <CustomerPaymentTypes />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.SalesmanDiscountRates" defaultMessage="Salesman Discount Rates" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Salesman Discount Rates", <SalesmanDiscountRates />)}
                />
              </Col>
            </Row>
            <SalesmanDiscountRates />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.PaymentType" defaultMessage="Payment Type" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.props.getModalParameters("Payment Type", <PaymentType />)} />
              </Col>
            </Row>
            <PaymentType />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.CustomerDiscountAndProfitRates" defaultMessage="Customer Discount And Profit Rates" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Customer Discount And Profit Rates", <CustomerDiscountAndProfitRates />)}
                />
              </Col>
            </Row>
            <CustomerDiscountAndProfitRates />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.ProductDiscountAndProfitsRates" defaultMessage="Product Discount And Profit Rates" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Product Discount And Profit Rates", <ProductDiscountAndProfitsRates />)}
                />
              </Col>
            </Row>
            <ProductDiscountAndProfitsRates />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.SalesmanCreditStatus" defaultMessage="Salesman Credit Status" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Salesman Credit Status", <SalesmanCreditStatus />)}
                />
              </Col>
            </Row>
            <SalesmanCreditStatus />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body">
            <Row>
              <Col span={16}>
                <h5>
                  <FormattedMessage id="DashboardPage.CustomerCreditStatus" defaultMessage="Customer Credits Status" />
                </h5>
              </Col>
              <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                <ExportOutlined
                  style={{ fontSize: "150%" }}
                  onClick={() => this.props.getModalParameters("Customer Credits Status", <CustomerCreditStatus />)}
                />
              </Col>
            </Row>
            <CustomerCreditStatus />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesComponent;
