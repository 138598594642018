import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Badge, Col, Form, Row, Collapse, Spin, Radio, Checkbox, Upload, Switch, Space } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Button, Modal } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import { Col as ColBoot, Form as FormBoot } from "react-bootstrap";
import { RadioGroup } from "@material-ui/core";

const { Panel } = Collapse;

export class GapAnalysis extends Component {
  constructor() {
    super();
    this.state = {
      formName: "GapAnalysis",
      controllerName: "gapAnalysis",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      answerTypeSelectItems: [],
      id: null,
      tableList: [],
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      filePath: "",
      fileList: [],
      rows: [],
      loadingChecklistSingle: false,
      loadingChecklistMultiple: false,
      itemIndex: 0,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {
    this.getAnswerType();
    this.fillCode("GapAnalysisTitle");
    this.fillCode("GapAnalysisParentTitle");
    this.fillCode("GapAnalysisOptions");
    this.fillCode("GapAnalysisColumns");
  };

  getAnswerType = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
    if (Boolean(response.data)) {
      this.setState({
        answerTypeSelectItems: response.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: []
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();

    var options = [];
    if (Boolean(row) && Boolean(row.options) && row.options.length > 0) {
      row.options.forEach(element => {
        options.push(element.codeId);
      });
    }

    var columns = [];
    if (Boolean(row) && Boolean(row.columns) && row.columns.length > 0) {
      row.columns.forEach(element => {
        columns.push(element.codeId);
      });
    }

    let _fileList = [];
    if (Boolean(row.answerType) && row.answerType === "Imagepicker") {
      if (Boolean(row.imageOptions)) {
        row.imageOptions.forEach((element, index) => {
          if (Boolean(element.imageUrl)) {
            let obj = {
              url: element.imageUrl,
              uid: index,
              imagePath: element.imageUrl,
              id: index,
              name: element.imageUrl.substring(element.imageUrl.lastIndexOf("/") + 1)
            };
            _fileList.push(obj);
          }
        });
      }
    }

    this.formRef.current.setFieldsValue({
      ...row,
      options: options,
      columns: columns
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      answerType: row.answerType,
      fileList: _fileList
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany");
    console.log("XXX", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let itemIndex = 0;
        response.data.forEach(item => {
          if (Boolean(item.children) && item.children.length > 0) {
            item.children.forEach(child => {
              if (Boolean(child.children) && child.children.length > 0) {
                child.children.forEach(childChild => {
                  childChild.itemIndex = itemIndex;
                  itemIndex++;
                });
              }
            });
          }
        });
        this.setState({
          loading: false,
          tableList: response.data
        });
      } else {
        this.setState({
          loading: false,
          tableList: []
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    var options = [];
    if (Boolean(values.options) && values.options.length > 0) {
      values.options.forEach(element => {
        options.push({
          ["codeId"]: element
        });
      });
    }

    var columns = [];
    if (Boolean(values.columns) && values.columns.length > 0) {
      values.columns.forEach(element => {
        columns.push({
          ["codeId"]: element
        });
      });
    }

    var _imageOptions = [];
    if (Boolean(this.state.fileList))
      this.state.fileList.forEach(element => {
        let url = null;
        if (Boolean(element.url)) {
          url = element.url;
        } else if (Boolean(element.response) && Boolean(element.response.url)) {
          url = element.response.url;
        }
        if (Boolean(url)) {
          _imageOptions.push({
            ["imageUrl"]: url
          });
        }
      });

    let newItem = {
      ...values,
      id: this.state.id,
      options: options,
      columns: columns,
      imageOptions: _imageOptions
    };
    console.log("xxll", newItem);

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  renderElement = (values, parenTitle) => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Collapse activeKey={this.state.activePanelKeyTitle} onChange={this.setOpenPanelsTitle} accordion>
          <Panel header={item.title} key={parenTitle + index}>
            {Boolean(item.children) && item.children.length > 0 && (
              <>
                {Boolean(item.children) &&
                  item.children.length > 0 &&
                  item.children.map((item, index) => (
                    <>
                      <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <FormBoot.Label style={{ color: "black" }} column sm="9">
                            {item.question}
                          </FormBoot.Label>
                        </FormBoot.Group>
                        {Boolean(item.answerType) && item.answerType === "Comment" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <FormBoot.Control as="textarea" rows="3" />
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
                          <>
                            {Boolean(item.options) &&
                              item.options.length > 0 &&
                              item.options.map((opt, j) => (
                                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                  <ColBoot xs="11">
                                    <Radio checked={false}>{opt.codeName}</Radio>
                                  </ColBoot>
                                </FormBoot.Group>
                              ))}
                          </>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              {Boolean(item.imageOptions) &&
                                item.imageOptions.length > 0 &&
                                item.imageOptions.map((opt, j) => (
                                  <>
                                    <RadioGroup>
                                      <Radio checked={false}>
                                        <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                                      </Radio>
                                    </RadioGroup>
                                  </>
                                ))}
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Rating" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <Radio.Group buttonStyle="solid">
                                {this.getRatingList(item.rateMax).map((element, index) => (
                                  <Radio.Button value={element}>{element}</Radio.Button>
                                ))}
                              </Radio.Group>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Boolean" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="12">
                              <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Checkbox" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            {Boolean(item.options) &&
                              item.options.length > 0 &&
                              item.options.map((opt, j) => (
                                <>
                                  <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                                    <Checkbox> {opt.codeName}</Checkbox>
                                  </ColBoot>{" "}
                                  <br />
                                </>
                              ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Dropdown" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="4">
                              <FormBoot.Control name="type" as="select">
                                <option value="Select">Select Option</option>
                                {Boolean(item.options) &&
                                  item.options.length > 0 &&
                                  item.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                              </FormBoot.Control>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "File" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot xs="4">
                              <Upload>
                                <AntButton>
                                  <UploadOutlined /> Click to Upload
                                </AntButton>
                              </Upload>
                            </ColBoot>
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot> </ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>{col.codeName}</ColBoot>
                            ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot> </ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>{col.codeName}</ColBoot>
                            ))}
                          </FormBoot.Group>
                        )}

                        {Boolean(item.answerType) &&
                          item.answerType === "Matrix_Single" &&
                          Boolean(item.columns) &&
                          item.columns.length > 0 &&
                          Boolean(item.matrixValues) &&
                          item.matrixValues.length > 0 &&
                          item.matrixValues.map((row, k) => (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot>{row.title}</ColBoot>
                              {item.columns.map((col, j) => (
                                <ColBoot>
                                  <Radio checked={false}></Radio>
                                </ColBoot>
                              ))}
                            </FormBoot.Group>
                          ))}

                        {Boolean(item.answerType) &&
                          item.answerType === "Matrix_Multiple" &&
                          Boolean(item.matrixValues) &&
                          item.matrixValues &&
                          item.matrixValues.map((row, k) => (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot> {row.title} </ColBoot>
                              {item.columns.map((col, j) => (
                                <ColBoot>
                                  <FormBoot.Control name="type" as="select">
                                    <option value="Select">Select Option</option>
                                    {Boolean(item.options) &&
                                      item.options.length > 0 &&
                                      item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                                  </FormBoot.Control>
                                </ColBoot>
                              ))}
                            </FormBoot.Group>
                          ))}
                      </div>
                      <br />
                    </>
                  ))}
              </>
            )}
          </Panel>
        </Collapse>
      ))
    );
  };

  renderElementMultiple = (values, parenTitle) => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <>
          {Boolean(item.children) && item.children.length > 0 && (
            <>
              {Boolean(item.children) &&
                item.children.length > 0 &&
                item.children.map((item, index) => (
                  <>
                    <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
                      <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                        <FormBoot.Label style={{ color: "black" }} column sm="9">
                          {item.question}
                        </FormBoot.Label>
                      </FormBoot.Group>
                      {Boolean(item.answerType) && item.answerType === "Comment" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="12">
                            <FormBoot.Control as="textarea" rows="3" />
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
                        <>
                          {Boolean(item.options) &&
                            item.options.length > 0 &&
                            item.options.map((opt, j) => (
                              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <ColBoot xs="11">
                                  <Radio checked={false}>{opt.codeName}</Radio>
                                </ColBoot>
                              </FormBoot.Group>
                            ))}
                        </>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="12">
                            {Boolean(item.imageOptions) &&
                              item.imageOptions.length > 0 &&
                              item.imageOptions.map((opt, j) => (
                                <>
                                  <RadioGroup>
                                    <Radio checked={false}>
                                      <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                                    </Radio>
                                  </RadioGroup>
                                </>
                              ))}
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Rating" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="12">
                            <Radio.Group buttonStyle="solid">
                              {this.getRatingList(item.rateMax).map((element, index) => (
                                <Radio.Button value={element}>{element}</Radio.Button>
                              ))}
                            </Radio.Group>
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Boolean" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="12">
                            <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Checkbox" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          {Boolean(item.options) &&
                            item.options.length > 0 &&
                            item.options.map((opt, j) => (
                              <>
                                <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                                  <Checkbox> {opt.codeName}</Checkbox>
                                </ColBoot>
                                <br />
                              </>
                            ))}
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Dropdown" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="4">
                            <FormBoot.Control name="type" as="select">
                              <option value="Select">Select Option</option>
                              {Boolean(item.options) &&
                                item.options.length > 0 &&
                                item.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                            </FormBoot.Control>
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "File" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot xs="4">
                            <Upload>
                              <AntButton>
                                <UploadOutlined /> Click to Upload
                              </AntButton>
                            </Upload>
                          </ColBoot>
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot> </ColBoot>
                          {item.columns.map((col, j) => (
                            <ColBoot>{col.codeName}</ColBoot>
                          ))}
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
                        <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <ColBoot> </ColBoot>
                          {item.columns.map((col, j) => (
                            <ColBoot>{col.codeName}</ColBoot>
                          ))}
                        </FormBoot.Group>
                      )}

                      {Boolean(item.answerType) &&
                        item.answerType === "Matrix_Single" &&
                        Boolean(item.columns) &&
                        item.columns.length > 0 &&
                        Boolean(item.matrixValues) &&
                        item.matrixValues.length > 0 &&
                        item.matrixValues.map((row, k) => (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot>{row.title}</ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>
                                <Radio checked={false}></Radio>
                              </ColBoot>
                            ))}
                          </FormBoot.Group>
                        ))}

                      {Boolean(item.answerType) &&
                        item.answerType === "Matrix_Multiple" &&
                        Boolean(item.matrixValues) &&
                        item.matrixValues &&
                        item.matrixValues.map((row, k) => (
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <ColBoot> {row.title} </ColBoot>
                            {item.columns.map((col, j) => (
                              <ColBoot>
                                <FormBoot.Control name="type" as="select">
                                  <option value="Select">Select Option</option>
                                  {Boolean(item.options) &&
                                    item.options.length > 0 &&
                                    item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                                </FormBoot.Control>
                              </ColBoot>
                            ))}
                          </FormBoot.Group>
                        ))}
                    </div>
                    <br />
                  </>
                ))}
            </>
          )}
        </>
      ))
    );
  };

  renderElementSingle = (values, parenTitle) => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map(item => (
        <>
          {Boolean(item.children) && item.children.length > 0 && (
            <>
              {Boolean(item.children) &&
                item.children.length > 0 &&
                item.children.map(
                  (child, index) =>
                    child.itemIndex === this.state.itemIndex && (
                      <>
                        <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
                          <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                            <FormBoot.Label style={{ color: "black" }} column sm="9">
                              {child.question}
                            </FormBoot.Label>
                          </FormBoot.Group>
                          {Boolean(child.answerType) && child.answerType === "Comment" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="12">
                                <FormBoot.Control as="textarea" rows="3" />
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Radiogroup" && (
                            <>
                              {Boolean(child.options) &&
                                child.options.length > 0 &&
                                child.options.map((opt, j) => (
                                  <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                    <ColBoot xs="11">
                                      <Radio checked={false}>{opt.codeName}</Radio>
                                    </ColBoot>
                                  </FormBoot.Group>
                                ))}
                            </>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Imagepicker" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="12">
                                {Boolean(child.imageOptions) &&
                                  child.imageOptions.length > 0 &&
                                  child.imageOptions.map((opt, j) => (
                                    <>
                                      <RadioGroup>
                                        <Radio checked={false}>
                                          <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                                        </Radio>
                                      </RadioGroup>
                                    </>
                                  ))}
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Rating" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="12">
                                <Radio.Group buttonStyle="solid">
                                  {this.getRatingList(child.rateMax).map((element, index) => (
                                    <Radio.Button value={element}>{element}</Radio.Button>
                                  ))}
                                </Radio.Group>
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Boolean" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="12">
                                <Switch checkedChildren={child.labelTrue} unCheckedChildren={child.labelFalse} defaultChecked />
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Checkbox" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              {Boolean(child.options) &&
                                child.options.length > 0 &&
                                child.options.map((opt, j) => (
                                  <>
                                    <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                                      <Checkbox> {opt.codeName}</Checkbox>
                                    </ColBoot>
                                    <br />
                                  </>
                                ))}
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Dropdown" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="4">
                                <FormBoot.Control name="type" as="select">
                                  <option value="Select">Select Option</option>
                                  {Boolean(child.options) &&
                                    child.options.length > 0 &&
                                    child.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                                </FormBoot.Control>
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "File" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot xs="4">
                                <Upload>
                                  <AntButton>
                                    <UploadOutlined /> Click to Upload
                                  </AntButton>
                                </Upload>
                              </ColBoot>
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Matrix_Single" && Boolean(child.columns) && child.columns.length > 0 && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot> </ColBoot>
                              {child.columns.map((col, j) => (
                                <ColBoot>{col.codeName}</ColBoot>
                              ))}
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) && child.answerType === "Matrix_Multiple" && (
                            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                              <ColBoot> </ColBoot>
                              {child.columns.map((col, j) => (
                                <ColBoot>{col.codeName}</ColBoot>
                              ))}
                            </FormBoot.Group>
                          )}

                          {Boolean(child.answerType) &&
                            child.answerType === "Matrix_Single" &&
                            Boolean(child.columns) &&
                            child.columns.length > 0 &&
                            Boolean(child.matrixValues) &&
                            child.matrixValues.length > 0 &&
                            child.matrixValues.map((row, k) => (
                              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <ColBoot>{row.title}</ColBoot>
                                {child.columns.map((col, j) => (
                                  <ColBoot>
                                    <Radio checked={false}></Radio>
                                  </ColBoot>
                                ))}
                              </FormBoot.Group>
                            ))}

                          {Boolean(child.answerType) &&
                            child.answerType === "Matrix_Multiple" &&
                            Boolean(child.matrixValues) &&
                            child.matrixValues &&
                            child.matrixValues.map((row, k) => (
                              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <ColBoot> {row.title} </ColBoot>
                                {child.columns.map((col, j) => (
                                  <ColBoot>
                                    <FormBoot.Control name="type" as="select">
                                      <option value="Select">Select Option</option>
                                      {Boolean(child.options) &&
                                        child.options.length > 0 &&
                                        child.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                                    </FormBoot.Control>
                                  </ColBoot>
                                ))}
                              </FormBoot.Group>
                            ))}
                        </div>
                        <br />
                      </>
                    )
                )}
            </>
          )}
        </>
      ))
    );
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  setOpenPanelsTitle = key => {
    this.setState({
      activePanelKeyTitle: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  singleOpenCheckListModel = async row => {};

  multipleChecklistModel = async row => {};

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="GapAnalysis.pageTitle" defaultMessage="Gap Analysis" />}
        style={{ fontSize: 12 }}
        placement="start"
        color="volcano"
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <Row gutter={[16, 16]}>
            <Col md={20}></Col>
            <Col md={4}>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                onClick={() => {
                  this.setState({
                    modalOnebyoneShow: true
                  });
                }}
              >
                <FormattedMessage id="GapAnalysis.ApplyQuestionary" defaultMessage="Apply Questionary" />
              </Button>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}></Form>
          </div>
          <br />
          {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
            <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
              {this.state.tableList.map((item, index) => (
                <Panel header={item.parentTitle} key={index}>
                  {this.renderElement(item.children, item.parentTitle)}
                </Panel>
              ))}
            </Collapse>
          ) : (
            ""
          )}

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={
              <FormattedMessage
                id="GapAnalysis.SelectEncodingMethodOfChecklist"
                defaultMessage="Please select encoding method of checklist items ?"
              />
            }
            centered
            open={this.state.modalOnebyoneShow}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalOnebyoneShow: false });
            }}
            content={
              <>
                <br />
                <Row gutter={[16, 16]}>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <Spin spinning={this.state.loadingChecklistSingle}>
                      <Button
                        id="OneByOneButton"
                        onClick={() => {
                          this.setState({
                            modalOnebyoneShow: false,
                            modalSingleShow: true,
                            itemIndex: 0
                          });
                        }}
                        style={{ width: "100%" }}
                        variant="dark"
                      >
                        <FormattedMessage id="GeneralButtonOneByOne" defaultMessage="One by one" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col md={6}>
                    <Spin spinning={this.state.loadingChecklistMultiple}>
                      <Button
                        id="MultipleButton"
                        onClick={() => {
                          this.setState({
                            modalOnebyoneShow: false,
                            modalMultipleShow: true
                          });
                        }}
                        style={{ width: "100%" }}
                        variant="dark"
                      >
                        <FormattedMessage id="GeneralButtonMultiple" defaultMessage="Multiple" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GapAnalysis.Multiple" defaultMessage="Multiple" />}
            centered
            width={1200}
            open={this.state.modalMultipleShow}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalMultipleShow: false });
            }}
            content={
              Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
                <>
                  {this.state.tableList.map((item, index) => (
                    <>{this.renderElementMultiple(item.children, item.parentTitle)}</>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GapAnalysis.Single" defaultMessage="Single" />}
            centered
            width={1200}
            open={this.state.modalSingleShow}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ modalSingleShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const itemIndex = this.state.itemIndex;
                    if (itemIndex > 0) {
                      this.setState({
                        itemIndex: itemIndex - 1
                      });
                    }
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    const { itemIndex } = this.state;
                    this.setState({
                      itemIndex: itemIndex + 1
                    });
                  }}
                >
                  Next
                </Button>
              </Space>
            ]}
            content={
              Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
                <>
                  {this.state.tableList.map((item, index) => (
                    <>{this.renderElementSingle(item.children, item.parentTitle)}</>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(GapAnalysis);
