import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import store from "../../../../redux/store";
import { Content } from "./Content";

export function ContentRouteUserOnly({ children, component, render, ...props }) {
  const state = store.getState();
  const user = state.auth.user;
  const [authorized, setAuthorized] = useState(null);
  useEffect(() => {
    if (!Boolean(authorized)) {
      if (Boolean(user)) {
        setAuthorized(true);
      }
    }
  }, [authorized, user]);

  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (authorized != null && authorized) {
          return <Content>{React.createElement(component, routeProps)}</Content>;
        } else if (authorized != null && !authorized) {
          return <Redirect to="authorization-error" />;
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
