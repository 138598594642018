import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import PrepareFormItemProps from "./ElementUtils";

const TreeSelectComponent = ({ field, layout }) => {
  const [list, setList] = useState(null);
  const { TreeSelect } = Select;

  const fillList = async () => {
    let url = field.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else setList(Boolean(response.data) ? response.data : []);
  };
  const onLoadData = async ({ id }) => {
    let url = field.loadUrl; //"/api/countries/selectItems";
    var response = await handleRequest("GET", url + "/" + id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      list.concat(response.data);
      setList(list);
    }
    //TODO: Örnek data objesine dikkat edin.
    // {
    //   id: random,
    //   pId: parentId,
    //   value: random,
    //   title: ""
    //   isLeaf,//altında bje var mı yok mu yok ise true
    // };
  };
  useEffect(() => {
    fillList();
  }, []);
  const onChange = value => {
    console.log(value);
  };

  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <TreeSelect
          treeDataSimpleMode
          style={{ width: "100%" }}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select"
          onChange={onChange}
          loadData={onLoadData}
          treeData={list}
        />
      </Form.Item>
    </>
  );
};

export default TreeSelectComponent;
