import { Add, EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import {
    Avatar,
    Button as AntButton,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Switch,
    Upload,
    Badge,
    Drawer,
    Tooltip,
    Card, Space
} from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { UploadOutlined } from "@ant-design/icons";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

const generateItemList = data => {
    let itemList = [];
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        itemList.push({
            key: node.value,
            lineage: node.lineage,
            title: node.value
        });
        if (node.children && node.children.length > 0) {
            itemList.push(...generateItemList(node.children));
        }
    }
    return itemList;
};
export class FuelRefill extends Component {
    constructor() {
        super();
        this.state = {
            formName: "FuelRefill",
            controllerName: "fuelRefill",
            user: store.getState().auth.user,
            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],
            id: null,
            tableList: [],
            restartTable: false,
            pageFormData: null,
            excelData: [],
            createWhereParams: null,
            refreshForm: false,
            fileList: [],


            plateNo: "",
            date: null,
            modalShow: false,
            modalShowAdd: false,
            project: "",
            company: "Test Company",
            trailer: "",
            trailerId: null,
            driver: "",
            driverId: null,
            lateDecleration: false,
            amountOfFuel: null,
            amountOfFuelShow: null,
            unitPrice: null,
            unitPriceShow: null,
            price: null,
            priceShow: null,
            kmOfTruck: null,
            minKmOfTruck: 0,
            sourceOfFuel: "Select Source of Fuel",
            sourceOfFuelId: null,
            sourceOfFuels: [],
            newSourceFuelName: "",
            invoicePhotoPath: "",
            meterPhotoPath: "",
            datePickerDisable: true,
            VAT: true,
            fuelRefills: [],
            fuelTankCapacity: null,
            fuelType: null,

            equipmentId: null,
            vendorId: null,
            equipmentLogSelectItems: [],
            equipmentLogInfoSelectItems: [],
            fuelTypeSelectItems: [],
            vendorSelectItems: [],

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
            },
            sortField: "",
            sortOrder: "",
        };
    }

    formRef = React.createRef();
    formRefNewSupplier = React.createRef();

    componentDidMount = async () => {
        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            date: today
        });

        let data = await GetDynamicFormItems(this.state.formName);
        this.setState({
            pageFormData: data
        });

        // this.fillComboboxes(data.pageFormInputCompanyList);
        this.fillEquipmentLogs();
        this.fillVendors();
    };
    componentDidUpdate = async nextProps => { };
    fillComboboxes = async inputList => {
        inputList.forEach(async (item, index) => {
            if (item.inputType === "CASCADER") {
                let url = item.loadUrl;
                var response = await handleRequest("GET", url);
                if (response.type === "ERROR") {
                    showError(response);
                } else {
                    let list = generateItemList(response.data);
                    this.setState({
                        [item.inputName + "Cascader"]: list
                    });
                }
            }
        });
    };

    fillPlate = async (eqLogId) => {
        if (Boolean(eqLogId) && eqLogId > 0) {
            var responseplateNo = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPlate/" + eqLogId);
            if (responseplateNo.type === "ERROR") {
                error(responseplateNo);
            }
            this.setState({
                plateNo: Boolean(responseplateNo.data) && Boolean(responseplateNo.data.plateNo) ? responseplateNo.data.plateNo : "",
                fuelTypeSelectItems: Boolean(responseplateNo.data) && Boolean(responseplateNo.data.powerSupplyType) ? [responseplateNo.data.powerSupplyType] : []
            });
        }
        else (this.setState({ plate: "", fuelTypeSelectItems: [] }))
    }


    fillEquipmentLogs = async () => {
        const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getEquipmentLogs");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                equipmentLogSelectItems: Boolean(response.data)
                    ? response.data
                    : [],
            });
        }
    };

    fillVendors = async () => {
        const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getVendor");
        console.log(response.data);
        if (Boolean(response.data)) {
            this.setState({
                vendorSelectItems: response.data
            });
        }
    };

    handleEquipmentDescriptionChange = async (value) => {
        this.fillPlate(value);
        if (Boolean(value) && value > 0) {
            const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getEquipmentInfo/" + value);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    equipmentId: value,
                    equipmentLogInfoSelectItems: Boolean(response.data)
                        ? response.data
                        : [],
                });
            }

            const responseMinKmOfTruck = await handleRequest("GET", "/api/" + this.state.controllerName + "/minKmOfTruck/" + value);
            if (responseMinKmOfTruck.type === "ERROR") {
                error(responseMinKmOfTruck);
            } else {
                let minKmOfTruck = Boolean(responseMinKmOfTruck.data) ? responseMinKmOfTruck.data : 0;
                this.setState({
                    minKmOfTruck: minKmOfTruck,
                });
                this.formRef.current.setFieldsValue({
                    kmOfTruck: minKmOfTruck
                });
            }
        } else {
            this.setState({
                equipmentId: null,
                equipmentLogInfoSelectItems: [],
                minKmOfTruck: 0,
            });
            this.formRef.current.setFieldsValue({
                kmOfTruck: 0,
            });
        }
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,
            lateDecleration: false,
            amountOfFuel: null,
            amountOfFuelShow: null,
            unitPrice: null,
            unitPriceShow: null,
            price: null,
            priceShow: null,
            kmOfTruck: null,
            sourceOfFuel: "Select Source of Fuel",
            sourceOfFuelId: null,
            sourceOfFuels: [],
            newSourceFuelName: "",
            invoicePhotoPath: "",
            meterPhotoPath: "",
            datePickerDisable: true,
            VAT: true,
            fuelRefills: [],
            fuelTankCapacity: null,
            fuelType: null,
        });
    };

    setData = data => {
        this.setState({
            tableList: data
        });
    };

    edit = row => {
        let handledValues = {
            ...row,
            time: moment(row.time),
            equipmentId: row.equipmentLogId,
            lateDecleration: row.lateDecleration,
            priceShow: row.price,
            unitPriceShow: row.unitPrice,
            amountOfFuelShow: row.amountOfFuel,
        };
        if (Boolean(row.equipmentLogId)) {
            this.handleEquipmentDescriptionChange(row.equipmentLogId)
        }
        handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
        this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
            if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
                let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
                if (itemId) {
                    handledValues[item.inputName] = itemId.lineage;
                }
            }
        });
        this.formRef.current.setFieldsValue({
            ...handledValues
        });
        console.log(handledValues);

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id
        });
        this.calcVatRateValue();
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.restartTable();
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    restartTable = async () => {
        this.setState({ restartTable: !this.state.restartTable });
    };

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        let newItem = {
            id: this.state.id,
            equipmentLogId: this.state.equipmentId,
            vendorId: this.state.vendorId,
            price: this.state.price,
            unitPrice: this.state.unitPrice,
            amountOfFuel: this.state.amountOfFuel,
            invoicePhotoPath: this.state.invoicePhotoPath,
            meterPhotoPath: this.state.meterPhotoPath,
            fuelType: this.state.fuelType,
            ...values
        };
        console.log(newItem);
        newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
        if (!Boolean(newItem.id)) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            showError(response);
        } else {
            this.cancel();
            this.restartTable();
            showSuccess();
        }
    };

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        //name: element["Name"],
                        //shortName: element["Short Name"],
                        // [IMPORT_OBJECT]
                    };
                    this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
                        Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
                    });
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        this.restartTable();
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    action = record => {
        return (
            <React.Fragment>
                <DynamicActionMenu
                    row={record}
                    actionList={[
                        {
                            name: "Edit",
                            icon: <EditOutlined fontSize="small" color="primary" />,
                            actionClick: this.edit.bind(this)
                        },
                        {
                            name: "Delete",
                            icon: <DeleteIcon fontSize="small" color="error" />,
                            actionClick: this.deleteModal.bind(this)
                        }
                    ]}
                />
            </React.Fragment>
        );
    };

    disabledDate(current) {
        return current > moment().endOf('day');
    }

    onChangeDate = (value, dateString) => {
        this.setState({
            date: dateString
        });
    }

    handleChange = e => {
        e.preventDefault();
        try {
            var flagFloat = parseFloat(e.target.value);
            this.setState({
                [e.target.name]: flagFloat
            });
        } catch (error) {
            this.setState({
                [e.target.name]: null
            });
        }
    }

    handleChangeSourceFuel = e => {
        e.preventDefault();
        var sourceOfFuelIdVaule;

        for (var z = 0; z < this.state.sourceOfFuels.length; z++) {
            if (this.state.sourceOfFuels[z].name === e.target.value) {
                sourceOfFuelIdVaule = this.state.sourceOfFuels[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            sourceOfFuelId: sourceOfFuelIdVaule
        });
    }

    onChangeAmountOfFuel = (value) => {
        console.log("onChangeAmountOfFuel: " + value);
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            amountOfFuel: value,
            amountOfFuelShow: flagValue
        });

        setTimeout(() => {
            this.calculatePriceAOF();
        }, 1000);
    }

    onChangeUnitPrice = (value) => {
        console.log("onChangeUnitPrice: " + value);
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            unitPrice: value,
            unitPriceShow: flagValue
        });
        setTimeout(() => {
            this.calculatePriceUnit();
        }, 1000);
    }

    onChangePrice = (value) => {
        console.log("onChangePrice: " + value);
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            price: value,
            priceShow: flagValue
        });
        setTimeout(() => {
            this.calculatePricePrice();
        }, 1000);
    }

    onChangekmOfTruck = (value) => {
        this.setState({
            kmOfTruck: value
        });
    }

    calculatePriceAOF = () => {
        if (this.state.amountOfFuel !== null && this.state.unitPrice !== null) {
            var flagPrice = this.state.amountOfFuel * this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                price: flagPrice,
                priceShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.price !== null) {
            var flagUnitPrice = this.state.price / this.state.amountOfFuel;
            let flagValue = "";
            try {
                flagValue = flagUnitPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagUnitPrice,
                unitPriceShow: flagValue
            });
        }
    }



    calculatePriceUnit = () => {
        if (this.state.unitPrice !== null && this.state.price !== null) {
            var flagAmountOfFuel = this.state.price / this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagAmountOfFuel.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                amountOfFuel: flagAmountOfFuel,
                amountOfFuelShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.unitPrice !== null) {
            var flagPrice = this.state.amountOfFuel * this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                price: flagPrice,
                priceShow: flagValue
            });
        }
    }

    calculatePricePrice = () => {
        console.log(this.state.price);
        if (this.state.unitPrice !== null && this.state.price !== null) {
            var flagAmountOfFuel = this.state.price / this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagAmountOfFuel.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                amountOfFuel: flagAmountOfFuel,
                amountOfFuelShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.price !== null) {
            var flagUnitPrice = this.state.price / this.state.amountOfFuel;
            let flagValue = "";
            try {
                flagValue = flagUnitPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagUnitPrice,
                unitPriceShow: flagValue
            });
        }
    }

    handleFuelTypeChange = e => {
        this.setState({
            fuelType: e
        });
    }


    handleFormNewSupplierSubmit = async values => {
        const newItem = {
            ...values,
            supplier: true,
        };
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                showNewSupplierModal: false
            });
            showSuccess();
            this.fillVendors();
        }
    };


    calcVatRateValue() {
        const price = Boolean(this.state.price) ? this.state.price : this.state.priceShow;
        const vatRate = this.formRef.current.getFieldValue("vatRate");
        console.log("price", price);
        console.log("vatRate", vatRate);

        let vatRateValue = 0;
        if (!isNaN(price) && !isNaN(vatRate)) {
            const numericPrice = parseFloat(price);
            const numericVatRate = parseFloat(vatRate);

            console.log("numericPrice", numericPrice);
            console.log("numericVatRate", numericVatRate);

            if (numericPrice > 0 && numericVatRate >= 0) {
                vatRateValue = numericPrice - ((100 * numericPrice) / (100 + numericVatRate));
            }
        }
        console.log("vatRateValue", vatRateValue);
        this.formRef.current.setFieldsValue({
            vatRateValue: vatRateValue.toFixed(2),
        });
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 },
            style: {
                marginBottom: 0
            }
        };

        const layout2 = {
            labelCol: { span: 12 },
            wrapperCol: { span: 12 },
            style: {
                marginBottom: 0
            }
        };

        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename={this.state.formName} hideElement={true}>
                        <ExcelSheet name="Products" data={this.state.tableList}>
                            <ExcelColumn label="S/N" value="index" />
                            {Boolean(this.state.pageFormData) &&
                                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                                    return (
                                        <ExcelColumn
                                            key={item.inputName}
                                            label={item.label}
                                            value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                                        />
                                    );
                                })}
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
                )} */}
            </>
        );

        const listUploadProps = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: "picture"
        };

        const normFile = e => {
            console.log("Upload event:", e);

            if (Array.isArray(e)) {
                return e;
            }

            return e && e.fileList;
        };

        return (
            <Badge.Ribbon
                text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Fuel Refill" />}
                placement="start"
                color="purple"
                style={{ fontSize: 12 }}
            >
                <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                    <Row gutter={[16, 16]}>
                        <Col md={20}>
                        </Col>
                        <Col md={1}>{excelExport}</Col>
                        <Col md={1}>
                            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                        </Col>
                        <Col md={1}>
                            <AntButton
                                style={{ border: "0px" }}
                                hidden={!this.state.hideInputs}
                                onClick={this.onClickCreateNew}
                                icon={<CreateIcon color="secondary" fontSize="large" />}
                            ></AntButton>
                            <AntButton
                                style={{ border: "0px" }}
                                hidden={this.state.hideInputs}
                                onClick={this.onClickCreateNew}
                                icon={<RemoveCircle color="error" fontSize="large" />}
                            ></AntButton>
                        </Col>
                        <Col md={1}>
                            {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                                <Tooltip title={<FormattedMessage id="FuelRefill.Settings" defaultMessage="Settings" />}>
                                    <AntButton
                                        style={{ border: "0px" }}
                                        onClick={() => {
                                            this.setState({
                                                drawerSettingsShow: true
                                            });
                                        }}
                                        icon={<SettingsIcon color="error" fontSize="large" />}
                                    ></AntButton>
                                </Tooltip>
                            )}
                        </Col>
                    </Row>







                    <div hidden={this.state.hideInputs}>
                        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                            <Row gutter={16} style={{ marginBottom: "16px" }}>

                                <br></br>
                                <br></br>
                                <br></br>
                                {/* Sol Card */}
                                <Col span={1}> </Col>
                                <Col span={9}>
                                    <Card
                                        style={{

                                            borderRadius: "6px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            width: "100%",
                                            height: "100%"
                                        }}
                                    >
                                        {
                                            <Form.Item
                                                {...layout}
                                                label={<FormattedMessage id="clinic.equipmentId"
                                                    defaultMessage="Equipment" />}
                                                name="equipmentId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                            defaultMessage="This field is required." />
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={this.handleEquipmentDescriptionChange}
                                                    style={{ width: "200px" }}
                                                    placeholder="Equipment"
                                                >
                                                    {Boolean(this.state["equipmentLogSelectItems"]) &&
                                                        this.state["equipmentLogSelectItems"].map(i => (
                                                            <Option key={i.key} value={i.key}>
                                                                {i.value}
                                                            </Option>
                                                        ))}{" "}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </Card>
                                </Col>

                                {/* Sağ Card */}
                                <Col span={13}>
                                    <Card
                                        style={{

                                            borderRadius: "8px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            width: "100%",
                                            height: "100%"
                                        }}
                                    >
                                        {Boolean(this.state.equipmentLogInfoSelectItems) && this.state.equipmentLogInfoSelectItems.map(i => (
                                            <><Row key={i.key} gutter={[16, 16]}>
                                                <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                                                    <Form.Item
                                                        {...layout2}
                                                        label={<FormattedMessage id="fuelRefill.VehicleDescription"
                                                            defaultMessage="Vehicle Description" />}
                                                    >
                                                        {i.description}
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...layout2}
                                                        label={<FormattedMessage id="fuelRefill.SerialNo"
                                                            defaultMessage="Serial No" />}
                                                    >
                                                        {i.serialNo}
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...layout2}
                                                        label={<FormattedMessage id="fuelRefill.PlateNo"
                                                            defaultMessage="Plate No" />}
                                                    >

                                                        {this.state.plateNo}

                                                    </Form.Item>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                    <Form.Item
                                                        {...layout2}
                                                    >
                                                        <Avatar shape="square" size={128} src={i.vehiclePhoto} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            </>
                                        ))

                                        }

                                    </Card>
                                </Col>
                                <Col span={1}></Col>
                            </Row>

                            {
                                <Form.Item
                                    {...layout} label={<FormattedMessage id="fuelRefill.time"
                                        defaultMessage="Date" />}
                                    name="time"
                                >
                                    <Row gutter={8}>
                                        <Col span={10}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                            defaultMessage="This field is required." />
                                                    }
                                                ]}
                                            >
                                                <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'DD/MM/YYYY HH:mm')} disabled={this.state.datePickerDisable} showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDate} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name="lateDecleration"
                                                label={<FormattedMessage id="fuelRefill.LateDeclaration"
                                                    defaultMessage="Late Declaration" />}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                                            defaultMessage="This field is required." />
                                                    }
                                                ]}
                                            >
                                                <Checkbox inputId="cb1" checked={this.state.lateDecleration}
                                                    onChange={(e) => {
                                                        var today = new Date();
                                                        var HH = String(today.getHours()).padStart(2, '0');
                                                        var minute = String(today.getMinutes()).padStart(2, '0');
                                                        var dd = String(today.getDate()).padStart(2, '0');
                                                        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                        var yyyy = today.getFullYear();

                                                        var flagToday = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
                                                        this.setState({ lateDecleration: e.checked, datePickerDisable: !e.checked, date: flagToday });
                                                    }}>
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            }

                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.fuelType"
                                        defaultMessage="Fuel Type" />}
                                    name="fuelType"
                                    rules={[
                                        {
                                            required: false,
                                            message: <FormattedMessage id="GENERAL.REQUIRED"
                                                defaultMessage="This field is required." />
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={this.handleFuelTypeChange}
                                        style={{ width: "100%" }}
                                        placeholder="Fuel Type"
                                    >
                                        {Boolean(this.state["fuelTypeSelectItems"]) &&
                                            this.state["fuelTypeSelectItems"].length > 0 &&
                                            this.state["fuelTypeSelectItems"].map(i => (
                                                <Option key={i} value={i}>
                                                    {i}
                                                </Option>
                                            ))}{" "}
                                    </Select>
                                </Form.Item>
                            }

                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="FuelRefill.FuelSupplier" defaultMessage="Fuel Supplier" />}
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <Space.Compact style={{ width: "100%" }}>
                                    <Form.Item
                                        name="vendorId"
                                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                        style={{ width: "90%" }}
                                    >
                                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                                            {Boolean(this.state["vendorSelectItems"]) &&
                                                this.state["vendorSelectItems"].length > 0 &&
                                                this.state["vendorSelectItems"].map(i => (
                                                    <Option key={i.key} value={i.key}>
                                                        {i.value}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>

                                    <AntButton
                                        type="default"
                                        style={{
                                            color: "black",
                                            background: "white",
                                            width: "10%"
                                        }}
                                        onClick={() => {
                                            this.setState({ showNewSupplierModal: true });
                                        }}
                                        icon={<Add />}
                                    ></AntButton>
                                </Space.Compact>
                            </Form.Item>

                            {
                                <Form.Item
                                    {...layout} label={<FormattedMessage id="fuelRefill.price" defaultMessage="Price" />}
                                >
                                    <Row gutter={8}>
                                        <Col span={10}>
                                            <Form.Item
                                                name="price"
                                                rules={[{
                                                    required: false,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED"
                                                        defaultMessage="This field is required." />
                                                }]}>
                                                <InputNumber style={{ width: '85%' }} min={0} value={Boolean(this.state.price) ? this.state.price : this.state.priceShow}
                                                    onChange={(value) => {
                                                        this.calcVatRateValue();
                                                        this.onChangePrice(value);
                                                    }} />
                                                <span style={{ visibility: 'hidden' }}>.</span>
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name="vatInclude"
                                                rules={[{
                                                    required: false,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED"
                                                        defaultMessage="This field is required." />
                                                }]}>

                                                <Switch checkedChildren="VAT included" unCheckedChildren="VAT excluded" checked={this.state.VAT} defaultChecked onChange={(value) => {
                                                    this.setState({
                                                        VAT: value
                                                    })
                                                }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            }

                            {
                                <Form.Item
                                    {...layout} label={<FormattedMessage id="fuelRefill.Vat" defaultMessage="Vat" />}
                                >
                                    <Row gutter={8}>
                                        <Col span={6}>
                                            <Form.Item
                                                name="vatRate"
                                                rules={[{
                                                    required: true,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED"
                                                        defaultMessage="This field is required." />
                                                }]}>
                                                <InputNumber onChange={() => {
                                                    this.calcVatRateValue();
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="vatRateValue"
                                                rules={[{
                                                    required: false,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED"
                                                        defaultMessage="This field is required." />
                                                }]}>

                                                <Input readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.unitPrice"
                                        defaultMessage="Unit Price" />}
                                    name="unitPrice"
                                    rules={[{
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                            defaultMessage="This field is required." />
                                    }]}>

                                    <InputNumber style={{ width: '85%' }} min={0} value={Boolean(this.state.unitPrice) ? this.state.unitPrice : this.state.unitPriceShow} onChange={this.onChangeUnitPrice} />
                                    <span style={{ visibility: 'hidden' }}>.</span>
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.amountOfFuel"
                                        defaultMessage="Amount of Fuel" />}
                                    name="amountOfFuel"
                                    rules={[{
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                            defaultMessage="This field is required." />
                                    }]}>

                                    <InputNumber style={{ width: '85%' }} min={0} value={Boolean(this.state.amountOfFuel) ? this.state.amountOfFuel : this.state.amountOfFuelShow} onChange={this.onChangeAmountOfFuel} />
                                    <span style={{ visibility: 'hidden' }}>.</span>
                                </Form.Item>
                            }

                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.KmOfHrTruck"
                                        defaultMessage="Vehicle KM/hr" />}
                                    name="kmOfTruck"
                                    rules={[{
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED"
                                            defaultMessage="This field is required." />
                                    }]}>

                                    <InputNumber
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                        style={{ width: '100%' }} min={this.state.minKmOfTruck} value={this.state.kmOfTruck} onChange={this.onChangekmOfTruck} />
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.invoicePhotoPath"
                                        defaultMessage="Upload Invoice" />}
                                    name="invoicePhotoPath"
                                    rules={[{ required: false, message: "Select file" }]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                >
                                    <Upload {...listUploadProps} name="file">
                                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                                    </Upload>
                                </Form.Item>
                            }
                            {
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="fuelRefill.meterPhotoPath"
                                        defaultMessage="Photo of dashboard" />}
                                    name="meterPhotoPath"
                                    rules={[{ required: false, message: "Select file" }]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                >
                                    <Upload {...listUploadProps} name="file">
                                        <AntButton icon={<UploadOutlined />}> Photo of Meter </AntButton>
                                    </Upload>
                                </Form.Item>
                            }
                            {/*{this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (*/}
                            {/*    <ElementsProvider*/}
                            {/*        inputs={this.state.pageFormData.pageFormInputCompanyList}*/}
                            {/*        layout={layout}*/}
                            {/*        form={this.formRef}*/}
                            {/*        refresh={this.state.refreshForm}*/}
                            {/*    />*/}
                            {/*) : null}*/}
                            <div hidden={this.state.hideSave}>
                                {
                                    <Row gutter={[16, 16]}>
                                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                            <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success" onClick={this.state.restartTable}>
                                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />







                                            </Button>
                                        </Col>
                                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    </Row>
                                }
                            </div>
                            <div hidden={this.state.hideUpdate}>
                                {
                                    <Row gutter={[16, 16]}>
                                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                            <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                            <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                            </Button>
                                        </Col>
                                        <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    </Row>
                                }
                            </div>
                        </Form>
                    </div>
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                        {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                            <DataTableComponent2
                                key="dataTable"
                                field={this.state.pageFormData.pageForm}
                                inputs={this.state.pageFormData.pageFormInputCompanyList}
                                actions={this.action}
                                editRow={this.edit.bind(this)}
                                restartTable={this.state.restartTable}
                                createWhereParams={this.state.createWhereParams}
                                setData={this.setData.bind(this)}
                            />
                        ) : null}
                    </div>

                    <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={this.delete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <DraggableModal
                        title={<FormattedMessage id="BillOfQuantityAddProduct.NewSupplier" defaultMessage="New Supplier" />}
                        centered
                        width={600}
                        open={this.state.showNewSupplierModal}
                        onOk={() => this.formRefNewSupplier.current.submit()}
                        onCancel={() => {
                            this.setState({ showNewSupplierModal: false });
                        }}
                        content={
                            <div className="card card-custom" style={{ minHeight: "100%" }}>
                                <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewSupplierSubmit} ref={this.formRefNewSupplier}>
                                    {
                                        <Form.Item
                                            {...layout}
                                            label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                                            style={{
                                                marginBottom: 0
                                            }}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                }
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} />
                                        </Form.Item>
                                    }
                                    {
                                        <Form.Item
                                            {...layout}
                                            label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                                            style={{
                                                marginBottom: 0
                                            }}
                                            name="address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                }
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} />
                                        </Form.Item>
                                    }
                                    {
                                        <Form.Item
                                            {...layout}
                                            label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                                            style={{
                                                marginBottom: 0
                                            }}
                                            name="emails"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                }
                                            ]}
                                        >
                                            <Input style={{ width: "100%" }} />
                                        </Form.Item>
                                    }
                                </Form>
                            </div>
                        }
                    ></DraggableModal>

                    <Drawer
                        title={<FormattedMessage id="ProjectTakeOffMain.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
                        width={1200}
                        closable={false}
                        onClose={() => {
                            this.setState({ drawerSettingsShow: false });
                        }}
                        open={this.state.drawerSettingsShow}
                    >
                        <PageSettings pageName={"Fuel_Refill"} />
                    </Drawer>
                </div>
            </Badge.Ribbon>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FuelRefill);