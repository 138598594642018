/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { PersonnelAccount } from "../finance/PersonnelAccount";
import { Timesheet } from "../humanresources/Timesheet";
import {Button as AntButton} from "antd"
import PersonnelInformation from "../finance/PersonnelInformation";
import PersonnelContractSummary from "../finance/PersonnelContractSummary";
import PersonnelContacts from "../finance/PersonnelContacts";
export function UserProfile() {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("personalInformation");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (

    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::User */}
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <img src={Boolean(user.photoPath) ? user.photoPath : toAbsoluteUrl("/media/logos/mndlogo.png")} alt="" />
                    <i className="symbol-badge bg-success"></i>
                  </div>
                  <div>
                    <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                      {Boolean(user) && Boolean(user.name) && Boolean(user.surname) ? user.name + " " + user.surname : ""}
                    </a>
                    <div className="text-muted">{Boolean(user) && Boolean(user.designation) ? user.designation.name : ""}</div>
                    <div className="mt-2"></div>
                  </div>
                </div>
                {/* end::User */}
                {/* begin::Contact */}
                <div className="py-9">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Email:</span>
                    <span className="text-muted text-hover-primary">{Boolean(user) && Boolean(user.email) ? user.email : ""}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Country:</span>
                    <span className="text-muted">{Boolean(user) && Boolean(user.country) ? user.country.nicename : ""}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="font-weight-bold mr-2">Department:</span>
                    <span className="text-muted">{Boolean(user) && Boolean(user.department) ? user.department.name : ""}</span>
                  </div>
                </div>
                {/* end::Contact */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <a
                      href="#"
                      className="navi-link py-4"
                      onClick={() => {
                        setSelectedTab("personalInformation");
                      }}
                    >
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                        </span>
                      </span>
                      <span className="navi-text font-size-lg">Personal Information</span>
                    </a>
                  </div>
                  {/* <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("accountInformation");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Account Information</span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("statements");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Statements</span>
                      </a>
                    </OverlayTrigger>
                  </div> */}


                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("contactstab");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Contacts</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("contractsummarytab");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Contract summary</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("timsheet");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Timesheet</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("payroll");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Payroll</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("vacationstab");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Vacations</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                      <a
                        href="#"
                        className="navi-link py-4"
                        onClick={() => {
                          setSelectedTab("account");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">Account</span>
                      </a>
                    </OverlayTrigger>
                  </div>

                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            <div className="flex-row-fluid ml-lg-8">
              {selectedTab === "personalInformation" && (
                
                <PersonnelInformation user={user} ></PersonnelInformation>
              )}            ,
            </div>
            {/* <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "personalInformation" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Personal Information</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Personal Info</h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                      <div className="col-lg-9 col-xl-6">
                        <img src={Boolean(user.photoPath) ? user.photoPath : toAbsoluteUrl("/media/logos/mndlogo.png")} alt="" className="w-100px" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">First Name</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Company Name</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.companyId) ? user.companyId : ""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className={`form-control form-control-lg form-control-solid`}
                            name="firstname"
                            value={Boolean(user) && Boolean(user.phone) ? user.phone : ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-at"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className={`form-control form-control-lg form-control-solid`}
                            name="firstname"
                            value={Boolean(user) && Boolean(user.email) ? user.email : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}

{selectedTab === "contractsummarytab" && (
              <PersonnelContractSummary user={user} ></PersonnelContractSummary>
            )}

{selectedTab === "contactstab" && (
              <PersonnelContacts user={user} ></PersonnelContacts>
            )}

            {/* {selectedTab === "contactstab" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">

                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Contacts</h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Adress</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">GSM</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Company GSM</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Personnel Email</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Company Email</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Emergency Person</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Emergency Phone No</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className={`form-control form-control-lg form-control-solid`}
                            name="firstname"
                            value={Boolean(user) && Boolean(user.phone) ? user.phone : ""}
                          />
                        </div>
                      </div>
                    </div>
                    <br>
                    </br>
                    <br>
                    </br>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Home Country</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Address </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.companyId) ? user.companyId : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Emergency Person </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.companyId) ? user.companyId : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Emergency Phone No</label>
                      <div className="col-lg-9 col-xl-6">
                        <div className="input-group input-group-lg input-group-solid">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className={`form-control form-control-lg form-control-solid`}
                            name="firstname"
                            value={Boolean(user) && Boolean(user.phone) ? user.phone : ""}
                          />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            )} */}

            {/* {selectedTab === "contractsummarytab" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">

                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Contract Summary</h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Contract Type</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Start Date</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Contract Duration</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.name) ? user.name : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Annual Vacation Days</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Salary</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Overtime</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Accomodation</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Transportation</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Fooding </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Family Status </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Vacation Days</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Insurance</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">Fly Ticket</label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          type="text"
                          readOnly
                          className={`form-control form-control-lg form-control-solid`}
                          name="firstname"
                          value={Boolean(user) && Boolean(user.surname) ? user.surname : ""}
                        />
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            )} */}

            {selectedTab === "accountInformation" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Account Information</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Account Info</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "vacationstab" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Vacations</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Vacations </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "payroll" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Payroll</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Payroll </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "statements" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Statements</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <div className="row">
                      <label className="col-xl-3"></label>
                      <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mb-6">Statements Info</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "personnelDetail" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Personnel Account</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <PersonnelAccount userId={user.id} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "timsheet" && (
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Personnel Account</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="form">
                  <div className="card-body">
                    <Timesheet personnelLogId={user.id} />
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "account" && (
              <div className="card card-custom card-stretch">
            <div className="card-header py-3">
              <div className="card-toolbar"></div>
              <AntButton style={{ border: "0px" }} icon={"L"}></AntButton>
              <AntButton style={{ border: "0px" }} icon={"W"}></AntButton>
              <AntButton style={{ border: "0px" }} icon={"T"}></AntButton>
              <AntButton style={{ border: "0px" }} icon={"R"}></AntButton>
            </div>
                <div className="form">
                  <div className="card-body">
                    <PersonnelAccount personnelLogId={user.id} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
