import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Input, InputNumber, Col, Row, DatePicker } from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import moment from "moment";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
    });
  };

  const toggleSave = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record }, { ...values });
    } catch (errInfo) {
      error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {(dataIndex === "workingHours" || dataIndex === "overtime") ?
          <InputNumber min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
          :
          <Input ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
        }
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class Timesheet extends Component {
  constructor() {
    super();
    this.state = {
      controller: "timesheet",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      personnelsSelectItems: [],
      departmentItems: [],
      designationItems: [],
      searchCriteriaModels: [],
      tableRender: true,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.formRef.current.setFieldsValue({
      date: moment()
    });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    let url = "/api/" + this.state.controller + "/personnels";
    const responsePersonnels = await handleRequest("GET", url);
    if (responsePersonnels.type === "ERROR") {
      error(responsePersonnels);
    } else {
      this.setState({
        personnelsSelectItems: Boolean(responsePersonnels.data) ? responsePersonnels.data : [],
      });
    }

    await this.initialize();
  };

  async initialize() {
    var searchCriteriaModels = [];
    let date = Boolean(this.formRef.current.getFieldValue("date")) ?
      moment(this.formRef.current.getFieldValue("date")).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY");
    if (Boolean(date)) {
      searchCriteriaModels.push({
        "key": "date",
        "value": date,
        "operation": "DATE_DateIs",
        "type": "Match_All",
      });
    }
    var newObj = {
      searchCriteriaModels: searchCriteriaModels
    };
    let urlDailyFirstData = "/api/" + this.state.controller + "/dailyFirstData";
    const responseDailyFirstData = await handleRequest("POST", urlDailyFirstData, newObj);
    if (responseDailyFirstData.type === "ERROR") {
      error(responseDailyFirstData);
    }
    else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    }
  }

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    var parent = dataList.find(p => p.key === row.parentId);
    let parentId = [];

    if (Boolean(parent)) {
      parentId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentId: parentId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controller + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controller + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = index + 1;
          if (Boolean(element.timeIn)) {
            element.timeIn = moment(element.timeIn).format("HH:mm");
          }
          if (Boolean(element.timeOut)) {
            element.timeOut = moment(element.timeOut).format("HH:mm");
          }
        });
        this.setState({
          tableRender: true,
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controller + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controller + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";

    let date = moment().format("DD-MM-YYYY");
    if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("date"))) {
      date = moment(this.formRef.current.getFieldValue("date")).format("DD-MM-YYYY");
    }
    if (Boolean(date)) {
      searchCriteriaModels.push({
        "key": "date",
        "value": date,
        "operation": "DATE_DateIs",
        "type": "Match_All",
      });
    }
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels,
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controller + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  }

  handleTimeIn = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.timeIn === value.timeIn) return;
        item.timeIn = value.timeIn;

        let overtime = 0;
        let workingHours = 0;
        let result = moment(item.timeOut, "HH:mm").diff(moment(value.timeIn, "HH:mm"), 'hours', true);
        if (result > 1) {
          workingHours = (result - 1).toFixed(2);
        } else {
          workingHours = result.toFixed(2);
        }
        if (result > 9) {
          overtime = (result - 9).toFixed(2);
        }
        item.overtime = overtime;
        item.workingHours = workingHours;

        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleTimeOut = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.timeOut === value.timeOut) return;
        item.timeOut = value.timeOut;
        let overtime = 0;
        let result = moment(value.timeOut, "HH:mm").diff(moment(item.timeIn, "HH:mm"), 'hours', true);
        if (result > 9) {
          overtime = (result - 9).toFixed(2);
        }
        item.overtime = overtime;
        item.workingHours = (result - 1).toFixed(2);
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleWorkingHours = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.workingHours === value.workingHours) return;
        item.workingHours = value.workingHours;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleOvertime = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.overtime === value.overtime) return;
        item.overtime = value.overtime;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  saveAll = async (type) => {
    this.setState({ tableRender: false });
    if (Boolean(this.state.tableList) && this.state.tableList.length > 0) {
      this.state.tableList.forEach(element => {
        if (Boolean(element.timeIn)) {
          element.timeIn = moment((moment(element.date).format("DD-MM-YYYY") + element.timeIn), "DD-MM-YYYY HH:mm")
        }
        if (Boolean(element.timeOut)) {
          element.timeOut = moment((moment(element.date).format("DD-MM-YYYY") + element.timeOut), "DD-MM-YYYY HH:mm")
        }
      });
      var response = await handleRequest("POST", "/api/" + this.state.controller + "/" + type, this.state.tableList);
      if (response.type === "ERROR") {
        showError("No records to be added");
      } else {
        this.resetInputs();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("No records to be added");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const columns = [
      // {
      //   key: "key",
      //   title: "Actions",
      //   render: this.action
      // },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Timesheet.departmentName" defaultMessage="Department" />,
        key: "departmentName",
        render: record => {
          return record.departmentName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="Timesheet.designationName" defaultMessage="Designation" />,
        key: "designationName",
        render: record => {
          return record.designationName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="Timesheet.personnelName" defaultMessage="Name" />,
        key: "personnelName",
        render: record => {
          return record.personnelName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="Timesheet.identificationNo" defaultMessage="ID" />,
        key: "identificationNo",
        render: record => {
          return record.identificationNo;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="Timesheet.timeIn" defaultMessage="Time In" />,
        key: "timeIn",
        render: record => {
          return (record.closeTimeIn || record.type !== "MANUEL") ?
            <p className="text-primary">{Boolean(record.timeIn) ? record.timeIn : ""}</p> :
            <p className="text-warning">{Boolean(record.timeIn) ? record.timeIn : ""}</p>;
        },
        sorter: false,
        onCell: record => ({
          record,
          editable: (record.closeTimeIn || record.type !== "MANUEL") ? false : true,
          dataIndex: "timeIn",
          title: <FormattedMessage id="Timesheet.timeIn" defaultMessage="Time In" />,
          handleSave: this.handleTimeIn
        }),
      },
      {
        title: <FormattedMessage id="Timesheet.timeOut" defaultMessage="Time Out" />,
        key: "timeOut",
        render: record => {
          return (record.closeTimeOut || record.type !== "MANUEL") ?
            <p className="text-primary">{Boolean(record.timeOut) ? record.timeOut : ""}</p> :
            <p className="text-warning">{Boolean(record.timeOut) ? record.timeOut : ""}</p>;
        },
        sorter: false,
        onCell: record => ({
          record,
          editable: (record.closeTimeOut || record.type !== "MANUEL") ? false : true,
          dataIndex: "timeOut",
          title: <FormattedMessage id="Timesheet.timeOut" defaultMessage="Time Out" />,
          handleSave: this.handleTimeOut
        }),
      },
      {
        title: <FormattedMessage id="Timesheet.workingHours" defaultMessage="Working Hours" />,
        key: "workingHours",
        render: record => {
          return record.workingHours;
        },
        sorter: false,
        // onCell: record => ({
        //   record,
        //   editable: true,
        //   dataIndex: "workingHours",
        //   title: <FormattedMessage id="Timesheet.workingHours" defaultMessage="Working Hours" />,
        //   handleSave: this.handleWorkingHours
        // }),
      },
      {
        title: <FormattedMessage id="Timesheet.overtime" defaultMessage="Overtime" />,
        key: "overtime",
        render: record => {
          return record.overtime;
        },
        sorter: false,
        // onCell: record => ({
        //   record,
        //   editable: true,
        //   dataIndex: "overtime",
        //   title: <FormattedMessage id="Timesheet.overtime" defaultMessage="Overtime" />,
        //   handleSave: this.handleOvertime
        // }),
      },
      {
        title: <FormattedMessage id="Timesheet.type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        sorter: false
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        sorter: false
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <span className="h3">
                <FormattedMessage
                  id={this.state.controllerName + ".pageTitle"}
                  defaultMessage={"Timesheet"}
                />
              </span>
            </Col>
            <Col md={4}>
              <Button id="SaveButton" onClick={() => this.saveAll("saveAll")} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="Timesheet.SaveAllButton" defaultMessage="Save" />
              </Button>
            </Col>
            <Col md={4}>
              <Button id="SaveButtonCloseTimeIn" onClick={() => this.saveAll("saveCloseTimeIn")} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="Timesheet.SaveButtonCloseTimeIn" defaultMessage="Save and close time in" />
              </Button>
            </Col>
            <Col md={4}>
              <Button id="SaveButtonCloseTimeOut" onClick={() => this.saveAll("saveCloseTimeOut")} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="Timesheet.SaveButtonCloseTimeOut" defaultMessage="Save and close time out" />
              </Button>
            </Col>
          </Row>

          <br />
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="Timesheet.date" defaultMessage="Date" />}
              name="date"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker disabledDate={this.disabledDate} defaultValue={moment()}
                format="DD-MM-YYYY" style={{ width: "100%" }}
                onChange={async e => {
                  if (!Boolean(e)) {
                    this.formRef.current.setFieldsValue({
                      date: moment()
                    });
                  }
                  await this.initialize();
                }} />
            </Form.Item>
          }
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.tableRender &&
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet);