import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Badge, Modal as AntModal, InputNumber, Upload, Cascader } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "antd";
import { Input } from "antd";
import { MinusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import "./styles.css";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class ActivityLogWorkFlow extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ActivityLogWorkFlow",
      controllerName: "activityLogWorkFlow",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],
      prerequisitionSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefEquipment = React.createRef();
  formRefTool = React.createRef();
  formRefMeasuringDevice = React.createRef();
  formRefPersonnel = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseDepartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/departmentId");
    if (responseDepartmentId.type === "ERROR") {
      error(responseDepartmentId);
    }
    var responseDesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designationId");
    if (responseDesignationId.type === "ERROR") {
      error(responseDesignationId);
    }
    var responseActivity = await handleRequest("GET", "/api/" + this.state.controllerName + "/activity");
    if (responseActivity.type === "ERROR") {
      error(responseActivity);
    }
    var responseEquipmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentId");
    if (responseEquipmentId.type === "ERROR") {
      error(responseEquipmentId);
    }

    let prerequisitionSelectItems = [];
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/prerequisition");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      prerequisitionSelectItems = responseTree.data;
    }

    this.setState({
      equipmentIdSelectItems: Boolean(responseEquipmentId.data) ? responseEquipmentId.data : [],
      departmentIdSelectItems: Boolean(responseDepartmentId.data) ? responseDepartmentId.data : [],
      activitySelectItems: Boolean(responseActivity.data) ? responseActivity.data : [],
      designationIdSelectItems: Boolean(responseDesignationId.data) ? responseDesignationId.data : [],
      prerequisitionSelectItems: prerequisitionSelectItems,
    }, () => {
      this.fillActivityLogWorkFlow();
    });
  };

  fillActivityLogWorkFlow = async () => {
    const { selectedActivityLogWorkFlow } = this.props;
    if (Boolean(selectedActivityLogWorkFlow) && Boolean(selectedActivityLogWorkFlow.id) && selectedActivityLogWorkFlow.id > 0) {
      var responseItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityLogWorkFlow/" + selectedActivityLogWorkFlow.id);
      if (responseItem.type === "ERROR") {
        error(responseItem);
      }
      if (Boolean(responseItem.data)) {
        responseItem.data.forEach((element, index) => {
          let itemIdSelectItems = [];
          let equipmentIdSelectItems = [];
          let toolIdSelectItems = [];
          let measuringDeviceIdSelectItems = [];

          let uploadedFiles = [];
          let photoPath = Boolean(element.uploadPath) && element.uploadPath.length > 0 ? element.uploadPath.split(",") : [];
          if (Boolean(photoPath) && photoPath.length > 0) {
            photoPath.forEach((item, index) => {
              let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
              uploadedFiles.push(obj);
            });
          }
          element.uploadPath = uploadedFiles;

          var items = [];
          if (Boolean(element.items) && element.items.length > 0) {
            element.items.forEach(element => {
              if (!itemIdSelectItems.some(item => item.key === element.itemId)) {
                items.push(element.itemId);
                itemIdSelectItems.push({ "key": element.itemId, "value": element.itemName });
              }
            });
          }
          element.items = items;

          if (Boolean(element.equipments) && element.equipments.length > 0) {
            element.equipments.forEach(element => {
              if (!equipmentIdSelectItems.some(item => item.key === element.equipmentLogId)) {
                equipmentIdSelectItems.push({ "key": element.equipmentLogId, "value": element.equipmentLogName });
              }
            });
          }

          if (Boolean(element.tools) && element.tools.length > 0) {
            element.tools.forEach(element => {
              if (!toolIdSelectItems.some(item => item.key === element.toolId)) {
                toolIdSelectItems.push({ "key": element.toolId, "value": element.toolName });
              }
            });
          }

          if (Boolean(element.measuringDevices) && element.measuringDevices.length > 0) {
            element.measuringDevices.forEach(element => {
              if (!measuringDeviceIdSelectItems.some(item => item.key === element.measuringDeviceId)) {
                measuringDeviceIdSelectItems.push({ "key": element.measuringDeviceId, "value": element.measuringDeviceName });
              }
            });
          }
          var parent = dataList.find(p => p.key === element.prerequisition);
          let prerequisition = [];
          if (Boolean(parent)) {
            prerequisition = parent.lineage;
          }
          element.prerequisition = prerequisition;

          this.setState({
            [`itemIdSelectItems${index}`]: itemIdSelectItems,
            [`equipmentIdSelectItems${index}`]: equipmentIdSelectItems,
            [`toolIdSelectItems${index}`]: toolIdSelectItems,
            [`measuringDeviceIdSelectItems${index}`]: measuringDeviceIdSelectItems,
          });
        });
      }
      this.formRef.current.setFieldsValue({
        activityLogWorkFlowDetailList: Boolean(responseItem.data) ? responseItem.data : null,
      });
    } else {
      this.setState({
        itemIdSelectItems: [],
      });
      this.formRef.current.setFieldsValue({
        activityLogWorkFlowDetailList: null,
      });
    }
  }

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    const { selectedActivityLogWorkFlow } = this.props;
    if (Boolean(selectedActivityLogWorkFlow) && selectedActivityLogWorkFlow.id > 0) {

      if (Boolean(values.activityLogWorkFlowDetailList) && values.activityLogWorkFlowDetailList.length > 0) {
        values.activityLogWorkFlowDetailList.forEach(element => {
          let pathList = [];
          if (Boolean(element.uploadPath))
            element.uploadPath.forEach(item => {
              if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
              else if (Boolean(item.url)) pathList.push(item.url);
            });
          element.uploadPath = pathList.toString();


          let items = [];
          if (element.items && element.items.length > 0)
            element.items.forEach(element => {
              items.push({ itemId: element });
            });
          element.items = items;

          element.prerequisition = Boolean(element.prerequisition) ? element.prerequisition[element.prerequisition.length - 1] : null;

        });
      }

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + selectedActivityLogWorkFlow.id, values.activityLogWorkFlowDetailList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        if (Boolean(this.props) && Boolean(this.props.close)) {
          this.props.close();
        }
      }
    } else {
      showWarning("Activity Log not found.");
    }
  };

  saveEquipment = async () => {
    const activityLogWorkFlowDetailList = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
    const equipments = this.formRefEquipment.current.getFieldValue("equipments");
    const { fieldIndex } = this.state;
    let activityLogWorkFlowDetailListIndex = activityLogWorkFlowDetailList[fieldIndex];

    let equipmentIdSelectItems = [];
    if (Boolean(equipments) && equipments.length > 0) {
      equipments.forEach(element => {
        if (!equipmentIdSelectItems.some(item => item.key === element.equipmentLogId)) {
          let row = this.state.equipmentIdSelectItems.find(p => p.key === element.equipmentLogId);
          equipmentIdSelectItems.push({ "key": element.equipmentLogId, "value": row != null ? row.value : "" });
        }
      });
    }
    if (Boolean(activityLogWorkFlowDetailListIndex)) {
      activityLogWorkFlowDetailListIndex.equipments = equipments;
    } else {
      activityLogWorkFlowDetailList[fieldIndex] = { equipments: equipments };
    }
    this.formRef.current.setFieldsValue({ activityLogWorkFlowDetailList: activityLogWorkFlowDetailList });

    this.setState({
      showEquipmentModal: false,
      [`equipmentIdSelectItems${fieldIndex}`]: equipmentIdSelectItems,
    });
  };

  saveTool = async () => {
    const activityLogWorkFlowDetailList = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
    const tools = this.formRefTool.current.getFieldValue("tools");
    const { fieldIndex } = this.state;
    let activityLogWorkFlowDetailListIndex = activityLogWorkFlowDetailList[fieldIndex];

    let toolIdSelectItems = [];
    if (Boolean(tools) && tools.length > 0) {
      tools.forEach(element => {
        if (!toolIdSelectItems.some(item => item.key === element.toolId)) {
          let row = this.state.equipmentIdSelectItems.find(p => p.key === element.toolId);
          toolIdSelectItems.push({ "key": element.toolId, "value": row != null ? row.value : "" });
        }
      });
    }

    if (Boolean(activityLogWorkFlowDetailListIndex)) {
      activityLogWorkFlowDetailListIndex.tools = tools;
    } else {
      activityLogWorkFlowDetailList[fieldIndex] = { tools: tools };
    }

    this.formRef.current.setFieldsValue({ activityLogWorkFlowDetailList: activityLogWorkFlowDetailList });
    this.setState({
      showToolModal: false,
      [`toolIdSelectItems${fieldIndex}`]: toolIdSelectItems,
    });
  };

  saveMeasuringDevice = async () => {
    const activityLogWorkFlowDetailList = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
    const measuringDevices = this.formRefMeasuringDevice.current.getFieldValue("measuringDevices");
    const { fieldIndex } = this.state;
    let activityLogWorkFlowDetailListIndex = activityLogWorkFlowDetailList[fieldIndex];

    let measuringDeviceIdSelectItems = [];
    if (Boolean(measuringDevices) && measuringDevices.length > 0) {
      measuringDevices.forEach(element => {
        console.log("element", element);
        if (!measuringDeviceIdSelectItems.some(item => item.key === element.measuringDeviceId)) {
          let row = this.state.equipmentIdSelectItems.find(p => p.key === element.measuringDeviceId);
          measuringDeviceIdSelectItems.push({ "key": element.measuringDeviceId, "value": row != null ? row.value : "" });
        }
      });
    }

    if (Boolean(activityLogWorkFlowDetailListIndex)) {
      activityLogWorkFlowDetailListIndex.measuringDevices = measuringDevices;
    } else {
      activityLogWorkFlowDetailList[fieldIndex] = { measuringDevices: measuringDevices };
    }

    this.formRef.current.setFieldsValue({ activityLogWorkFlowDetailList: activityLogWorkFlowDetailList });
    this.setState({
      showMeasuringDeviceModal: false,
      [`measuringDeviceIdSelectItems${fieldIndex}`]: measuringDeviceIdSelectItems,
    });
  };

  savePersonnel = async () => {
    const activityLogWorkFlowDetailList = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
    const personnels = this.formRefPersonnel.current.getFieldValue("personnels");
    let activityLogWorkFlowDetailListIndex = activityLogWorkFlowDetailList[this.state.fieldIndex];

    if (Boolean(activityLogWorkFlowDetailListIndex)) {
      activityLogWorkFlowDetailListIndex.personnels = personnels;
    } else {
      activityLogWorkFlowDetailList[this.state.fieldIndex] = { personnels: personnels };
    }

    this.formRef.current.setFieldsValue({ activityLogWorkFlowDetailList: activityLogWorkFlowDetailList });
    this.setState({
      showPersonnelModal: false
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  searchItem = async (fieldIndex, item) => {
    console.log("item", item);
    console.log("fieldIndex", fieldIndex);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          [`itemIdSelectItems${fieldIndex}`]: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            [`itemIdSelectItems${fieldIndex}`]: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            [`itemIdSelectItems${fieldIndex}`]: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        [`itemIdSelectItems${fieldIndex}`]: [],
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <br></br>
          <br></br>
          {
            <Form.List name="activityLogWorkFlowDetailList">
              {(fields, { add, remove }) => {
                return (
                  <div className="scrollable-table-container">
                    <TableBoot bordered responsive="sm" className="TableBoot">
                      <thead>
                        <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Steps"} defaultMessage={"Steps"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Department"} defaultMessage={"Department"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FormattedMessage id={"ActivityLog.Activity"} defaultMessage={"Activity"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Prerequisite"} defaultMessage={"Prerequisite"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Document"} defaultMessage={"Document"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Upload"} defaultMessage={"Upload"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Item"} defaultMessage={"Item"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Tool"} defaultMessage={"Tool"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />
                          </th>
                          <th colSpan="3" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.PerformanceCriteria"} defaultMessage={"Performance Criteria"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Personnel"} defaultMessage={"Personnel"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"ActivityLog.Remarks"} defaultMessage={"Remarks"} />
                          </th>
                          <th colSpan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <Form.Item noStyle hidden name={[field.name, "id"]}></Form.Item>
                              <td>
                                <Form.Item noStyle name={[field.name, "items"]}>
                                  <Input style={{ width: "150px" }} 
                                    
                                  >
                                    
                                  </Input>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "departmentId"]}>
                                  <Select style={{ width: "150px" }} >
                                    {Boolean(this.state["departmentIdSelectItems"]) && this.state["departmentIdSelectItems"].length > 0 &&
                                      this.state["departmentIdSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "activity"]}>
                                  <Select style={{ width: "150px" }} >
                                    {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                                      this.state["activitySelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "prerequisition"]}>
                                  <Cascader changeOnSelect style={{ width: "150px" }}
                                    onChange={value => {
                                      this.setState({
                                        prerequisition: Boolean(value) && value.length > 0 ? value[value.length - 1] : null,
                                      });
                                    }}
                                    options={this.state.prerequisitionSelectItems} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "documentId"]}>
                                  <Select style={{ width: "150px" }}>
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "uploadPath"]}
                                  rules={[{ required: false, message: "Missing Load" }]}
                                  valuePropName="fileList"
                                  getValueFromEvent={normFile}
                                >
                                  <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                                    <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                                      <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                    </AntButton>
                                  </Upload>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "items"]}>
                                  <Select style={{ width: "150px" }} mode="multiple"
                                    allowClear
                                    showSearch
                                    onSearch={(value) => this.searchItem(field.name, value)}
                                    loading={this.state.loadingSearch}
                                    onChange={value => {

                                      let itemIdSelectItems = [];
                                      if (Boolean(value) && value.length > 0) {
                                        value.forEach(element => {
                                          let row = this.state[`itemIdSelectItems${field.name}`].find(p => p.key === element);
                                          if (Boolean(row) && Boolean(row.value)) {
                                            itemIdSelectItems.push({ "key": element, "value": row.value });
                                          }
                                        });
                                      }
                                      this.setState({
                                        itemId: value,
                                        [`itemIdSelectItems${field.name}`]: itemIdSelectItems,
                                      });
                                    }}
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {Boolean(this.state[`itemIdSelectItems${field.name}`]) &&
                                      this.state[`itemIdSelectItems${field.name}`].length > 0 &&
                                      this.state[`itemIdSelectItems${field.name}`].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <AntButton
                                  type="primary"
                                  onClick={() => this.setState({
                                    showEquipmentModal: true,
                                    fieldIndex: field.name
                                  }, () => {
                                    let list = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
                                    if (Boolean(list) && list.length > 0) {
                                      this.formRefEquipment.current.setFieldsValue({
                                        equipments: Boolean(list[field.name]) && Boolean(list[field.name].equipments)
                                          ? list[field.name].equipments : null,
                                      });
                                    }
                                  })}
                                >Equipment </AntButton>
                              </td>
                              <td>
                                <AntButton
                                  type="primary"
                                  onClick={() => this.setState({
                                    showToolModal: true,
                                    fieldIndex: field.name
                                  }, () => {
                                    let list = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
                                    if (Boolean(list) && list.length > 0) {
                                      this.formRefTool.current.setFieldsValue({
                                        tools: Boolean(list[field.name]) && Boolean(list[field.name].tools)
                                          ? list[field.name].tools : null,
                                      });
                                    }
                                  })}
                                >Tool </AntButton>
                              </td>
                              <td>
                                <AntButton
                                  type="primary"
                                  onClick={() => this.setState({
                                    showMeasuringDeviceModal: true,
                                    fieldIndex: field.name
                                  }, () => {
                                    let list = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
                                    if (Boolean(list) && list.length > 0) {
                                      this.formRefMeasuringDevice.current.setFieldsValue({
                                        measuringDevices: Boolean(list[field.name]) && Boolean(list[field.name].measuringDevices)
                                          ? list[field.name].measuringDevices : null,
                                      });
                                    }
                                  })}
                                >Measuring Device </AntButton>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "performanceCriteriaItemId"]}>
                                  <Select style={{ width: "150px" }}
                                    allowClear
                                    showSearch
                                    onChange={value => {
                                      this.setState({
                                        performanceCriteriaItemId: value
                                      }, async () => {
                                        if (Boolean(value) && value > 0) {
                                          var responsePurchasingcriterias = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + value);
                                          if (responsePurchasingcriterias.type === "ERROR") {
                                            error(responsePurchasingcriterias);
                                          }
                                          this.setState({
                                            purchasingcriteriasSelectItems: Boolean(responsePurchasingcriterias.data) ? responsePurchasingcriterias.data : [],
                                          });
                                        } else {
                                          this.setState({
                                            purchasingcriteriasSelectItems: [],
                                          });
                                        }
                                      });
                                    }}
                                  >
                                    {Boolean(this.state[`itemIdSelectItems${field.name}`]) && this.state[`itemIdSelectItems${field.name}`].length > 0 &&
                                      this.state[`itemIdSelectItems${field.name}`].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "purchasingCriteriaId"]}>
                                  <Select style={{ width: "150px" }}
                                    allowClear
                                    showSearch
                                    onChange={value => {
                                      this.setState({
                                        purchasingCriteriaId: value
                                      }, async () => {
                                        if (Boolean(value) && value > 0) {
                                          var responsePurchasingcriterias = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + value);
                                          if (responsePurchasingcriterias.type === "ERROR") {
                                            error(responsePurchasingcriterias);
                                          }
                                          this.setState({
                                            unitIdSelectItems: Boolean(responsePurchasingcriterias.data) ? responsePurchasingcriterias.data : [],
                                          });
                                        } else {
                                          this.setState({
                                            unitIdSelectItems: [],
                                          });
                                        }
                                      });
                                    }}
                                  >
                                    {Boolean(this.state["purchasingcriteriasSelectItems"]) && this.state["purchasingcriteriasSelectItems"].length > 0 &&
                                      this.state["purchasingcriteriasSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "unitId"]}>
                                  <Select style={{ width: "150px" }}
                                    allowClear
                                    showSearch
                                  >
                                    {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                                      this.state["unitIdSelectItems"].map(i => (
                                        <Option key={i.id} value={i.id}>
                                          {i.abbreviation}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <AntButton
                                  type="primary"
                                  onClick={() => this.setState({
                                    showPersonnelModal: true,
                                    fieldIndex: field.name
                                  }, () => {
                                    let list = this.formRef.current.getFieldValue("activityLogWorkFlowDetailList");
                                    if (Boolean(list) && list.length > 0) {
                                      this.formRefPersonnel.current.setFieldsValue({
                                        personnels: Boolean(list[field.name]) && Boolean(list[field.name].personnels)
                                          ? list[field.name].personnels : null,
                                      });
                                    }
                                  })}
                                >Personnel </AntButton>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "remarks"]}>
                                  <Input style={{ width: "150px" }} />
                                </Form.Item>
                              </td>
                              <td>
                                <PlusOutlined onClick={() => add(field.name)} />
                              </td>
                              <td>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          { /*  
                            {this.state.single && */}
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={() => { add(); }} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"ActivityLog.AddNew"} defaultMessage={"Add New Line"} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  </div>
                );
              }}
            </Form.List>
          }
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>


        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />}
          open={this.state.showEquipmentModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showEquipmentModal: false })}
          onOk={() => this.formRefEquipment.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }}
              onFinish={this.saveEquipment}
              ref={this.formRefEquipment}>
              {
                <Form.List name="equipments">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Quantity"} defaultMessage={"Quantity"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "equipmentLogId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state["equipmentIdSelectItems"]) && this.state["equipmentIdSelectItems"].length > 0 &&
                                          this.state["equipmentIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "quantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ActivityLog.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />

        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id={"ActivityLog.Tool"} defaultMessage={"Tool"} />}
          open={this.state.showToolModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showToolModal: false })}
          onOk={() => this.formRefTool.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }}
              onFinish={this.saveTool}
              onFinishFailed={onFinishFailed}
              ref={this.formRefTool}>
              {
                <Form.List name="tools">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Tool"} defaultMessage={"Tool"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Quantity"} defaultMessage={"Quantity"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "toolId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state["equipmentIdSelectItems"]) && this.state["equipmentIdSelectItems"].length > 0 &&
                                          this.state["equipmentIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "quantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ActivityLog.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />

        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />}
          open={this.state.showMeasuringDeviceModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showMeasuringDeviceModal: false })}
          onOk={() => this.formRefMeasuringDevice.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }}
              onFinish={this.saveMeasuringDevice}
              onFinishFailed={onFinishFailed}
              ref={this.formRefMeasuringDevice}>
              {
                <Form.List name="measuringDevices">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Quantity"} defaultMessage={"Quantity"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "measuringDeviceId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state["equipmentIdSelectItems"]) && this.state["equipmentIdSelectItems"].length > 0 &&
                                          this.state["equipmentIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "quantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ActivityLog.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />

        <DraggableModal
          centered
          width={1200}
          title={<FormattedMessage id={"ActivityLog.Personnel"} defaultMessage={"Personnel"} />}
          open={this.state.showPersonnelModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showPersonnelModal: false })}
          onOk={() => this.formRefPersonnel.current.submit()}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }}
              onFinish={this.savePersonnel}
              onFinishFailed={onFinishFailed}
              ref={this.formRefPersonnel}>
              {
                <Form.List name="personnels">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Activity"} defaultMessage={"Designation"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Equipment"} defaultMessage={"Equipment"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.Tool"} defaultMessage={"Tool"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ActivityLog.MeasuringDevice"} defaultMessage={"Measuring Device"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "designationId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state["designationIdSelectItems"]) && this.state["designationIdSelectItems"].length > 0 &&
                                          this.state["designationIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "designationQuantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  {console.log("equipmentIdSelectItems", this.state[`equipmentIdSelectItems0}`])}
                                  {console.log("equipmentIdSelectItems", this.state[`equipmentIdSelectItems1}`])}
                                  {console.log("equipmentIdSelectItems", this.state[`equipmentIdSelectItems${this.state.fieldIndex}`])}
                                  {console.log("fieldIndex", this.state.fieldIndex)}
                                  <td>
                                    <Form.Item noStyle name={[field.name, "equipmentId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state[`equipmentIdSelectItems${this.state.fieldIndex}`]) && this.state[`equipmentIdSelectItems${this.state.fieldIndex}`].length > 0 &&
                                          this.state[`equipmentIdSelectItems${this.state.fieldIndex}`].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "equipmentQuantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item noStyle name={[field.name, "toolId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state[`toolIdSelectItems${this.state.fieldIndex}`]) && this.state[`toolIdSelectItems${this.state.fieldIndex}`].length > 0 &&
                                          this.state[`toolIdSelectItems${this.state.fieldIndex}`].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "toolQuantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item noStyle name={[field.name, "measuringDeviceId"]}>
                                      <Select style={{ width: "150px" }}>
                                        {Boolean(this.state[`measuringDeviceIdSelectItems${this.state.fieldIndex}`]) && this.state[`measuringDeviceIdSelectItems${this.state.fieldIndex}`].length > 0 &&
                                          this.state[`measuringDeviceIdSelectItems${this.state.fieldIndex}`].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "measuringDeviceQuantity"]}>
                                      <InputNumber
                                        noStyle
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>


                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ActivityLog.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogWorkFlow);