import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Badge, Input, DatePicker, InputNumber, Cascader, Button as AntButton, Typography, Select } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { fillFilterOperations } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import DraggableModal from "../../Components/DraggableModal";

const { Text } = Typography;
const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "reqirementDate"
      ? form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY HH:mm") : ""
        })
      : form.setFieldsValue({
          [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
        });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "reqirementDate") {
        const values = await form.validateFields();
        values.reqirementDate = Boolean(values.reqirementDate) ? moment(values.reqirementDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY HH:mm") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="POVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="POVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "reqirementDate" ? (
              <DatePicker
                ref={inputRef}
                onBlur={toggleSave}
                format={"DD-MM-YYYY HH:mm"}
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              />
            ) : dataIndex === "purchasingCriteriaId" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.purchasingCriteriaIdSelectItems}
                ref={inputRef}
                onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : dataIndex === "unit" ? (
              <Cascader style={{ width: "100%" }} options={record.unitSelectItems} ref={inputRef} onChange={toggleSave} onBlur={toggleSave} />
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class PartRequirementList extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PartRequirementList",
      controllerName: "partRequirementList",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      avaliableQuantityList: [],
      estimatedSupplyDayList: [],
      estimatedPriceList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      modalStatusShow: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async () => {
    const { tableList } = this.state;
    if (Boolean(tableList) && tableList.length > 0) {
      tableList.forEach(element => {
        element.reqirementDate = Boolean(element.reqirementDate) ? moment(element.reqirementDate) : null;
      });
    }
    console.log(tableList);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", this.state.tableList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleRequiredQuantity = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.requiredQuantity === value.requiredQuantity) return;
        item.requiredQuantity = value.requiredQuantity;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleReqirementDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.reqirementDate === value.reqirementDate) return;
        item.reqirementDate = value.reqirementDate;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  };

  saveSendStatus = async () => {
    const { selectedRowKeys } = this.state;
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSendStatus/" + selectedRowKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Please select item.");
    }
  };

  avaliableQuantityModal = async row => {
    let avaliableQuantityList = [];
    if (Boolean(row.stockCardId) && row.stockCardId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/avaliable/" + row.stockCardId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          avaliableQuantityList = response.data.avaliable;
        }
      }
    }
    console.log("avaliableQuantityList", avaliableQuantityList);

    this.setState({
      modalAvaliableQuantityShow: true,
      avaliableQuantityList: avaliableQuantityList
    });
  };

  estimatedSupplyDayModal = async row => {
    let estimatedSupplyDayList = [];
    if (Boolean(row.stockCardId) && row.stockCardId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/estimatedSupplyDay/" + row.stockCardId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && response.data.length > 0) {
          estimatedSupplyDayList = response.data;
        }
      }
    }
    this.setState({
      modalEstimatedSupplyDayModalShow: true,
      estimatedSupplyDayList: estimatedSupplyDayList
    });
  };

  estimatedPriceModal = async row => {
    let estimatedPriceList = [];
    if (Boolean(row.stockCardId) && row.stockCardId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/estimatedPrice/" + row.stockCardId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && response.data.length > 0) {
          estimatedPriceList = response.data;
        }
      }
    }
    this.setState({
      modalEstimatedPriceShow: true,
      estimatedPriceList: estimatedPriceList
    });
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        itemIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => {
        return {
          disabled: !Boolean(record.id)
        };
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.project" defaultMessage="Project" />,
        key: "companyProjectId",
        render: record => {
          return record.companyProjectName;
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.ItemCode" defaultMessage="Item Code" />,
        key: "code",
        render: record => {
          return record.code;
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.Item" defaultMessage="Item" />,
        key: "id",
        render: record => {
          return record.renameText;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              onSearch={this.searchItem}
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {Boolean(this.state["itemIdSelectItems"]) &&
                this.state["itemIdSelectItems"].length > 0 &&
                this.state["itemIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.idColor) ? this.state.idColor : "#bfbfbf" }} />,
        sorter: true
      },
      {
        title: <FormattedMessage id="PartRequirementList.MinStockLevel" defaultMessage="Min Stock Level" />,
        key: "minStockLevel",
        width: 100,
        render: record => {
          return record.minStockLevel?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.AvailableStockQuantity" defaultMessage="Available Stock Quantity" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <Text style={{ cursor: "pointer" }} onClick={() => this.avaliableQuantityModal(record)} type="success">
              {record.availableStockQuantity}
            </Text>
          );
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.RemainingDays" defaultMessage="Remaining Days" />,
        key: "id",
        width: 100,
        render: record => {
          let remainingDays = Boolean(record.usageRateDay) && record.usageRateDay > 0 ? record.availableStockQuantity / record.usageRateDay : "0";
          return remainingDays?.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.EstimatedSupplyDay" defaultMessage="Estimated Supply Day" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.estimatedSupplyDayModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.EstimatedPrice" defaultMessage="Estimated Price" />,
        key: "id",
        width: 100,
        render: record => {
          return (
            <AntButton
              onClick={() => this.estimatedPriceModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.RequiredQuantity" defaultMessage="Required Quantity" />,
        key: "requiredQuantity",
        width: 100,
        render: record => {
          return record.requiredQuantity;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "requiredQuantity",
          title: <FormattedMessage id="PartRequirementList.RequiredQuantity" defaultMessage="Required Quantity" />,
          handleSave: this.handleRequiredQuantity
        })
      },
      {
        title: <FormattedMessage id="PartRequirementList.ReqirementDate" defaultMessage="Reqirement Date" />,
        key: "reqirementDate",
        width: 100,
        render: record => {
          return Boolean(record.reqirementDate) ? moment(record.reqirementDate).format("DD-MM-YYYY HH:mm") : "";
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "reqirementDate",
          title: <FormattedMessage id="PartRequirementList.ReqirementDate" defaultMessage="Reqirement Date" />,
          handleSave: this.handleReqirementDate
        })
      },
      {
        title: <FormattedMessage id="PartRequirementList.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        }
      }
    ];

    const columnsAvaliable = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.StockName" defaultMessage="Stock Name" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.Qty" defaultMessage="Qty" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.Reserve" defaultMessage="Reserved" />,
        key: "reserve",
        render: record => {
          return record.reserve;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.Released" defaultMessage="Released" />,
        key: "released",
        render: record => {
          return record.released;
        },
        width: 100
      }
    ];

    const columnsEstimatedSupplyDay = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.RequestDate" defaultMessage="Request Date" />,
        key: "requestDate",
        render: record => {
          return Boolean(record.requestDate) ? moment(record.requestDate).format("DD-MM-YYYY HH:mm") : "";
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.Qty" defaultMessage="Qty" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.ReceivingDate" defaultMessage="Receiving Date" />,
        key: "receivingDate",
        render: record => {
          return Boolean(record.receivingDate) ? moment(record.receivingDate).format("DD-MM-YYYY HH:mm") : "";
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.SupplyDays" defaultMessage="Supply Days" />,
        key: "supplyDays",
        render: record => {
          return record.supplyDays;
        },
        width: 100
      }
    ];

    const columnsEstimatedPrice = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="PartRequirementList.SupplyDate" defaultMessage="Supply Date" />,
        key: "supplyDate",
        render: record => {
          return Boolean(record.supplyDate) ? moment(record.supplyDate).format("DD-MM-YYYY HH:mm") : "";
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.Qty" defaultMessage="Qty" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          return record.unitPrice;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="PartRequirementList.TotalPrice" defaultMessage="Total Price" />,
        key: "totalPrice",
        render: record => {
          return record.totalPrice;
        },
        width: 100
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Part Requirement List" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={18}></Col>
              <Col md={3}>
                <Button
                  id="SendButton"
                  style={{ width: "100%" }}
                  variant="primary"
                  onClick={this.saveSendStatus}
                  disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                >
                  <FormattedMessage id="GeneralButtonSendSelected" defaultMessage="Send Selected" />
                </Button>
              </Col>
              <Col md={3}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                </Button>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <br />
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey={record => record.id}
              rowSelection={rowSelection}
              components={components}
              rowClassName={() => "editable-row"}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div>

        <DraggableModal
          title={<FormattedMessage id="PartRequirementList.AvailableQuantity" defaultMessage="Available Quantity" />}
          closable="false"
          open={this.state.modalAvaliableQuantityShow}
          onCancel={() => {
            this.setState({ modalAvaliableQuantityShow: false });
          }}
          okButtonProps={{ hidden: true }}
          width={800}
          centered
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsAvaliable}
                dataSource={this.state.avaliableQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PartRequirementList.EstimatedSupplyDay" defaultMessage="Estimated Supply Day" />}
          closable="false"
          open={this.state.modalEstimatedSupplyDayModalShow}
          onCancel={() => {
            this.setState({ modalEstimatedSupplyDayModalShow: false });
          }}
          okButtonProps={{ hidden: true }}
          width={800}
          centered
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsEstimatedSupplyDay}
                dataSource={this.state.estimatedSupplyDayList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PartRequirementList.EstimatedPrice" defaultMessage="Estimated Price" />}
          closable="false"
          open={this.state.modalEstimatedPriceShow}
          onCancel={() => {
            this.setState({ modalEstimatedPriceShow: false });
          }}
          okButtonProps={{ hidden: true }}
          width={800}
          centered
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsEstimatedPrice}
                dataSource={this.state.estimatedPriceList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PartRequirementList);
