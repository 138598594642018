import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tag } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";

export class ProposedProjectLogUpgrading extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProposedProjectLogUpgrading",
      controllerName: "proposedProjectLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      typeRevise: false,
      fileList: [],
      selectedRowKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    let fileUrl = [];
    let filePathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileUrl.push(obj);
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileUrl,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
      fileList: fileUrl
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.edit.bind(this, true)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(reviseAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    let pathList = [];
    if (Boolean(newItem.fileUrl))
      newItem.fileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.fileUrl = pathList.toString();

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (this.state.typeRevise) {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  upgrade = async () => {
    const { selectedRowKeys } = this.state;
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/upgrade", selectedRowKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
      this.setState({
        selectedRowKeys: []
      });
    } else {
      showWarning("Please select item.");
    }
  };

  getStatusColor = status => {
    if (Boolean(status)) {
      if (status === "Prelimanry") {
        return "processing";
      } else if (status === "Design") {
        return "warning";
      } else if (status === "Execution") {
        return "success";
      }
    }
    return "default";
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        if (record.type === "Execution") {
          return {
            props: {
              disabled: true
            }
          };
        }
        return originNode;
      }
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProposedProjectLog.ProjectName" defaultMessage="Project Name" />,
        key: "projectName",
        render: record => {
          return record.projectName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.ProjectType" defaultMessage="Project Type" />,
        key: "projectType",
        render: record => {
          return record.projectType;
        },
        ...getColumnFilter({
          inputName: "projectType",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.Score" defaultMessage="Score" />,
        key: "score",
        render: record => {
          return record.score;
        },
        ...getColumnFilter({
          inputName: "score",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.FacilityType" defaultMessage="Facility Type" />,
        key: "facilityType",
        render: record => {
          return record.facilityType;
        },
        ...getColumnFilter({
          inputName: "facilityType",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.Scope" defaultMessage="Scope" />,
        key: "scope",
        render: record => {
          return record.scope;
        },
        ...getColumnFilter({
          inputName: "scope",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.LocationAddress" defaultMessage="Location, Address" />,
        key: "locationAddress",
        render: record => {
          return record.locationAddress;
        },
        ...getColumnFilter({
          inputName: "locationAddress",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.EstimatedBudget" defaultMessage="Estimated Budget" />,
        key: "estimatedBudget",
        render: record => {
          return record.estimatedBudget;
        },
        ...getColumnFilter({
          inputName: "estimatedBudget",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.DurationOfProject" defaultMessage="Duration of Project" />,
        key: "durationOfProject",
        render: record => {
          return record.durationOfProject;
        },
        ...getColumnFilter({
          inputName: "durationOfProject",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.ProposedBy" defaultMessage="Proposed By" />,
        key: "proposedBy",
        render: record => {
          return record.proposedBy;
        },
        ...getColumnFilter({
          inputName: "proposedBy",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ProposedProjectLogUpgrading.Status" defaultMessage="Status" />,
        key: "type",
        render: record => {
          return record.type && <Tag color={this.getStatusColor(record.type)}>{record.type}</Tag>;
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <br></br>
          <Row gutter={[16, 16]}>
            <Col md={6}></Col>
            <Col md={3}>
              <AntButton style={{ border: "9px" }} type="primary" onClick={this.onClickCreateNew}>
                Preliminary
              </AntButton>
            </Col>
            <Col md={3}>
              <AntButton style={{ border: "9px" }} type="primary" onClick={this.onClickCreateNew}>
                Design
              </AntButton>
            </Col>
            <Col md={3}>
              <AntButton style={{ border: "9px" }} type="primary" onClick={this.onClickCreateNew}>
                Execution
              </AntButton>
            </Col>
            <Col md={9}></Col>
          </Row>
          <br></br>
          <Row gutter={[16, 16]}>
            <Col md={9}></Col>
            <Col md={6}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLogUpgrading.Project" defaultMessage="Project" />}
                  name="project"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </Col>
            <Col md={9}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={9}></Col>
            <Col md={6}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProposedProjectLogUpgrading.Score" defaultMessage="Score" />}
                  name="score"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Row gutter={[16, 16]}>
                    <Input style={{ width: "100%" }} /> <Input style={{ width: "100%" }} />
                  </Row>
                </Form.Item>
              }
            </Col>
            <Col md={9}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={22}></Col>
            <Col md={2}>
              <AntButton style={{ border: "9px" }} type="primary" onClick={this.upgrade} disabled={!hasSelected}>
                Upgrade
              </AntButton>
            </Col>
          </Row>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey={record => record.id}
            rowSelection={rowSelection}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProposedProjectLogUpgrading);
