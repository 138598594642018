import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { Button as AntButton, Button, Col, DatePicker, Input, InputNumber, Row, Select, Tooltip, Cascader } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showError, showSuccess } from "../MessageHelper";

const { Option } = Select;

export class ColumnFilterComponent extends Component {
  constructor() {
    super();
    this.state = {
      filterOperationsItems: [],
      filterType: "Match_All",
      searchCriteriaModels: [],
      disabled: false,
      ipIdSelectItems: []
    };
  }

  searchOperationInputs = {
    String: () => {
      return <Input style={{ width: "100%" }} value={this.state.value} onChange={e => this.setState({ value: e.target.value })} />;
    },
    Numeric: () => {
      return <InputNumber min={0} style={{ width: "100%" }} value={this.state.value} onChange={e => this.setState({ value: e })} />;
    },
    Date: () => {
      return (
        <DatePicker
          format="DD-MM-YYYY"
          value={this.state.valueDate}
          onChange={e => {
            this.setState({
              value: moment(e).format("DD-MM-YYYY"),
              valueDate: e
            });
          }}
        />
      );
    },
    TemporalDate: () => {
      return (
        <DatePicker
          style={{ width: "100%" }}
          value={this.state.valueDate}
          format="DD-MM-YYYY"
          onChange={e => {
            this.setState({
              value: moment(e).format("DD-MM-YYYY"),
              valueDate: e
            });
          }}
        />
      );
    },
    Bool: input => {
      return (
        <Select
          showSearch
          optionFilterProp="children"
          style={{ width: "100%" }}
          value={this.state.value}
          onChange={e => {
            this.setState({ value: e });
          }}
        >
          <Option value={true}>{Boolean(input.trueText) ? input.trueText : "Yes"}</Option>
          <Option value={false}>{Boolean(input.falseText) ? input.falseText : "No"}</Option>
        </Select>
      );
    },
    Select: () => {
      return (
        <Select
          showSearch
          optionFilterProp="children"
          style={{ width: "100%" }}
          value={this.state.value}
          onChange={e => {
            this.setState({ value: e });
          }}
        >
          {this.props.selectItems.map(i => (
            Boolean(i.key) && Boolean(i.value) ?
              <Option
                key={Boolean(this.props.input.propKey) ? i[this.props.input.propKey] : i.key}
                value={Boolean(this.props.input.propKey) ? i[this.props.input.propKey] : i.key}
              >
                {Boolean(this.props.input.propValue) ? i[this.props.input.propValue] : i.value}
              </Option> :
              <Option
                key={Boolean(this.props.input.propKey) ? i[this.props.input.propKey] : i.id}
                value={Boolean(this.props.input.propKey) ? i[this.props.input.propKey] : i.id}
              >
                {Boolean(this.props.input.propValue) ? i[this.props.input.propValue] : i.name}
              </Option>
          ))}
        </Select>
      );
    },
    Cascader: () => {
      return (
        <Cascader
          showSearch
          allowClear
          optionFilterProp="children"
          style={{ width: "100%" }}
          changeOnSelect
          onChange={e => {
            var value = null;
            if (Boolean(e) && e.length > 0) {
              value = e[e.length - 1];
            }
            this.setState({ value: value });
          }}
          options={this.props.selectItems}
        />
      );
    }
  };

  searchOperationInput = {
    TEXT: this.searchOperationInputs.String,
    MAIL: this.searchOperationInputs.String,
    PHONE: this.searchOperationInputs.String,
    PASSWORD: this.searchOperationInputs.String,
    NUMBER: this.searchOperationInputs.Numeric,
    TEXTAREA: this.searchOperationInputs.String,
    TEXT_EDITOR: this.searchOperationInputs.String,
    SELECT: this.searchOperationInputs.Select,
    SELECT_MULTIPLE: this.searchOperationInputs.Select,
    SELECT_ADDABLE: this.searchOperationInputs.Select,
    SELECT_MULTIPLE_ADDABLE: this.searchOperationInputs.Select,
    CHECKBOX: this.searchOperationInputs.Bool,
    RADIO: this.searchOperationInputs.Select,
    DATEPICKER: this.searchOperationInputs.Date,
    DATEPICKER_WITH_TIME: this.searchOperationInputs.Date,
    TIMEPICKER: this.searchOperationInputs.TemporalDate,
    MONTHPICKER: this.searchOperationInputs.TemporalDate,
    YEARPICKER: this.searchOperationInputs.Numeric,
    RANGEPICKER: null,
    RANGEPICKER_WITH_TIME: null,
    CASCADER: this.searchOperationInputs.Cascader,
    FILEUPLOAD: null,
    FILEUPLOAD_IMAGE: null,
    TREE_SELECT: null
  };

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    var disabled = false;
    var filterType = "Match_All";
    if (Boolean(this.props.searchCriteriaModels) && this.props.searchCriteriaModels.length > 0) {
      var searchCriteriaModels = this.props.searchCriteriaModels.find(p => p.key === this.props.input.inputName);
      if (Boolean(searchCriteriaModels)) {
        disabled = true;
        filterType = searchCriteriaModels.type;
      }
    }

    this.setState(
      {
        filterType: filterType,
        searchCriteriaModels: this.props.searchCriteriaModels,
        value: "",
        valueDate: null,
        disabled: disabled
      },
      () => {
        this.getFilterOperations();
      }
    );
  };

  getFilterOperations = async () => {
    if (Boolean(this.props.filterOperations) && this.props.filterOperations.length > 0) {
      this.setState({
        filterOperationsItems: this.props.filterOperations,
        filterOperations: this.props.filterOperations[0].key
      });
    } else {
      this.setState({
        filterOperationsItems: [],
        filterOperations: ""
      });
    }
  };

  search = () => {
    var searchCriteriaModels = this.state.searchCriteriaModels.filter(p => p.key === this.props.input.inputName);
    if (this.state.value !== null && this.state.value !== undefined && this.state.value !== "") {
      var operationValue = this.state.filterOperationsItems.find(p => p.key === this.state.filterOperations);
      let value = this.state.value;
      let showValue =
        this.props.input.inputType === "CHECKBOX"
          ? value === true
            ? "Yes"
            : "No"
          : (this.searchOperationInput[this.props.input.inputType].name === "Select" ||
            this.searchOperationInput[this.props.input.inputType].name === "Cascader")
            ? Boolean(this.props.selectItems.find(p => p.key === value))
              ? this.props.selectItems.find(p => p.key === value).value
              : Boolean(this.props.selectItems.find(p => p.id === value))
                ? this.props.selectItems.find(p => p.id === value).name : ""
            : value;
      searchCriteriaModels.push({
        label: this.props.input.label,
        showValue: showValue,
        key: this.props.input.inputName,
        value: this.state.value,
        operation: this.state.filterOperations,
        operationValue: Boolean(operationValue) ? operationValue.value : "",
        type: this.state.filterType
      });
    }
    var other = this.state.searchCriteriaModels.filter(p => p.key !== this.props.input.inputName);
    if (Boolean(other) && other.length > 0) {
      other.forEach(element => {
        searchCriteriaModels.push(element);
      });
    }
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        value: "",
        valueDate: null,
        disabled: true
      },
      () => {
        this.props.filterChangeItem(this.state.searchCriteriaModels);
      }
    );
  };

  clear = () => {
    var searchCriteriaModels = this.state.searchCriteriaModels.filter(p => p.key !== this.props.input.inputName);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        value: "",
        valueDate: null,
        disabled: false
      },
      () => {
        this.props.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  addRules = (value, operation, type) => {
    if (Boolean(this.props.input.inputName) && value !== null && value !== undefined && Boolean(operation) && Boolean(type)) {
      var operationValue = this.state.filterOperationsItems.find(p => p.key === operation);
      var searchCriteriaModels = this.state.searchCriteriaModels;
      let showValue =
        this.props.input.inputType === "CHECKBOX"
          ? value === true
            ? "Yes"
            : "No"
          : (this.searchOperationInput[this.props.input.inputType].name === "Select" ||
            this.searchOperationInput[this.props.input.inputType].name === "Cascader")
            ? Boolean(this.props.selectItems.find(p => p.key === value))
              ? this.props.selectItems.find(p => p.key === value).value
              : ""
            : value;
      searchCriteriaModels.push({
        label: this.props.input.label,
        showValue: showValue,
        key: this.props.input.inputName,
        value: value,
        operation: operation,
        operationValue: Boolean(operationValue) ? operationValue.value : "",
        type: type
      });
      this.setState({
        searchCriteriaModels: searchCriteriaModels,
        value: "",
        valueDate: null,
        disabled: true
      });
    } else {
      this.showError("Please enter the filter criteria.");
    }
  };

  removeRules = (value, operation) => {
    if (Boolean(this.props.input.inputName) && Boolean(value) && Boolean(operation)) {
      var attr = this.state.searchCriteriaModels.findIndex(
        p => p.key === this.props.input.inputName && p.value === value && p.operation === operation
      );
      if (attr >= 0) this.state.searchCriteriaModels.splice(attr, 1);

      var disabled = false;
      if (Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0) {
        var searchCriteriaModels = this.state.searchCriteriaModels.find(p => p.key === this.props.input.inputName);
        if (Boolean(searchCriteriaModels)) {
          disabled = true;
        }
      }

      this.setState({
        searchCriteriaModels: this.state.searchCriteriaModels,
        value: "",
        valueDate: null,
        disabled: disabled
      });
    } else {
      this.showError();
    }
  };

  error = response => {
    try {
      if (Boolean(response.response) && response.response.status === 403) document.getElementById("logout").click();
      else this.showError(Boolean(response.response) && Boolean(response.response.data) ? response.response.data.message : "");
    } catch (error) { }
  };

  showSuccess(msg) {
    if (Boolean(msg) && msg.length > 0) showSuccess(msg);
    else showSuccess("Success");
  }

  showError(msg) {
    if (Boolean(msg) && msg.length > 0) showError(msg);
    else showError("An error has occured");
  }

  render() {
    return (
      <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%", minWidth: "250px" }}>
        <Link id="logout" to="/logout" hidden></Link>

        <Select
          showSearch
          optionFilterProp="children"
          disabled={this.state.disabled}
          style={{ width: "100%", marginBottom: "1rem" }}
          value={this.state.filterType}
          onChange={value => this.setState({ filterType: value })}
        >
          <Option value={"Match_All"}>Match All</Option>
          <Option value={"Match_Any"}>Match Any</Option>
        </Select>
        <Select
          showSearch
          optionFilterProp="children"
          value={this.state.filterOperations}
          style={{ width: "100%", marginBottom: "1rem" }}
          onChange={value => this.setState({ filterOperations: value })}
        >
          {this.state.filterOperationsItems.map(i => (
            <Option key={i.key} value={i.key}>
              {i.value}
            </Option>
          ))}
        </Select>
        {Boolean(this.searchOperationInput[this.props.input.inputType]) && this.searchOperationInput[this.props.input.inputType](this.props.input)}

        <Row justify="center">
          <Col>
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => this.addRules(this.state.value, this.state.filterOperations, this.state.filterType)}
            >
              Add Rule
            </Button>
          </Col>
        </Row>
        {this.state.searchCriteriaModels
          .filter(p => p.key === this.props.input.inputName)
          .map((searchCriteria, index) => (
            <div key={index} className="alert alert-light alert-custom mb-5" role="alert">
              <div className="alert-text">
                <strong className="mr-2">{searchCriteria.type}</strong>
                <span className="mr-2">{searchCriteria.operationValue}</span>
                <span>{searchCriteria.showValue}</span>
              </div>
              <div className="alert-close">
                <Tooltip title="Remove">
                  <MinusCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() => this.removeRules(searchCriteria.value, searchCriteria.operation)}
                  />
                </Tooltip>
              </div>
            </div>
          ))}

        <Row>
          <Col span={6}> </Col>
          <Col span={8}>
            <Tooltip title="Clear">
              <AntButton style={{ border: "0px" }} onClick={() => this.clear()} icon={<CloseIcon fontSize="large" color="error" />}></AntButton>
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip title="Search">
              <AntButton style={{ border: "0px" }} onClick={() => this.search()} icon={<CheckIcon fontSize="large" color="primary" />}></AntButton>
            </Tooltip>
          </Col>
          <Col span={4}> </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnFilterComponent);
