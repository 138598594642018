import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { Form, Col, Row, Select, Input, Button as AntButton, Tree, Avatar, Image, Space, Radio, Tooltip } from "antd";
import { error, showError, showSuccess, showWarning } from "../../../../../../app/MessageHelper";
import { handleRequest } from "../../../../../../app/ApiConnector";
import DraggableModal from "../../../../../../app/Components/DraggableModal";
import { fillFilterOperations } from "../../../../../../app/Components/DataTableFilterComponent";
import { Add, Archive, ArrowDownward, Bookmark, BookmarkOutlined, Cached, Delete, EditOutlined, SearchOutlined, ShortText, Star, StarOutlined } from "@material-ui/icons";
import { DownloadOutlined, MessageOutlined } from "@ant-design/icons";
import moment from "moment";
import ReplyToUser from "./ReplyToUser";
import DOMPurify from "dompurify";
import Reject from "./Reject";
import ReplyIcon from "@material-ui/icons/Reply";
import ForwardIcon from "@material-ui/icons/Forward10Outlined";

const { Option } = Select;

export class Sent extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Sent",
      controllerName: "ticketMessage",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingSent";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true,
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true,
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    let list = [];
    if (Boolean(values) && Boolean(values.mails) && values.mails.length > 0) {
      values.mails.forEach(element => {
        element.pageName = this.props.pageName;

        let notificationTos = [];
        if (Boolean(element.notificationTos) && element.notificationTos.length > 0) {
          element.notificationTos.forEach(to => {
            console.log("to", to);
            if (Boolean(to.id) && to.id > 0) {
              notificationTos.push({ id: to.id, userId: to.userId, departmentId: to.departmentId });
            } else {
              let type = to.split("~")[0];
              let value = to.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationTos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationTos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationTos = notificationTos;

        let notificationInfos = [];
        if (Boolean(element.notificationInfos) && element.notificationInfos.length > 0) {
          element.notificationInfos.forEach(info => {
            if (Boolean(info.id) && info.id > 0) {
              notificationInfos.push({ id: info.id, userId: info.userId, departmentId: info.departmentId });
            } else {
              let type = info.split("~")[0];
              let value = info.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationInfos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationInfos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationInfos = notificationInfos;

        list.push(element);
      });
    }
    console.log("list", list);

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveEldenInbox", list);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  showReport = async rowData => {
    try {
      if (rowData.filePath !== null && rowData.filePath !== undefined && rowData.filePath !== "") {
        const fileRealUrl = rowData.filePath;

        if (
          fileRealUrl.endsWith(".png") ||
          fileRealUrl.endsWith(".jpg") ||
          fileRealUrl.endsWith(".tiff") ||
          fileRealUrl.endsWith(".jpeg") ||
          fileRealUrl.endsWith(".PNG") ||
          fileRealUrl.endsWith(".JPG") ||
          fileRealUrl.endsWith(".TIFF") ||
          fileRealUrl.endsWith(".JPEG")
        ) {
          this.setState({
            imageUrl: fileRealUrl,
            modalPhotoShow: true
          });
        } else {
          window.location.href = fileRealUrl;
        }
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  handleClick = async (item) => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getMessage/" + item.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalMessageShow: true,
        messageData: response.data.message,
      });
    }
  };

  render() {
    const { tableList } = this.state;
    console.log("tableList", tableList);
    const renderTableRows = () => {
      return tableList.map((item, index) => (
        // <tr key={item.id} onClick={() => this.handleReplyClick(item)}>
        <tr key={item.id} onClick={() => this.handleClick(item)}>
          <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              {/* <input className='form-check-input widget-9-check' type='checkbox' value={item.id} /> */}
              <Radio
                value={item.checked}
                checked={item.checked}
                defaultChecked={item.checked}
                onChange={(e) => {
                  console.log("e", e);

                  // Mevcut seçimleri güncelle
                  const updatedList = tableList.map((element) => ({
                    ...element,
                    checked: element.id === item.id ? e.target.checked : false,
                  }));

                  let ticketMessageId = e.target.checked ? item.id : null;

                  // Yeni durumu ayarla
                  this.setState({
                    ticketMessageId: ticketMessageId,
                    tableList: updatedList,
                  });
                }}
              />
            </div>
          </td>
          {/* <td>
            <div className='d-flex'>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Star />
              </a>
              <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <Bookmark />
              </a>
            </div>
          </td> */}
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{index + 1}</span>
          </td>
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.ticketId}</span>
          </td>
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {item.companyBranche}
            </span>
          </td>
          <td>
            <div className='d-flex align-items-center'>
              <Space>
                {Boolean(item.photoPath) &&
                  <Image width={50} src={item.photoPath} alt={item.messageToUserName} />}
                <div className='d-flex flex-column'>
                  <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
                    {item.messageToUserName}
                  </a>
                </div>
              </Space>
            </div>
          </td>
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.requestTypeName}</span>
          </td>
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.priority}</span>
          </td>
          <td>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.subject}</span>
          </td>
          <td>
            <MessageOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />
          </td>
          <td>
            {
              !Boolean(item.filePath) ?
                ""
                :
                <>
                  {(
                    item.filePath.endsWith(".png") ||
                    item.filePath.endsWith(".jpg") ||
                    item.filePath.endsWith(".tiff") ||
                    item.filePath.endsWith(".jpeg") ||
                    item.filePath.endsWith(".PNG") ||
                    item.filePath.endsWith(".JPG") ||
                    item.filePath.endsWith(".TIFF") ||
                    item.filePath.endsWith(".JPEG")
                  ) ?
                    <Image width={80} src={item.filePath} />
                    :
                    <AntButton
                      style={{
                        marginLeft: "2px",
                        marginTop: "-2px",
                        alignContent: "center"
                      }}
                      onClick={() => this.showReport(item)}
                      type="primary"
                      shape="round"
                      icon={<DownloadOutlined />}
                    ></AntButton>}
                </>
            }
          </td>
          <td className='text-end'>
            <div className='d-flex flex-column'>
              <span className='text-muted fs-7 fw-semibold' style={{ lineHeight: '1.5rem', fontSize: '15px' }}>
                {Boolean(item.lastUpdated) ? moment(item.lastUpdated).format("DD-MM-YYYY HH:mm") : ""}
              </span>
            </div>
          </td>
        </tr >
      ));
    };

    return (
      <>
        <div>
          {/* begin::Header */}
          <div className='card-body py-1'>
            <div className='table-responsive'>
              <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a user'>
                <div className="d-flex justify-content-between align-items-center" >
                  <div className="d-flex">
                    <Tooltip title={<FormattedMessage id="Sent.Reload" defaultMessage="Reload" />}>
                      <div onClick={() => console.log("Reload clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <Cached style={{ fontSize: '16px' }} />
                      </div>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="Sent.Reply" defaultMessage="Reply" />}>
                      <div onClick={() => {
                        const { ticketMessageId } = this.state;
                        if (Boolean(ticketMessageId) && ticketMessageId > 0) {
                          this.setState({
                            modalReplyToUserShow: true,
                            random: Math.random()
                          });
                        } else {
                          showWarning("Please select ticket.");
                        }
                      }} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <ReplyIcon style={{ fontSize: '16px' }} />
                      </div>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="Sent.Accept" defaultMessage="Accept" />}>
                      <div onClick={() => console.log("Arrow Down clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <ArrowDownward style={{ fontSize: '16px' }} />
                      </div>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="Sent.Reject" defaultMessage="Reject" />}>
                      <div onClick={() => {
                        const { ticketMessageId } = this.state;
                        if (Boolean(ticketMessageId) && ticketMessageId > 0) {
                          this.setState({
                            modalRejectShow: true,
                            random: Math.random()
                          });
                        } else {
                          showWarning("Please select ticket.");
                        }
                      }} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <Delete style={{ fontSize: '16px' }} />
                      </div>
                    </Tooltip>

                    <Tooltip title={<FormattedMessage id="Sent.Forward" defaultMessage="Forward" />}>
                      <div onClick={() => console.log("Short Text clicked")} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <ForwardIcon style={{ fontSize: '16px' }} />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="ms-3" style={{ transition: 'background-color 0.3s' }}>
                    <Input
                      placeholder="Search"
                      prefix={<SearchOutlined style={{ color: '#a0a5b1' }} />}
                      style={{ backgroundColor: '#f8f9fa', border: '1px solid #ced4da' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-25px'>
                    </th>
                    <th className='min-w-30px'> <FormattedMessage id="General.TitleSN" defaultMessage="S/N" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.TicketNo" defaultMessage="Ticket No" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.CompanyBranche" defaultMessage="Company-Branche" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.User" defaultMessage="User" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.RequestType" defaultMessage="Request Type" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.Priority" defaultMessage="Priority" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.Subject" defaultMessage="Subject" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.Message" defaultMessage="Message" /></th>
                    <th className='min-w-80px'><FormattedMessage id="Sent.Attachment" defaultMessage="Attachment" /></th>
                    {/* <th className='min-w-80px'><FormattedMessage id="Sent.Status" defaultMessage="Status" /></th> */}
                    <th className='min-w-80px text-end'><FormattedMessage id="Sent.Updates" defaultMessage="Updates" /></th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
          </div>

          {/* begin::Body */}
        </div >

        <DraggableModal
          title={<FormattedMessage id="Sent.Reply" defaultMessage="Reply" />}
          open={this.state.showReply}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showReply: false })}
          width={1300}
          centered
          content={
            <Form
              initialValues={{ remember: false }}
            >
              {/* <EldenInboxReply /> */}
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ModalPhotoText" defaultMessage="Photo" />}
          open={this.state.modalPhotoShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPhotoShow: false })}
          width={800}
          centered
          content={
            this.state.imageUrl !== "" ? (
              <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              <p>
                {" "}
                <FormattedMessage id="UploadedPhotoMessage1" defaultMessage="Photo has not been uploaded." />
              </p>
            )
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Sent.ReplyToUser" defaultMessage="Reply To User" />}
          open={this.state.modalReplyToUserShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalReplyToUserShow: false })}
          width={1200}
          centered
          content={
            <ReplyToUser ticketMessageId={this.state.ticketMessageId} random={this.state.random}
              close={() => {
                this.setState({ modalReplyToUserShow: false });
              }} />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Sent.ReplyToUser" defaultMessage="Reject" />}
          open={this.state.modalRejectShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalRejectShow: false })}
          width={1200}
          centered
          content={
            <Reject ticketMessageId={this.state.ticketMessageId} random={this.state.random}
              close={() => {
                this.setState({ modalRejectShow: false });
              }} />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Sent.Message" defaultMessage="Message" />}
          open={this.state.modalMessageShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalMessageShow: false })}
          width={800}
          centered
          content={
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                lineClamp: "2",
                WebkitLineClamp: "2"
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.messageData) }}
            ></div>
          }
        />
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Sent);