import { Button, Drawer, Modal, notification, Select } from "antd";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { reducer } from "../../../../../app/modules/Auth/_redux/authRedux";
import SockJS from "sockjs-client";
import { API_BASE_ROOT, handleRequest } from "../../../../../app/ApiConnector";
import * as Stomp from "stompjs";
import store from "../../../../../redux/store";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { error, showSuccess } from "../../../../../app/MessageHelper";
// import "../../../../../style.bundle.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InboxDrawer } from "../../../../../app/pages/elden/accounting/Inbox/InboxDrawer";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

const { Option } = Select;

let timeout = 3000;
let timeoutCounter = 1;
const maxTimeoutCounter = 5;


const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "code"],
        ["clean"]
    ]
};

const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "code"];

export default function InboxDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [isComposeOpen, setIsComposeOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [personnels, setPersonnels] = useState([]);
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas: objectPath.get(uiService.config, "extras.inbox.layout") === "offcanvas"
        };
    }, [uiService]);

    const fillPersonnels = async () => {
        const response = await handleRequest("GET", "/api/personnelLog");
        if (Boolean(response.data)) {
            setPersonnels(response.data);
        }
    };

    const fillMessages = async () => {
        const response = await handleRequest("GET", "/api/inbox");
        if (Boolean(response.data)) {
            setMessages(response.data);
        }
    };

    const openChatNotification = e => {
        const key = e.id;

        const desc = (
            <>
                <p>{e.message}</p>
                <div className="navi-text">
                    <div className="text-muted"> {moment(e.activationTime).format("DD/MM/YYYY HH:mm")}</div>
                </div>
            </>
        );

        notification["info"]({
            message: e.title,
            description: desc,
            duration: 0,
            key
        });
    };

    const connect = () => {
        const token = reducer.authToken;
        const ws = new SockJS(`${API_BASE_ROOT}/socket`); //{`${API_BASE}/Staff/download/photo/`+row.id }
        const stompClient = Stomp.over(ws);

        stompClient.connect(
            { Authorization: "Bearer " + token },
            function (frame) {
                stompClient.subscribe("/user/" + store.getState().auth.user.id + "/", message => {
                    console.log(message.body);

                    // that.fillAlerts();
                    //that.getNotifications();
                    var data = JSON.parse(message.body);
                    openChatNotification(data);
                });
            },
            error => {
                timeoutCounter++;
                console.log("Trying to connect to websocket...");
                if (timeoutCounter < maxTimeoutCounter) {
                    setTimeout(connect, timeoutCounter * timeout);
                }
            }
        );
    };

    useEffect(() => {
        connect();
        fillMessages();
        fillPersonnels();
    }, []);

    const sendMessage = async () => {
        const newItem = {
            message: message,
            subject: subject,
            personnelId: store.getState().auth.user.id,
            remotePersonnelId: store.getState().auth.user.id,
            messageTime: moment().toDate()
        }

        var response = await handleRequest("POST", "/api/inbox", newItem);

        if (response.type === "ERROR") {
            error(response);
        } else {
            fillMessages();
            showSuccess();
        }
    };

    const onMessageChange = e => {
        // console.log(e.target.value);
        setMessage(e.target.value);
    }

    const onSubjectChange = e => {
        console.log(e.target.value);
        setSubject(e.target.value);
    }

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className={`btn btn-icon btn-clean btn-lg mr-1 ${Boolean(messages) && messages.length > 0 ? "pulse pulse-danger" : ""}`}
                        id="kt_notes_toggle">
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Inbox.svg")} />
                        </span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <div className="topbar-item">
                    <OverlayTrigger placement="bottom"
                        overlay={<Tooltip id="user-notification-tooltip">Inbox</Tooltip>}>
                        <Button
                            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                            icon={
                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Inbox.svg")} />
                                </span>
                            }
                            onClick={() => setIsOpen(true)}
                        ></Button>
                    </OverlayTrigger>
                </div>
            )}

            {<Drawer
                title={<FormattedMessage id="InboxDropdown.Inbox" defaultMessage="Inbox" />}
                width={1200} closable={true}
                onClose={() => setIsOpen(false)} open={isOpen}>
                <InboxDrawer />
            </Drawer>}

            {/* <Modal
                centered
                maskClosable={false}
                title={<FormattedMessage id="Menu.inbox" defaultMessage="My Inbox" />}
                visible={isOpen}
                onOk={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                cancelButtonProps={{ style: { display: "none" } }}
                width={1300}
            >
                <div className="d-flex flex-column flex-lg-row">
                    <div className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px"
                        data-kt-drawer="true" data-kt-drawer-name="inbox-aside"
                        data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
                        data-kt-drawer-width="225px" data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_inbox_aside_toggle">
                        <div className="card card-flush mb-0" data-kt-sticky="true"
                            data-kt-sticky-name="inbox-aside-sticky"
                            data-kt-sticky-offset="{default: false, xl: '100px'}"
                            data-kt-sticky-width="{lg: '275px'}"
                            data-kt-sticky-left="auto" data-kt-sticky-top="100px" data-kt-sticky-animation="false"
                            data-kt-sticky-zindex="95">
                            <div className="card-body">
                                <a onClick={() => setIsComposeOpen(true)} href="#" data-bs-toggle="modal" data-bs-target="#modal_compose"
                                    className="btn btn-primary fw-bold w-100 mb-8">New Message</a>
                                <div
                                    className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                                    <div className="menu-item mb-3">
                                        <span className="menu-link active">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen010.svg")} />
                                                </span>
                                            </span>
                                            <span className="menu-title fw-bold">Inbox</span>
                                            <span className="badge badge-light-success">3</span>
                                        </span>
                                    </div>
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen024.svg")} />
                                                </span>
                                            </span>
                                            <span className="menu-title fw-bold">Marked</span>
                                        </span>
                                    </div>
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen009.svg")} />
                                                </span>
                                            </span>
                                            <span className="menu-title fw-bold">Draft</span>
                                            <span className="badge badge-light-warning">5</span>
                                        </span>
                                    </div>
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen016.svg")} />
                                                </span>
                                            </span>
                                            <span className="menu-title fw-bold">Sent</span>
                                        </span>
                                    </div>
                                    <div className="menu-item">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen027.svg")} />
                                                </span>
                                            </span>
                                            <span className="menu-title fw-bold">Trash</span>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary">
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span
                                                    className="svg-icon svg-icon-6 svg-icon-danger me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/abstract/abs009.svg")} />
                                                </span>
                                            </span>
                                            <span
                                                className="menu-title fw-semibold">Custom Work</span>
                                            <span className="badge badge-light-danger">6</span>
                                        </span>
                                    </div>
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span
                                                    className="svg-icon svg-icon-6 svg-icon-success me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/abstract/abs009.svg")} />
                                                </span>
                                            </span>
                                            <span
                                                className="menu-title fw-semibold">Partnership</span>
                                        </span>
                                    </div>
                                    <div className="menu-item mb-3">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span
                                                    className="svg-icon svg-icon-6 svg-icon-info me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/abstract/abs009.svg")} />
                                                </span>
                                            </span>
                                            <span
                                                className="menu-title fw-semibold">In Progress</span>
                                        </span>
                                    </div>
                                    <div className="menu-item">
                                        <span className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2 me-3">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/abstract/abs009.svg")} />
                                                </span>
                                            </span>
                                            <span
                                                className="menu-title fw-semibold">Add Label</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                        <div className="card">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="d-flex flex-wrap gap-2">
                                    <div
                                        className="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7">
                                        <input className="form-check-input" type="checkbox" data-kt-check="true"
                                            data-kt-check-target="#kt_inbox_listing .form-check-input"
                                            value="1" />
                                    </div>
                                    <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                        data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top"
                                        title="Reload">
                                        <span className="svg-icon svg-icon-2">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/arrows/arr029.svg")} />
                                        </span>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                        data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top"
                                        title="Archive">
                                        <span className="svg-icon svg-icon-2">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/communication/com010.svg")} />
                                        </span>
                                    </a>
                                    <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                        data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top"
                                        title="Delete">
                                        <span className="svg-icon svg-icon-2">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/gen027.svg")} />
                                        </span>
                                    </a>
                                    <div>
                                        <Select
                                            allowClear
                                            onChange={value => {
                                                this.onChangeItemGroupId(value);
                                            }}
                                        >
                                            <Option key="0" value="0">
                                                All
                                            </Option>
                                            <Option key="1" value="1">
                                                Read
                                            </Option>
                                            <Option key="2" value="2">
                                                Unread
                                            </Option>
                                            <Option key="3" value="3">
                                                Starred
                                            </Option>
                                            <Option key="4" value="4">
                                                Unstarred
                                            </Option>
                                        </Select>
                                    </div>
                                    <span>
                                        <a href="#"
                                            className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                            data-bs-toggle="tooltip" data-bs-dismiss="click"
                                            data-bs-placement="top" title="Sort">
                                            <span className="svg-icon svg-icon-3 m-0">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Inbox/gen052.svg")} />
                                            </span>
                                        </a>
                                        <div
                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                            data-kt-menu="true">

                                            <Select
                                                allowClear
                                                placeholder="..."
                                                onChange={value => {
                                                    this.onChangeItemGroupId(value);
                                                }}
                                            >
                                                <Option key="0" value="0">
                                                    Newest
                                                </Option>
                                                <Option key="1" value="1">
                                                    Oldest
                                                </Option>
                                                <Option key="2" value="2">
                                                    Unread
                                                </Option>
                                            </Select>
                                        </div>
                                    </span>
                                </div>
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="d-flex align-items-center position-relative">
                                        <span className="svg-icon svg-icon-2 position-absolute ms-4">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/gen021.svg")} />
                                        </span>
                                        <input type="text" data-kt-inbox-listing-filter="search"
                                            className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-12"
                                            placeholder="Search Inbox" />
                                    </div>
                                    <a href="#"
                                        className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary"
                                        data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top"
                                        title="Toggle inbox menu" id="kt_inbox_aside_toggle">
                                        <span className="svg-icon svg-icon-3 m-0">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/gen059.svg")} />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-hover table-row-dashed fs-6 gy-5 my-0"
                                    id="kt_inbox_listing">
                                    <thead className="d-none">
                                        <tr>
                                            <th>Checkbox</th>
                                            <th>Actions</th>
                                            <th>Author</th>
                                            <th>Title</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Boolean(messages) &&
                                            messages.map((field, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="ps-9">
                                                            <div
                                                                className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                                                <input className="form-check-input" type="checkbox" value="1" />
                                                            </div>
                                                        </td>
                                                        <td className="min-w-80px">
                                                            <a href="#"
                                                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                                                data-bs-toggle="tooltip" data-bs-placement="right" title="Star">
                                                                <span className="svg-icon svg-icon-3">
                                                                    <SVG
                                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen029.svg")} />
                                                                </span>
                                                            </a>
                                                            <a href="#"
                                                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                                                data-bs-toggle="tooltip" data-bs-placement="right"
                                                                title="Mark as important">
                                                                <span className="svg-icon svg-icon-4 mt-1">
                                                                    <SVG
                                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen056.svg")} />
                                                                </span>
                                                            </a>
                                                        </td>
                                                        <td className="w-150px w-md-175px">
                                                            <a href="../../demo1/dist/apps/inbox/reply.html"
                                                                className="d-flex align-items-center text-dark">
                                                                <div className="symbol symbol-35px me-3">
                                                                    <div className="symbol-label bg-light-danger">
                                                                        <span className="text-danger">M</span>
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="fw-semibold">{field.localPersonnelUser.name + " " + field.localPersonnelUser.lastName}</span>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <div className="text-dark mb-1">
                                                                <a href="../../demo1/dist/apps/inbox/reply.html"
                                                                    className="text-dark">
                                                                    <span
                                                                        className="fw-bold">Digital PPV Customer Confirmation</span>
                                                                    <span className="fw-bold d-none d-md-inine">-</span>
                                                                    <span className="d-none d-md-inine text-muted">Thank you for ordering UFC 240 Holloway vs Edgar Alternate camera angles...</span>
                                                                </a>
                                                            </div>
                                                            <div className="badge badge-light-primary">inbox</div>
                                                            <div className="badge badge-light-warning">task</div>
                                                        </td>
                                                        <td className="w-100px text-end fs-7 pe-9">
                                                            <span className="fw-semibold">8:30 PM</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                maskClosable={false}
                title={<FormattedMessage id="Menu.compose" defaultMessage="Compose" />}
                visible={isComposeOpen}
                onOk={() => setIsComposeOpen(false)}
                onCancel={() => setIsComposeOpen(false)}
                cancelButtonProps={{ style: { display: "none" } }}
                width={1100}
            >
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="flex-lg-row-fluid ms-lg-12 ms-xl-12">
                            <div className="card">
                                <div className="card-header align-items-center">
                                    <div className="card-title">
                                        <h2>Compose Message</h2>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <form id="kt_inbox_compose_form">
                                        <div className="d-block">
                                            <div
                                                className="d-flex align-items-center border-bottom px-8 min-h-50px">
                                                <div className="text-dark fw-bold w-75px">To:</div>
                                                <input type="text"
                                                    className="form-control form-control-transparent border-0"
                                                    name="compose_to" value="" data-kt-inbox-form="tagify" />
                                                <div className="ms-auto w-75px text-end">
                                                    <span
                                                        className="text-muted fs-bold cursor-pointer text-hover-primary me-2"
                                                        data-kt-inbox-form="cc_button">Cc</span>
                                                    <span
                                                        className="text-muted fs-bold cursor-pointer text-hover-primary"
                                                        data-kt-inbox-form="bcc_button">Bcc</span>
                                                </div>
                                            </div>
                                            <div
                                                className="d-none align-items-center border-bottom ps-8 pe-5 min-h-50px"
                                                data-kt-inbox-form="cc">
                                                <div className="text-dark fw-bold w-75px">Cc:</div>
                                                <input type="text"
                                                    className="form-control form-control-transparent border-0"
                                                    name="compose_cc" value="" data-kt-inbox-form="tagify" />
                                                <span className="btn btn-clean btn-xs btn-icon"
                                                    data-kt-inbox-form="cc_close">
                                                    <i className="la la-close"></i>
                                                </span>
                                            </div>
                                            <div
                                                className="d-none align-items-center border-bottom inbox-to-bcc ps-8 pe-5 min-h-50px"
                                                data-kt-inbox-form="bcc">
                                                <div className="text-dark fw-bold w-75px">Bcc:</div>
                                                <input type="text"
                                                    className="form-control form-control-transparent border-0"
                                                    name="compose_bcc" value="" data-kt-inbox-form="tagify" />
                                                <span className="btn btn-clean btn-xs btn-icon"
                                                    data-kt-inbox-form="bcc_close">
                                                    <i className="la la-close"></i>
                                                </span>
                                            </div>
                                            <div className="border-bottom">
                                                <input
                                                    className="form-control form-control-transparent border-0 px-8 min-h-45px"
                                                    name="compose_subject" id="compose_subject" onChange={onSubjectChange} placeholder="Subject" />
                                            </div>
                                            <div id="kt_inbox_form_editor"
                                                className="bg-transparent border-0 h-350px px-3">
                                                <ReactQuill className="bg-transparent border-0 h-300px px-3" theme="snow" onChange={onMessageChange} modules={modules} formats={formats} placeholder="Type your text here..." /></div>
                                            <div className="dropzone dropzone-queue px-8 py-4"
                                                id="kt_inbox_reply_attachments" data-kt-inbox-form="dropzone">
                                                <div className="dropzone-items">
                                                    <div className="dropzone-item" style={{ display: "none" }}>
                                                        <div className="dropzone-file">
                                                            <div className="dropzone-filename"
                                                                title="some_image_file_name.jpg">
                                                                <span
                                                                    data-dz-name="">some_image_file_name.jpg</span>
                                                                <strong>(
                                                                    <span data-dz-size="">340kb</span>)</strong>
                                                            </div>
                                                            <div className="dropzone-error"
                                                                data-dz-errormessage=""></div>
                                                        </div>
                                                        <div className="dropzone-progress">
                                                            <div className="progress">
                                                                <div className="progress-bar bg-primary"
                                                                    role="progressbar" aria-valuemin="0"
                                                                    aria-valuemax="100" aria-valuenow="0"
                                                                    data-dz-uploadprogress=""></div>
                                                            </div>
                                                        </div>
                                                        <div className="dropzone-toolbar">
                                                            <span className="dropzone-delete"
                                                                data-dz-remove="">
                                                                <i className="bi bi-x fs-1"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
                                            <div className="d-flex align-items-center me-3">
                                                <div className="btn-group me-4">
                                                    <span className="btn btn-primary fs-bold px-6"
                                                        data-kt-inbox-form="send" onClick={sendMessage}>
                                                        <span className="indicator-label">Send</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </span>
                                                    <span className="btn btn-primary btn-icon fs-bold"
                                                        role="button">
                                                        <span className="btn btn-icon"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-placement="top-start">
                                                            <SVG
                                                                src={toAbsoluteUrl("/media/svg/icons/Inbox/arrows/arr072.svg")} />
                                                        </span>
                                                        <div
                                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                                                            data-kt-menu="true">
                                                            <div className="menu-item px-3">
                                                                <a href="#" className="menu-link px-3">Schedule send</a>
                                                            </div>
                                                            <div className="menu-item px-3">
                                                                <a href="#" className="menu-link px-3">Save & archive</a>
                                                            </div>
                                                            <div className="menu-item px-3">
                                                                <a href="#"
                                                                    className="menu-link px-3">Cancel</a>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <span
                                                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                                                    id="kt_inbox_reply_attachments_select"
                                                    data-kt-inbox-form="dropzone_upload">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/communication/com008.svg")} />
                                                </span>
                                                <span
                                                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/gen018.svg")} />
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                                                    data-toggle="tooltip" title="More actions">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Inbox/coding/cod001.svg")} />
                                                </span>
                                                <span
                                                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary"
                                                    data-inbox="dismiss" data-toggle="tooltip"
                                                    title="Dismiss reply">
                                                    <span className="svg-icon svg-icon-2">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Inbox/gen027.svg")} />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
        </>
    );
}
