import { SearchOutlined } from "@ant-design/icons";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button as AntButton, Col, DatePicker, Form, PageHeader, Row, Table, Typography } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { excelExport } from "../../ExcelHelper";

const { Text } = Typography;
export class IncomeExpenseReport extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      exportExpenseClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      expenseTable: [],
      startDate: moment().subtract(1, "M"),
      endDate: moment(),

      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  fillComboboxes = async () => { };

  restartTable = async () => {
    const { startDate, endDate } = this.state;
    this.setState({ loading: true });

    let obj = {
      startDate: startDate
        ? startDate
        : moment()
          .subtract(1, "M")
          .toDate(),
      endDate: endDate ? endDate : moment().toDate()
    };
    const response = await handleRequest("POST", "/api/invoice/incomeReport", obj);
    const responseExpense = await handleRequest("POST", "/api/expense/outgoingReport", obj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false
      });
    } else {
      let tableList = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach((element, index) => {
          element.index = index + 1;
        });
        tableList = response.data;
      }
      let expenseTable = [];
      if (Boolean(responseExpense.data) && responseExpense.data.length > 0) {
        responseExpense.data.forEach((element, index) => {
          element.index = index + 1;
        });
        expenseTable = responseExpense.data;
      }
      this.setState({
        tableList: tableList,
        expenseTable: expenseTable,
        loading: false
      });
    }
  };

  search = async values => {
    this.setState(
      {
        ...values,
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      },
      this.restartTable
    );
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleExportExpenseExcel = async () => {
    this.setState({
      exportExpenseClick: true
    });
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "Date",
        key: "invoiceDate",
        render: (value, row, index) => {
          return moment(row.invoiceDate).format("DD/MM/YYYY");
        }
      },
      {
        title: "Invoice No",
        key: "invoiceNo",
        dataIndex: "invoiceNo"
      },
      {
        title: "Customer",
        key: "vendorString",
        dataIndex: "vendorString"
      },
      {
        title: "Vat Number",
        key: "vatNumber",
        dataIndex: "vatNumber"
      },
      {
        title: "Total Price",
        key: "grandTotal",
        dataIndex: "grandTotal"
      },
      {
        title: "Total",
        key: "total",
        dataIndex: "total"
      },
      {
        title: "VAT",
        key: "vatValue",
        dataIndex: "vatValue"
      }
    ];
    const columnsExpense = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "Date",
        key: "invoiceDate",
        render: (value, row, index) => {
          return moment(row.invoiceDate).format("DD/MM/YYYY");
        }
      },
      {
        title: "Invoice No",
        key: "invoiceNo",
        dataIndex: "invoiceNo"
      },
      {
        title: "Vendor",
        key: "vendorString",
        dataIndex: "vendorString"
      },
      {
        title: "Vat Number",
        key: "vatNumber",
        dataIndex: "vatNumber"
      },
      {
        title: "Payer",
        key: "payerName",
        dataIndex: "payerName"
      },
      {
        title: "Amount",
        key: "amount",
        dataIndex: "amount"
      },
      {
        title: "Total",
        key: "subTotal",
        dataIndex: "subTotal"
      },
      {
        title: "VAT",
        key: "vatValue",
        dataIndex: "vatValue"
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Date", key: "invoiceDate" },
      { header: "Invoice No", key: "invoiceNo" },
      { header: "Customer", key: "vendorString" },
      { header: "Vat Number", key: "vatNumber" },
      { header: "Total Price", key: "grandTotal" },
      { header: "SubTotal", key: "total" },
      { header: "VAT", key: "vatValue" },
    ];

    const columnsExpenseExcel = [
      { header: "S/N", key: "index" },
      { header: "Date", key: "invoiceDate" },
      { header: "Invoice No", key: "invoiceNo" },
      { header: "Vendor", key: "vendorString" },
      { header: "Vat Number", key: "vatNumber" },
      { header: "Payer", key: "payerName", value: col => (Boolean(col.payerName) ? col.payerName : " ") },
      { header: "Amount", key: "amount" },
      { header: "SubTotal", key: "subTotal" },
      { header: "VAT", key: "vatValue" },
      { header: "Status", key: "vatValue" },
      { header: "Status", key: "active", value: col => (col.active ? "Active" : "Delete") },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Income-Outcome Report"} />
        <Form
          initialValues={{ remember: false, startDate: moment().subtract(1, "M"), endDate: moment() }}
          onFinish={this.search}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
            style={{
              marginBottom: 0
            }}
            name="startDate"
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
            style={{
              marginBottom: 0
            }}
            name="endDate"
            rules={[
              {
                required: false,
                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
              }
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
              </AntButton>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Row>
            <Col md={12}>
              <Row gutter={[16, 16]} justify="end">
                <Col md={2}>{
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => { excelExport(columnsExcel, this.state.tableList) }}
                    icon={<ExportIcon color="action" fontSize="large" />}
                  />
                }</Col>
              </Row>
              <Table
                bordered
                title={() => "Income Report"}
                size="small"
                rowClassName={(record, index) => (record.active ? "" : "text-danger")}
                columns={columns}
                scroll={{ x: 500, scrollToFirstRowOnChange: true }}
                dataSource={this.state.tableList.map((d, i) => ({ key: i, ...d }))}
                loading={this.state.loading}
                summary={pageData => {
                  let totalAmount = 0;
                  let totalSubTotal = 0;
                  let totalVat = 0;

                  this.state.tableList.forEach(({ grandTotal, total, vatValue }) => {
                    totalAmount += grandTotal;
                    totalSubTotal += total;
                    totalVat += vatValue;
                  });
                  totalAmount = Math.round((totalAmount + Number.EPSILON) * 100) / 100;
                  totalSubTotal = Math.round((totalSubTotal + Number.EPSILON) * 100) / 100;
                  totalVat = Math.round((totalVat + Number.EPSILON) * 100) / 100;

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="success">{totalAmount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="success">{totalSubTotal}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="success">{totalVat}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
            <Col md={12}>
              <Row gutter={[16, 16]} justify="end">
                <Col md={2}>{
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => { excelExport(columnsExpenseExcel, this.state.expenseTable) }}
                    icon={<ExportIcon color="action" fontSize="large" />}
                  />
                }</Col>
              </Row>
              <Table
                bordered
                title={() => "Outgoing Report"}
                size="small"
                rowClassName={(record, index) => (record.active ? "" : "text-danger")}
                columns={columnsExpense}
                scroll={{ x: 500, scrollToFirstRowOnChange: true }}
                dataSource={this.state.expenseTable.map((d, i) => ({ key: i, ...d }))}
                loading={this.state.loading}
                summary={pageData => {
                  let totalAmount = 0;
                  let totalSubTotal = 0;
                  let totalVat = 0;

                  this.state.expenseTable.forEach(({ amount, subTotal, vatValue }) => {
                    totalAmount += amount;
                    totalSubTotal += subTotal;
                    totalVat += vatValue;
                  });
                  totalAmount = Math.round((totalAmount + Number.EPSILON) * 100) / 100;
                  totalSubTotal = Math.round((totalSubTotal + Number.EPSILON) * 100) / 100;
                  totalVat = Math.round((totalVat + Number.EPSILON) * 100) / 100;

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="danger">{totalAmount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="danger">{totalSubTotal}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type="danger">{totalVat}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeExpenseReport);
