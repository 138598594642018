/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { API_BASE_ROOT } from "../../../../../app/ApiConnector";
import { setLanguage, useLang } from "../../../../i18n";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function getLanguages() {
  let url = API_BASE_ROOT + "/api/languageTypes/layout";
  return axios.get(url);
}

const defaultLanguages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg")
  },
  {
    lang: "zh",
    name: "Mandarin",
    flag: toAbsoluteUrl("/media/svg/flags/015-china.svg")
  },
  {
    lang: "es",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg")
  },
  {
    lang: "ja",
    name: "Japanese",
    flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg")
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
  },
  {
    lang: "fr",
    name: "French",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg")
  }
];

export function LanguageSelectorDropdown() {
  const lang = useLang();
  const [languages, setLanguages] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  //const currentLanguage = languages.find((x) => x.lang === lang);

  useEffect(() => {
    if (!Boolean(languages))
      getLanguages()
        .then(({ data }) => {
          let currentLang = null;
          let languages = null;
          if (Boolean(data) && data.length > 0) {
            currentLang = data.find(x => x.lang === lang);
            data.forEach(element => {
              element.flag = toAbsoluteUrl(element.iconPath);
            });
            languages = data;
          } else {
            languages = defaultLanguages;
            currentLang = defaultLanguages.find(x => x.lang === lang);
          }
          setLanguages(languages);
          if (Boolean(currentLang)) {
            setCurrentLanguage(currentLang);
          } else {
            setCurrentLanguage(languages[0]);
            setLanguage(languages[0].lang);
          }
          return data;
        })
        .catch(() => {
          setLanguages(null);
          return null;
        });
  });

  return (
    Boolean(currentLanguage) && (
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="language-panel-tooltip">Select Language</Tooltip>}>
            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
              <img className="h-25px w-25px rounded" src={currentLanguage.flag} alt={currentLanguage.name} />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="navi navi-hover py-4">
            {languages.map(language => (
              <li
                key={language.lang}
                className={clsx("navi-item", {
                  active: language.lang === currentLanguage.lang
                })}
              >
                <a href="#" onClick={() => setLanguage(language.lang)} className="navi-link">
                  <span className="symbol symbol-20 mr-3">
                    <img src={language.flag} alt={language.name} />
                  </span>
                  <span className="navi-text">{language.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    )
  );
}
