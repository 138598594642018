import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import EquipmentCalibrationNew from "./EquipmentCalibrationNew";
import EquipmentInsurance from "./EquipmentInsurance";
import EquipmentVehicleLicence from "./EquipmentVehicleLicence";
import EquipmentMaintenance from "./EquipmentMaintenance";
import EquipmentPart from "./EquipmentPart";
import SoftwareLog from "../finance/software/SoftwareLog";

export function EquipmentLogMainDrawer({ equipmentLogId }) {
  console.log("equipmentLogId", equipmentLogId);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("overall");
  useEffect(() => {
    setSelectedTab("overall");
  }, [equipmentLogId]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  {/* OVERALL */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Overall" defaultMessage="Overall" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "overall" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("overall");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Overall" defaultMessage="Overall" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* LEGAL */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Legal" defaultMessage="Legal" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "legal" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("legal");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Legal" defaultMessage="Legal" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* LICENCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Licence" defaultMessage="Licence" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "licence" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("licence");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Licence" defaultMessage="Licence" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* PART LIST */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.PartList" defaultMessage="Part List" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "partList" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("partList");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.PartList" defaultMessage="Part List" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* ATTACHMENTS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Attachment" defaultMessage="Attachment" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "attachment" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("attachment");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Attachment" defaultMessage="Attachment" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* HARDWARES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Hardware" defaultMessage="Hardware" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "hardwares" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("hardwares");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Hardware" defaultMessage="Hardware" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* SOFTWARES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Software" defaultMessage="Software" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "software" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("software");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Software" defaultMessage="Software" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* INSURANCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Insurance" defaultMessage="Insurance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "insurance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("insurance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Insurance" defaultMessage="Insurance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* CALIBRATION */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Calibration" defaultMessage="Calibration" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "calibration" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("calibration");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Calibration" defaultMessage="Calibration" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* CERTIFICATES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Certificates" defaultMessage="Certificates" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "certificates" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("certificates");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Certificates" defaultMessage="Certificates" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* MAINTENANCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Maintenance" defaultMessage="Maintenance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "maintenance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("maintenance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Maintenance" defaultMessage="Maintenance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* PERFORMANCE CRITERIAS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.PerformanceCriteria" defaultMessage="Performance Criterias" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "performanceCriteria" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("performanceCriteria");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.PerformanceCriteria" defaultMessage="Performance Criterias" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* STATISTICALS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Statisticals" defaultMessage="Statisticals" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "statisticals" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("statisticals");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EldenEquipmentLogIdentificationDrawer.Statisticals" defaultMessage="Statisticals" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "overall" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "assigment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "sourceOfPower" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "purposeOfUse" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "attachment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "performanceCriterias" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "sparePart" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}

            {selectedTab === "partList" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {<EquipmentPart parentId={equipmentLogId} parentName={""} nextTab={() => setSelectedTab("licence")} />}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "licence" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {<EquipmentVehicleLicence parentId={equipmentLogId} parentName={""} nextTab={() => setSelectedTab("insurance")} />}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "software" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<SoftwareLog equipmentLogId={equipmentLogId} nextTab={() => setSelectedTab("insurance")} />}</div>
                </div>
              </div>
            )}

            {selectedTab === "insurance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EquipmentInsurance vehicleId={equipmentLogId} nextTab={() => setSelectedTab("calibration")} />}</div>
                </div>
              </div>
            )}

            {selectedTab === "calibration" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {<EquipmentCalibrationNew parentId={equipmentLogId} parentName={""} nextTab={() => setSelectedTab("maintenance")} />}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "maintenance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {<EquipmentMaintenance parentId={equipmentLogId} parentName={""} nextTab={() => setSelectedTab("statistics")} />}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "statistics" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
