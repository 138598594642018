import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Row } from "antd";
import { FormattedMessage } from "react-intl";

const Procurement = () => {
  const [tableList, setTableList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async (params = {}) => {
    const search = {
      firstResult: params.current - 1 || pagination.current - 1,
      maxResults: params.pageSize || pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/deliveryTime", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...pagination,
        total: response.data.totalRecords
      });
    }
  };

  const handleTableChange = (pagination, _, sorter) => {
    const params = { pagination, sortField: sorter.columnKey, sortOrder: sorter.order };
    restartTable(params);
  };

  const restartTable = async (params = {}) => {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/deliveryTime", search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      });
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="Procurement.Category" defaultMessage="Category" />,
      key: "category",
      render: record => record.category
    },
    {
      title: <FormattedMessage id="Procurement.Progress" defaultMessage="Progress" />,
      key: "progress",
      render: record => record.progress,
      align: "center"
    }
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24}>
        <Table
          scroll={{ y: 900, scrollToFirstRowOnChange: true }}
          bordered
          columns={columns}
          dataSource={tableList}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Col>
    </Row>
  );
};

export default Procurement;
