import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import { API_BASE_ROOT } from "../../app/ApiConnector";

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ar";

import deMessages from "./messages/de";
import enMessages from "./messages/en";
import esMessages from "./messages/es";
import frMessages from "./messages/fr";
import jaMessages from "./messages/ja";
import zhMessages from "./messages/zh";
import arMessages from "./messages/ar";

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  ar: arMessages,
};

export function getLanguagesKeyValueList(lang) {
  let url = API_BASE_ROOT + "/api/languageTypes/keyvalue/" + lang;
  return axios.get(url);
}

export function I18nProvider({ children }) {
  const locale = useLang();

  const [messages, setMessages] = useState(null);
  //const currentLanguage = languages.find((x) => x.lang === lang);

  useEffect(() => {
    if (!Boolean(messages))
      getLanguagesKeyValueList(locale)
        .then(({ data }) => {
          let messages = null;
          if (Boolean(data) && data.length > 0) {
            let messageObj = {};
            data.forEach((element) => {
              messageObj[element.key] = element.value;
            });
            messages = messageObj;
          } else {
            messages = allMessages[locale];
          }
          setMessages(messages);
          return data;
        })
        .catch(() => {
          setMessages(null);
          return null;
        });
  });

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
