import axios from "axios";
import {API_BASE_ROOT} from "../../../ApiConnector" ;

export const LOGIN_URL = API_BASE_ROOT + "/login";  
export const TWO_FA_LOGIN_URL = API_BASE_ROOT + "/2falogin";  
export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const REQUEST_PASSWORD_URL = API_BASE_ROOT + "/api/users/forgotPassword";

export const ME_URL = API_BASE_ROOT+"/api/users/me";

export function login(username, password) {
  console.log(LOGIN_URL); 
  return axios.post(LOGIN_URL, { username, password });
}

export function twoFalogin(username,token) {
  return axios.post(TWO_FA_LOGIN_URL, {username,token});  
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
