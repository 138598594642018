import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Upload } from "antd";
import { API_BASE_ROOT } from "../../../ApiConnector";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Input } from "antd";
import { Select } from "antd";
import { DatePicker } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { PrepareFileUploadSave } from "../../../Components/ElementUtils";

const { Option } = Select;

export class TenderPreparation extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TenderPreparation",
      controllerName: "tenderPreparation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseTenderingType = await handleRequest("GET", "/api/" + this.state.controllerName + "/tenderingType");
    if (responseTenderingType.type === "ERROR") {
      error(responseTenderingType);
    }
    var responseTenderingMethod = await handleRequest("GET", "/api/" + this.state.controllerName + "/tenderingMethod");
    if (responseTenderingMethod.type === "ERROR") {
      error(responseTenderingMethod);
    }
    var responsePartialOffer = await handleRequest("GET", "/api/" + this.state.controllerName + "/partialOffer");
    if (responsePartialOffer.type === "ERROR") {
      error(responsePartialOffer);
    }

    this.setState({
      tenderingTypeSelectItems: Boolean(responseTenderingType.data) ? responseTenderingType.data : [],
      tenderingMethodSelectItems: Boolean(responseTenderingMethod.data) ? responseTenderingMethod.data : [],
      partialOfferSelectItems: Boolean(responsePartialOffer.data) ? responsePartialOffer.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    let fileUrl = [];
    let filePathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileUrl.push(obj);
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileUrl,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
      fileList: fileUrl
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.edit.bind(this, true)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(reviseAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      proposedProjectLogId: this.props.proposedProjectLogId
    };
    newItem.technicalSpecificationUrl = PrepareFileUploadSave(newItem.technicalSpecificationUrl);
    newItem.administrativeSpecificationUrl = PrepareFileUploadSave(newItem.administrativeSpecificationUrl);
    if (Boolean(values.documents) && values.documents.length > 0) {
      values.documents.forEach(element => {
        element.fileUrl = PrepareFileUploadSave(element.fileUrl);
      });
    }
    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      if (this.state.typeRevise) {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="TenderPreparation.nameOfTendering" defaultMessage="Name of Tendering" />}
              name="nameOfTendering"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.tenderingTypeId" defaultMessage="Tendering Type-Method" />}
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name="tenderingType"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear style={{ width: "150px" }} optionFilterProp="children">
                    {Boolean(this.state["tenderingTypeSelectItems"]) &&
                      this.state["tenderingTypeSelectItems"].length > 0 &&
                      this.state["tenderingTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name="tenderingMethod"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear style={{ width: "150px" }} optionFilterProp="children">
                    {Boolean(this.state["tenderingMethodSelectItems"]) &&
                      this.state["tenderingMethodSelectItems"].length > 0 &&
                      this.state["tenderingMethodSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.PartialOffer" defaultMessage="Partial Offer" />}
            name="partialOffer"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select allowClear style={{ width: "150px" }} optionFilterProp="children">
              {Boolean(this.state["partialOfferSelectItems"]) &&
                this.state["partialOfferSelectItems"].length > 0 &&
                this.state["partialOfferSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.tenderingBranchCodesId" defaultMessage="Tendering Branch Codes" />}
            name="tenderingBranchCodesId"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
              {Boolean(this.state["tenderingBranchCodesIdSelectItems"]) &&
                this.state["tenderingBranchCodesIdSelectItems"].length > 0 &&
                this.state["tenderingBranchCodesIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="TenderPreparation.tenderApprovalDate" defaultMessage="Tender Approval Date" />}
              name="tenderApprovalDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker showTime format="DD-MM-YYYY HH:mm" style={{ width: "100%" }} />
            </Form.Item>
          }
          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.annoucementTypeId" defaultMessage="Annoucement Type" />}
            name="annoucementTypeId"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
              {Boolean(this.state["annoucementTypeIdSelectItems"]) &&
                this.state["annoucementTypeIdSelectItems"].length > 0 &&
                this.state["annoucementTypeIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.placeOfProjectId" defaultMessage="Place of Project" />}
            name="placeOfProjectId"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
              {Boolean(this.state["placeOfProjectIdSelectItems"]) &&
                this.state["placeOfProjectIdSelectItems"].length > 0 &&
                this.state["placeOfProjectIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.TenderingPlaceDateHour" defaultMessage="Tendering Place-Date-Hour" />}
            name="tenderingPlaceDateHour"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
              {Boolean(this.state["placeOfProjectIdSelectItems"]) &&
                this.state["placeOfProjectIdSelectItems"].length > 0 &&
                this.state["placeOfProjectIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.technicalSpecification" defaultMessage="Technical Specification" />}
            name="technicalSpecificationUrl"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload {...props}>
              <AntButton icon={<UploadOutlined />}>
                <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
              </AntButton>
            </Upload>
          </Form.Item>

          <Form.Item
            {...layout}
            label={<FormattedMessage id="TenderPreparation.administrativeSpecification" defaultMessage="Administrative Specification" />}
            name="administrativeSpecificationUrl"
            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload {...props}>
              <AntButton icon={<UploadOutlined />}>
                <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
              </AntButton>
            </Upload>
          </Form.Item>

          {
            <Form.List name="documents">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <TableBoot bordered size="sm">
                      <thead>
                        <tr>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"TenderPreparation.DocumentCode"} defaultMessage={"Document Code"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"TenderPreparation.DocumentName"} defaultMessage={"Document Name"} />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id={"TenderPreparation.Upload"} defaultMessage={"Upload"} />
                          </th>
                          <th colSpan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <td>
                                <Form.Item noStyle name={[field.name, "code"]}>
                                  <Input style={{ width: "100%" }} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "name"]}>
                                  <Input style={{ width: "100%" }} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "fileUrl"]} valuePropName="fileList" getValueFromEvent={normFile}>
                                  <Upload {...props}>
                                    <AntButton icon={<UploadOutlined />}>
                                      <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                    </AntButton>
                                  </Upload>
                                </Form.Item>
                              </td>
                              <td>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"TenderPreparation.AddNew"} defaultMessage={"Add New"} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  </>
                );
              }}
            </Form.List>
          }

          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TenderPreparation);
