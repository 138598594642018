import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Cascader, Card, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { Input } from "antd";

const { TextArea } = Input;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TextToParaLogFormatCreate extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TextToParaLogFormatCreate",
      controllerName: "textToParaLogFormat",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],
      pdfFileList: [],
      nodes: [],
      type: "",
      numberLetter: "",
      parentId: -1,
      transferType: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
  };

  fillComboboxes = async () => {

  };

  fillItemNos = async () => {
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + this.props.textToParaLogId);
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  textToParaModal = row => {
    this.setState({
      id: row.id,
      modalTextToParaItem: true,
      random: Math.random(),
    });
  };

  formatModal = row => {
    if (!Boolean(row.filePath)) {
      showWarning("Please upload word file.");
      return;
    }
    this.setState({
      id: row.id,
      modalTextToParaFormat: true,
      random: Math.random(),
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let { textToParaLogId } = this.props;
    const newItem = {
      ...values,
      id: this.state.id,
      textToParaLogId: textToParaLogId,
    };
    newItem.parentId =
      Boolean(values.parentId) && values.parentId.length > 0 ? values.parentId[values.parentId.length - 1] : null;

    var response = null;
    if (values.transferType === "Add_Text") {
      response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addText", newItem);
    } else {
      if (newItem.id === null) {
        response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  setItemNo = async () => {
    if (!Boolean(this.state.numberLetter)) {
      return "";
    }
    if (!Boolean(this.state.parentId)) {
      return "";
    }
    if (!Boolean(this.props.textToParaLogId)) {
      return "";
    }
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/numberLetter/" +
      this.state.numberLetter + "/" + this.props.textToParaLogId + "/" + this.state.parentId);
    if (response.type === "ERROR") {
      error(response);
    }
    this.formRef.current.setFieldsValue({
      itemNo: response.data,
    });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div hidden={false}>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              {/* Sol Card */}
              <Col span={1}> </Col>
              <Col span={11}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="TextToParaLogFormatCreate.Type" defaultMessage="Transfer Type" />}
                      name="transferType"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="TextToParaLogFormatCreate.TransferType" defaultMessage="Please select transfer type" />
                        }
                      ]}
                    >
                      <Radio.Group
                        onChange={e => {
                          this.setState({
                            transferType: e.target.value,
                            type: e.target.value == "Add_Text" ? "Sub_Item" : this.state.type,
                          });
                          this.fillItemNos();
                        }}
                      >
                        <Radio value="New">New</Radio>
                        <Radio value="Add_Text">Add Text</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }

                  {Boolean(this.state.transferType) &&
                    <>
                      {this.state.transferType !== "Add_Text" &&
                        <>
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="TextToParaLogFormatCreate.ParaNo" defaultMessage="Para No" />}
                              name="paraNo"
                              rules={[
                                {
                                  required: true,
                                  message: <FormattedMessage id="TextToParaLogFormatCreate.ParaNo" defaultMessage="Please select type" />
                                }
                              ]}
                            >
                              <Radio.Group
                                onChange={e => {
                                  if (e.target.value === "Sub_Item") {
                                    this.fillItemNos();
                                    this.setState({
                                      type: e.target.value,
                                    }, () => {
                                      this.setItemNo();
                                    });
                                  } else {
                                    this.setState({
                                      nodes: [],
                                      parentId: -1,
                                      type: e.target.value,
                                    }, () => {
                                      this.setItemNo();
                                    });
                                  }
                                }}
                              >
                                <Radio value="New_Item">New Item</Radio>
                                <Radio value="Sub_Item">Sub Item</Radio>
                              </Radio.Group>
                            </Form.Item>
                          }
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="TextToParaLogFormatCreate.NumberLetter" defaultMessage="Number/Letter" />}
                              name="numberLetter"
                              rules={[
                                {
                                  required: true,
                                  message: <FormattedMessage id="TextToParaLogFormatCreate.Type" defaultMessage="Please select type" />
                                }
                              ]}
                            >
                              <Radio.Group
                                onChange={async (e) => {
                                  this.setState({
                                    numberLetter: e.target.value,
                                  }, () => {
                                    this.setItemNo();
                                  });
                                }}
                              >
                                <Radio value="Number">Number</Radio>
                                <Radio value="Letter">Letter</Radio>

                                {(this.state.numberLetter === "Letter" || this.state.numberLetter === "Uppercase"
                                  || this.state.numberLetter === "Lowercase") &&
                                  <>
                                    <Radio value="Uppercase">Uppercase</Radio>
                                    <Radio value="Lowercase">Lowercase</Radio>
                                  </>
                                }
                              </Radio.Group>
                            </Form.Item>
                          }
                        </>
                      }
                      {
                        this.state.type === "Sub_Item" &&
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="TextToParaLogFormatCreate.ItemNo" defaultMessage="Item No" />}
                          name="parentId"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Cascader
                            showSearch
                            allowClear
                            changeOnSelect
                            style={{ width: "100%" }}
                            options={this.state.nodes}
                            optionFilterProp="children"
                            onChange={(value, selectedOptions) => {
                              this.setState({
                                parentId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null,
                              }, () => {
                                if (this.state.transferType === "Add_Text") {
                                  this.formRef.current.setFieldsValue({
                                    itemNo: Boolean(selectedOptions) && selectedOptions.length > 0 ? selectedOptions[selectedOptions.length - 1].label : "",
                                  });
                                } else {
                                  this.setItemNo();
                                }
                              });
                            }}
                          />
                        </Form.Item>
                      }
                    </>
                  }

                </Card>
              </Col>

              {/* Sağ Card */}
              <Col span={11}>
                <Card
                  style={{

                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="TextToParaLogFormatCreate.OriginalItemNo" defaultMessage="Original Item No" />}
                      name="originalItemNo"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="TextToParaLogFormatCreate.OriginalTitle" defaultMessage="Original Title" />}
                      name="originalTitle"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="TextToParaLogFormatCreate.ItemNo" defaultMessage="Item No" />}
                      name="itemNo"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} readOnly />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="TextToParaLogFormatCreate.Title" defaultMessage="Title" />}
                      name="title"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }


                </Card>
              </Col>
              <Col span={1}></Col>
            </Row>

            {this.state.transferType === "Add_Text" &&
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={1}> </Col>
                <Col span={22}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      name="selectedText"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <TextArea
                        rows={8}
                        placeholder="Selected Text"
                        style={{ width: "100%", height: "200%" }}
                      />
                    </Form.Item>

                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>
            }
          </div>

          <div hidden={false}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>

        </Form >

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TextToParaLogFormatCreate);