import React, { useState, useEffect } from "react";
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import { Table, Col, Select, Row, DatePicker, Progress } from "antd";
import { FormattedMessage } from "react-intl";
import { Pie } from "@ant-design/plots";
import moment from "moment";
import { Line } from "@ant-design/plots";
import { Column, Radar } from "@ant-design/charts";

const { Option } = Select;

const CustomerPaymentTypes = () => {
  const [tableList, setTableList] = useState([]);  
  const [lineChartList, setLineChartList] = useState([])
  const [pieChartList, setPieChartList] = useState([]);
  const [period, setPeriod] = useState("CASH");
  const [startDate, setStartDate] = useState(moment().subtract(6, "M"));
  const [endDate, setEndDate] = useState(moment());
  const [progressType, setProgressType] = useState("pie");
  const [radarVisible, setRadarVisible] = useState(false);
  const [tableVisible, setTableVisible] = useState(false);
  const [pieVisible, setPieVisible] = useState(true);
  const [lineVisible, setLineVisible] = useState(false);
  const [columnVisible, setColumnVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ["2", "5", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
    total: 0
  });

  useEffect(() => {
    asyncFetch();
  }, [startDate, endDate]);

  const asyncFetch = async () => {
    restartTable({ pagination }, progressType);  
  };

  const handleTableChange = (pagination, filters, sorter) => {
    restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  const restartTable  = async (params = {}, progressType) =>  {
    const search = {
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
    };
    console.log(search);
    var response = await handleRequest("POST", "/api/dashboard/customerPaymentTypes/" + progressType, search);
    if (response.type === "ERROR") {
      error(response);
    } else {
      setTableList(Boolean(response.data.data) ? response.data.data : null);
      setPagination({
        ...params.pagination,
        total: response.data.totalRecords
      },)
    }
     var responsePieChart = await handleRequest("POST", "/api/dashboard/customerPaymentTypesPieChart" , search);
    if (responsePieChart.type === "ERROR") {
      error(responsePieChart);
    } else {
      setPieChartList(Boolean(responsePieChart.data) ? responsePieChart.data : null);
    }
  };
  var pieConfig = {
    appendPadding: 10,
    data: pieChartList,
    angleField: "key",
    colorField: "value",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: '{value}',
      style: {
        textAlign: "center",
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
 
  };
   const handleSelectChange = (value) => {
   switch (value){
    case 'table':
      setTableVisible(true);
      setRadarVisible(false);
      setPieVisible(false);
      setColumnVisible(false);
      setLineVisible(false);
      break;
    case 'line':
      setLineVisible(true); 
      setRadarVisible(false);
      setPieVisible(false);
      setColumnVisible(false);
      setTableVisible(false);
      break;

    case 'progress':
      setTableVisible(true); 
      setRadarVisible(false);
      setPieVisible(false);
      setColumnVisible(false);
      setLineVisible(false);
      break;

    case 'circle':
      setTableVisible(true);     
      setRadarVisible(false);
      setPieVisible(false);
      setColumnVisible(false);
      setLineVisible(false);
      break;
    case 'radar':
      setRadarVisible(true);
      setPieVisible(false);
      setColumnVisible(false);
      setLineVisible(false);
      setTableVisible(false);
      break;
    case 'pie':
      setPieVisible(true);      
      setLineVisible(false);
      setColumnVisible(false);
      setTableVisible(false);
      setRadarVisible(false);
      break;
    case 'column':
      setColumnVisible(true);
      setLineVisible(false);
      setTableVisible(false);
      setRadarVisible(false);
      setPieVisible(false);
      break;
  
   }
  };

const barConfig = {
    data: pieChartList,
    isGroup: true,
    xField:"value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`,
        layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  const lineConfig = {
    data: pieChartList,
    xField: "value",
    yField: "key",
    seriesField: "value",
    yAxis: {
      label: {
        formatter: v => `${v}`
      }
    },
    color: ["#1979C9", "#D62A0D", "#FAA219", "#FF99C3"]
  };

  const columns = [
    {
      title: "",
      key: "type",
      render: record => {
        return record.type;
      },
    },
    {
      title: <FormattedMessage id="CustomerPaymentTypes.Cash" defaultMessage="Cash" />,
      key: "cash",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.cash;
        } else {
          return <Progress type={progressType} percent={row.cash} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="CustomerPaymentTypes.Credit" defaultMessage="Credit" />,
      key: "credit",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.credit;
        } else {
          return <Progress type={progressType} percent={row.credit} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="CustomerPaymentTypes.BankTransfer" defaultMessage="Bank Transfer" />,
      key: "bankTransfer",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.bankTransfer;
        } else {
          return <Progress type={progressType} percent={row.bankTransfer} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="CustomerPaymentTypes.Cheque" defaultMessage="Cheque" />,
      key: "cheque",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.cheque;
        } else {
          return <Progress type={progressType} percent={row.cheque} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },
    {
      title: <FormattedMessage id="CustomerPaymentTypes.Note" defaultMessage="Note" />,
      key: "note",
      render: (value, row, index) => {
        if (progressType === "table") {
          return row.note;
        } else {
          return <Progress type={progressType} percent={row.note} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />;
        }
      },
      align: "center"
    },

  ]

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={4}></Col>
        <Col lg={6}>
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            value={startDate}
            onChange={date => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            value={endDate}
            onChange={date => {
              setEndDate(date);
            }}
          />

        </Col><Col lg={6}>
          <Select
            placeholder="Period"
            defaultActiveFirstOption
            allowClear
            value={period}
            className="w-100"
            onChange={value => {
              setPeriod(value);
            }}
          >
            <Option key={"CASH"} value={"CASH"}>
              <FormattedMessage id="period.cash" defaultMessage="Cash" />
            </Option>
            <Option key={"CREDIT"} value={"CREDIT"}>
              <FormattedMessage id="period.credit" defaultMessage="Credit" />
            </Option>
            <Option key={"BANKTRANSFER"} value={"BANKTRANSFER"}>
              <FormattedMessage id="period.bankTransfer" defaultMessage="Bank Transfer" />
            </Option>
            <Option key={"CHEQUE"} value={"CHEQUE"}>
              <FormattedMessage id="period.cheque" defaultMessage="Cheque" />
            </Option>
            <Option key={"NOTE"} value={"NOTE"}>
              <FormattedMessage id="period.note" defaultMessage="Note" />
            </Option>
          </Select>
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Salesman Sales Type"
            defaultActiveFirstOption
            allowClear
            value={progressType}
            className="w-100"
            onChange={value => {
              setProgressType(value);
              restartTable({ pagination }, value);
              handleSelectChange(value);
            }}
          >
            <Option key={"dashboard"} value={"dashboard"}>
              <FormattedMessage id="CustomerPaymnetTypes.Dashboard" defaultMessage="Dashboard" />
            </Option>
            <Option key={"circle"} value={"circle"}>
              <FormattedMessage id="CustomerPaymnetTypes.Circle" defaultMessage="Circle" />
            </Option>
            <Option key={"table"} value={"table"}>
              <FormattedMessage id="CustomerPaymnetTypes.Table" defaultMessage="Table" />
            </Option>
            <Option key={"pie"} value={"pie"}>
              <FormattedMessage id="CustomerPaymnetTypes.Pie" defaultMessage="Pie" />
            </Option>
            <Option key={"column"} value={"column"}>
              <FormattedMessage id="CustomerPaymnetTypes.Column" defaultMessage="Column" />
            </Option>
            <Option key={"radar"} value={"radar"}>
              <FormattedMessage id="CustomerPaymnetTypes.Radar" defaultMessage="Radar" />
            </Option>
            <Option key={"line"} value={"line"}>
              <FormattedMessage id="CustomerPaymnetTypes.Line" defaultMessage="Line" />
            </Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
        {tableVisible&&
          <Table
            scroll={{ x: 900, y: 900, scrollToFirstRowOnChange: true }}
            bordered
            columns={columns}
            dataSource={tableList}
            onChange={handleTableChange}
            pagination={pagination}
          />
        }
        </Col>
        <Col>
        {Boolean(pieChartList) && pieChartList.length > 0 && pieVisible && <Pie {...pieConfig} />}
          {/* <PieChart apiUrl="/api/dashboard/monthly" detailHeader={false} /> */}
          
          {Boolean(pieChartList) && pieChartList.length > 0  && lineVisible && <Line {...lineConfig} />}

          {Boolean(pieChartList) && pieChartList.length > 0 && columnVisible &&  <Column {...barConfig} />}

          {Boolean(pieChartList) && pieChartList.length > 0 && radarVisible &&  <Radar {...barConfig} />}
        </Col>

      </Row>
    </>
  );
};

export default CustomerPaymentTypes;
