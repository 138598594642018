import { DatePicker, Form } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";
const RangePickerComponent = ({ field, layout }) => {
  const { RangePicker } = DatePicker;
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <RangePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default RangePickerComponent;
