import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Input, Space, Checkbox, Typography } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import { excelExport } from "../../ExcelHelper";

const { Option } = Select;
const { Text } = Typography;

export class MaintenanceLogExpanded extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenanceLogExpanded",
      controllerName: "maintenanceLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      selectedRecord: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefMultiple = React.createRef();
  formRefModal = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    console.log("props", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseControlType = await handleRequest("GET", "/api/" + this.state.controllerName + "/controlType");
    if (responseControlType.type === "ERROR") {
      error(responseControlType);
    }

    var responseDurationType = await handleRequest("GET", "/api/" + this.state.controllerName + "/durationType");
    if (responseDurationType.type === "ERROR") {
      error(responseDurationType);
    }

    var responseMKm = await handleRequest("GET", "/api/" + this.state.controllerName + "/mKm");
    if (responseMKm.type === "ERROR") {
      error(responseMKm);
    }

    var responseHr = await handleRequest("GET", "/api/" + this.state.controllerName + "/hr");
    if (responseHr.type === "ERROR") {
      error(responseHr);
    }

    var responseDuration = await handleRequest("GET", "/api/" + this.state.controllerName + "/duration");
    if (responseDuration.type === "ERROR") {
      error(responseDuration);
    }

    var responseScheduleType = await handleRequest("GET", "/api/" + this.state.controllerName + "/scheduleType");
    if (responseScheduleType.type === "ERROR") {
      error(responseScheduleType);
    }

    this.setState({
      controlTypeSelectItems: Boolean(responseControlType.data) ? responseControlType.data : [],
      mKmSelectItems: Boolean(responseMKm.data) ? responseMKm.data : [],
      durationTypeSelectItems: Boolean(responseDurationType.data) ? responseDurationType.data : [],
      hrSelectItems: Boolean(responseHr.data) ? responseHr.data : [],
      durationSelectItems: Boolean(responseDuration.data) ? responseDuration.data : [],
      scheduleTypeSelectItems: Boolean(responseScheduleType.data) ? responseScheduleType.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      equipmentLogId: this.props.equipmentLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });

    this.formRef.current.setFieldsValue({
      ...row
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });

      let durations = [];
      durations.push({ type: "Km" });
      durations.push({ type: "Work_Time" });
      durations.push({ type: "Calendar" });

      this.formRef.current.setFieldsValue({
        durations: durations
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      equipmentLogId: this.props.equipmentLogId
    };
    console.log(newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveMultiple = async values => {
    console.log("Values", values);
    this.setState(
      {
        showMultipleModal: false
      },
      () => {
        const { currentFieldKey } = this.state;
        console.log("currentFieldKey", currentFieldKey);

        let durations = this.formRef.current.getFieldValue("durations");
        console.log("durations", durations);
        let durationDetails = durations[currentFieldKey];
        console.log("durationDetails", durationDetails);
        durationDetails.durationDetails = values.durationMultiple;

        this.formRef.current.setFieldsValue({
          durations: durations
        });
      }
    );
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.Control" defaultMessage="Control" />,
        key: "controlName",
        render: record => {
          return record.controlName;
        },
        ...getColumnFilter({
          inputName: "controlName",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.ControlType" defaultMessage="Control Type" />,
        key: "controlType",
        render: record => {
          return record.controlTypeName;
        },
        ...getColumnFilter({
          inputName: "controlType",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["controlTypeSelectItems"]) && this.state["controlTypeSelectItems"].length > 0
              ? this.state["controlTypeSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.Activity" defaultMessage="Activity" />,
        key: "id",
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.Duration" defaultMessage="Duration" />,
        key: "id",
        render: record => {
          return (
            <AntButton
              onClick={() => {
                console.log("record", record);
                this.setState(
                  {
                    modalDurationShow: true,
                    selectedRecord: record
                  },
                  () => {
                    let durationModal = [];
                    if (Boolean(record.durations) && record.durations.length > 0) {
                      record.durations.forEach(element => {
                        if (Boolean(element.durationNames) && element.durationNames.length > 0) {
                          element.durationNames.forEach(duration => {
                            durationModal.push({ durationName: duration });
                          });
                        }
                      });
                    }
                    this.formRefModal.current.setFieldsValue({
                      durationModal: durationModal
                    });
                  }
                );
              }}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ClockCircleOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.Start" defaultMessage="Start" />,
        key: "id",
        render: record => {
          let allStartValue = [];
          let km = record?.durations?.find(p => p.type === "Km")?.periodType || "";
          let workTime = record?.durations?.find(p => p.type === "Work_Time")?.periodType || "";
          let calendar = record?.durations?.find(p => p.type === "Calendar")?.periodType || "";

          km && allStartValue.push(record?.startKm ? `${record.startKm} ${km}` : `0 ${km}`);
          workTime && allStartValue.push(record?.startWorkTime ? `${record.startWorkTime} ${workTime}` : `0 ${workTime}`);
          calendar && allStartValue.push(record?.startCalendar ? `${record.startCalendar} ${calendar}` : `0 ${calendar}`);

          return allStartValue.filter(Boolean).join(" - ");
        }
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Control", key: "controlName" },
      { header: "Control Type", key: "controlTypeName" },
      { header: "Activity", key: "activityName" },
      { header: "Duration", key: "durationName" },
      { header: "Start", key: "allStartValue" }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  excelExport(columnsExcel, this.state.tableList);
                }}
                icon={<ExportIcon color="action" fontSize="large" />}
              />
            </Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                name="controlName"
                label={<FormattedMessage id="MaintenanceLogExpanded.Control" defaultMessage="Control" />}
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                name="controlType"
                label={<FormattedMessage id="MaintenanceLogExpanded.ControlType" defaultMessage="Control Type" />}
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                  {Boolean(this.state["controlTypeSelectItems"]) &&
                    this.state["controlTypeSelectItems"].length > 0 &&
                    this.state["controlTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                name="activityId"
                label={<FormattedMessage id="MaintenanceLogExpanded.Activity" defaultMessage="Activity" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                  {Boolean(this.state["activityIdSelectItems"]) &&
                    this.state["activityIdSelectItems"].length > 0 &&
                    this.state["activityIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }

            <h2>
              <FormattedMessage id="MaintenanceLogExpanded.Duration" defaultMessage="Duration" />
            </h2>
            <Form.List name="durations">
              {(fields, { add, remove }) => {
                const handleCopyClick = fieldKey => {
                  let newIndex = parseInt(fieldKey, 10) + 1;
                  const durations = this.formRef.current.getFieldValue("durations");
                  let rowDuration = { ...durations[fieldKey] };
                  durations.splice(newIndex, 0, rowDuration);
                  console.log("durations", durations);

                  this.formRef.current.setFieldsValue({
                    durations: durations
                  });
                };

                return (
                  <TableBoot bordered size="sm">
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item noStyle name={[field.name, "type"]}>
                                <Select
                                  disabled
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    this.setState({ type: value });
                                  }}
                                >
                                  {Boolean(this.state["durationTypeSelectItems"]) &&
                                    this.state["durationTypeSelectItems"].length > 0 &&
                                    this.state["durationTypeSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "scheduleType"]}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    let showMultipleModal = false;
                                    if (value === "Multiple") {
                                      showMultipleModal = true;
                                    }
                                    this.setState(
                                      {
                                        scheduleType: value,
                                        showMultipleModal: showMultipleModal,
                                        currentFieldKey: field.name
                                      },
                                      () => {
                                        if (showMultipleModal) {
                                          let durationMultiple = [];
                                          durationMultiple.push({ type: this.formRef.current.getFieldValue("durations")[field.name].type });
                                          console.log("durationMultiple", durationMultiple);
                                          this.formRefMultiple.current.setFieldsValue({
                                            durationMultiple: durationMultiple
                                          });
                                        }
                                      }
                                    );
                                  }}
                                >
                                  {Boolean(this.state["scheduleTypeSelectItems"]) &&
                                    this.state["scheduleTypeSelectItems"].length > 0 &&
                                    this.state["scheduleTypeSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "period"]}>
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </td>
                            <td>
                              {Boolean(this.formRef) &&
                                Boolean(this.formRef.current) &&
                                Boolean(this.formRef.current.getFieldValue("durations")) &&
                                Boolean(this.formRef.current.getFieldValue("durations")[field.name]) &&
                                this.formRef.current.getFieldValue("durations")[field.name].type === "Km" && (
                                  <Form.Item noStyle name={[field.name, "periodType"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      onChange={value => {
                                        this.setState({ periodType: value });
                                      }}
                                    >
                                      {Boolean(this.state["mKmSelectItems"]) &&
                                        this.state["mKmSelectItems"].length > 0 &&
                                        this.state["mKmSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                )}

                              {Boolean(this.formRef) &&
                                Boolean(this.formRef.current) &&
                                Boolean(this.formRef.current.getFieldValue("durations")) &&
                                Boolean(this.formRef.current.getFieldValue("durations")[field.name]) &&
                                this.formRef.current.getFieldValue("durations")[field.name].type === "Work_Time" && (
                                  <Form.Item noStyle name={[field.name, "periodType"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      onChange={value => {
                                        this.setState({ periodType: value });
                                      }}
                                    >
                                      {Boolean(this.state["hrSelectItems"]) &&
                                        this.state["hrSelectItems"].length > 0 &&
                                        this.state["hrSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                )}

                              {Boolean(this.formRef) &&
                                Boolean(this.formRef.current) &&
                                Boolean(this.formRef.current.getFieldValue("durations")) &&
                                Boolean(this.formRef.current.getFieldValue("durations")[field.name]) &&
                                this.formRef.current.getFieldValue("durations")[field.name].type === "Calendar" && (
                                  <Form.Item noStyle name={[field.name, "periodType"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      onChange={value => {
                                        this.setState({ periodType: value });
                                      }}
                                    >
                                      {Boolean(this.state["durationSelectItems"]) &&
                                        this.state["durationSelectItems"].length > 0 &&
                                        this.state["durationSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                )}
                            </td>
                            <td>
                              <Space>
                                <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </Space>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                );
              }}
            </Form.List>

            {Boolean(this.formRef) &&
              Boolean(this.formRef.current) &&
              Boolean(this.formRef.current.getFieldValue("durations")) &&
              this.formRef.current.getFieldValue("durations").length > 1 && (
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageFormInput.WhicheverComesEarlier" defaultMessage="Whichever Comes Earlier" />}
                  name="whicheverComesEarlier"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              )}

            <br />
            <h2>
              <FormattedMessage id="MaintenanceLogExpanded.Start" defaultMessage="Start" />
            </h2>

            {Boolean(this.formRef) &&
              Boolean(this.formRef.current) &&
              Boolean(this.formRef.current.getFieldValue("durations")) &&
              this.formRef.current.getFieldValue("durations").length > 0 &&
              Boolean(
                this.formRef.current.getFieldValue("durations").find(p => p.periodType && ["m", "km"].some(type => p.periodType.includes(type)))
              ) && (
                <Form.Item
                  {...layout}
                  name="startKm"
                  label={<FormattedMessage id="BillOfQuantityAddProduct.Km" defaultMessage="Km" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber
                    addonAfter={
                      this.formRef.current.getFieldValue("durations").find(p => p.periodType && ["m", "km"].some(type => p.periodType.includes(type)))
                        .periodType
                    }
                    min={0}
                    style={{ width: "100%" }}
                    parser={value => value.replace(",", ".")}
                    formatter={value => value.replace(".", ",")}
                  />
                </Form.Item>
              )}

            {Boolean(this.formRef) &&
              Boolean(this.formRef.current) &&
              Boolean(this.formRef.current.getFieldValue("durations")) &&
              this.formRef.current.getFieldValue("durations").length > 0 &&
              Boolean(this.formRef.current.getFieldValue("durations").find(p => p.periodType === "hr")) && (
                <Form.Item
                  {...layout}
                  name="startWorkTime"
                  label={<FormattedMessage id="BillOfQuantityAddProduct.WorkTime" defaultMessage="Work Time" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber
                    addonAfter={"hr"}
                    min={0}
                    style={{ width: "100%" }}
                    parser={value => value.replace(",", ".")}
                    formatter={value => value.replace(".", ",")}
                  />
                </Form.Item>
              )}

            {Boolean(this.formRef) &&
              Boolean(this.formRef.current) &&
              Boolean(this.formRef.current.getFieldValue("durations")) &&
              this.formRef.current.getFieldValue("durations").length > 0 &&
              Boolean(
                this.formRef.current
                  .getFieldValue("durations")
                  .find(p => p.periodType && ["day", "week", "month", "year"].some(type => p.periodType.includes(type)))
              ) && (
                <Form.Item
                  {...layout}
                  name="startCalendar"
                  label={<FormattedMessage id="BillOfQuantityAddProduct.Calendar" defaultMessage="Calendar" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber
                    addonAfter={
                      this.formRef.current
                        .getFieldValue("durations")
                        .find(p => p.periodType && ["day", "week", "month", "year"].some(type => p.periodType.includes(type))).periodType
                    }
                    min={0}
                    style={{ width: "100%" }}
                    parser={value => value.replace(",", ".")}
                    formatter={value => value.replace(".", ",")}
                  />
                </Form.Item>
              )}

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            {" "}
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState(
                      {
                        searchCriteriaModels: []
                      },
                      () => {
                        this.filterChangeItem([]);
                      }
                    )
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}

        <div
          style={{
            marginTop: "2rem",
            marginBottom: "1rem"
          }}
          className="content-section implementation"
        >
          <Table
            bordered
            scroll={{
              x: 900,
              scrollToFirstRowOnChange: true
            }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>{" "}
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          centered
          width={1000}
          title={<FormattedMessage id="MaintenanceLogExpanded.Multiple" defaultMessage="Multiple" />}
          visible={this.state.showMultipleModal}
          okButtonProps={{ hidden: false }}
          onOk={() => this.formRefMultiple.current.submit()}
          onCancel={() => {
            this.setState({
              showMultipleModal: false
            });
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveMultiple} ref={this.formRefMultiple}>
              <Form.List name="durationMultiple">
                {(fields, { add, remove }) => {
                  const handleCopyClick = fieldKey => {
                    let newIndex = parseInt(fieldKey, 10) + 1;
                    const durationMultiple = this.formRefMultiple.current.getFieldValue("durationMultiple");
                    let rowDuration = { ...durationMultiple[fieldKey] };
                    durationMultiple.splice(newIndex, 0, rowDuration);
                    console.log("durationMultiple", durationMultiple);

                    this.formRefMultiple.current.setFieldsValue({
                      durationMultiple: durationMultiple
                    });
                  };

                  return (
                    <TableBoot bordered size="sm">
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <td>
                                <Form.Item noStyle name={[field.name, "type"]}>
                                  <Select
                                    disabled
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    onChange={value => {
                                      this.setState({ type: value });
                                    }}
                                  >
                                    {Boolean(this.state["durationTypeSelectItems"]) &&
                                      this.state["durationTypeSelectItems"].length > 0 &&
                                      this.state["durationTypeSelectItems"].map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.value}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "period"]}>
                                  <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                              </td>
                              <td>
                                {Boolean(this.formRefMultiple) &&
                                  Boolean(this.formRefMultiple.current) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name]) &&
                                  this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name].type === "Km" && (
                                    <Form.Item noStyle name={[field.name, "periodType"]}>
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                        {Boolean(this.state["mKmSelectItems"]) &&
                                          this.state["mKmSelectItems"].length > 0 &&
                                          this.state["mKmSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  )}

                                {Boolean(this.formRefMultiple) &&
                                  Boolean(this.formRefMultiple.current) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name]) &&
                                  this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name].type === "Work_Time" && (
                                    <Form.Item noStyle name={[field.name, "periodType"]}>
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                        {Boolean(this.state["hrSelectItems"]) &&
                                          this.state["hrSelectItems"].length > 0 &&
                                          this.state["hrSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  )}

                                {Boolean(this.formRefMultiple) &&
                                  Boolean(this.formRefMultiple.current) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")) &&
                                  Boolean(this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name]) &&
                                  this.formRefMultiple.current.getFieldValue("durationMultiple")[field.name].type === "Calendar" && (
                                    <Form.Item noStyle name={[field.name, "periodType"]}>
                                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                        {Boolean(this.state["durationSelectItems"]) &&
                                          this.state["durationSelectItems"].length > 0 &&
                                          this.state["durationSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  )}
                              </td>
                              <td>
                                <Space>
                                  <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Space>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </TableBoot>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          centered
          width={600}
          title={<FormattedMessage id="MaintenanceLogExpanded.Duration" defaultMessage="Duration" />}
          visible={this.state.modalDurationShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({
              modalDurationShow: false
            });
          }}
          content={
            <Form initialValues={{ remember: false }} ref={this.formRefModal}>
              <Form.List name="durationModal">
                {(fields, { add, remove }) => {
                  return (
                    <TableBoot bordered size="sm">
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <Form.Item hidden name={[field.name, "durationName"]}></Form.Item>
                              <td>
                                <Text type="secondary">
                                  {Boolean(this.formRefModal) &&
                                    this.formRefModal.current &&
                                    this.formRefModal.current.getFieldValue("durationModal") &&
                                    this.formRefModal.current.getFieldValue("durationModal").length > 0 &&
                                    this.formRefModal.current.getFieldValue("durationModal")[field.name].durationName}
                                </Text>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </TableBoot>
                  );
                }}
              </Form.List>
            </Form>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogExpanded);
